import { HTMLProps } from 'react';
import { Link, LinkProps } from 'react-router-dom';

import { langPath } from '../../utils/langPath';

type AbitlyLogoProps = HTMLProps<HTMLImageElement> | ({ height?: number; width?: number } & LinkProps);

function AbitlyLogo(props: AbitlyLogoProps) {
  if ('to' in props) {
    const { height, width, to, ...rest } = props;

    return (
      <Link to={langPath(to)} {...rest}>
        <img src='/images/abitly-logo.svg' height={height} width={width} alt='Abitly' />
      </Link>
    );
  }
  return <img src='/images/abitly-logo.svg' alt='Abitly' {...props} />;
}

export default AbitlyLogo;
