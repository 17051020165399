import { Box, Typography } from '@mui/material';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { deleteUser } from '../../../api/auth';
import Button from '../../../components/ButtonV2/Button';
import { useToggle } from '../../../hooks/utils/useToggle';
import { useAuth } from '../../Auth/state/AuthContext';
import styles from '../profile.module.scss';

export default function DeleteProfileButton() {
  const { t } = useTranslation();

  const [opened, toggleOpened] = useToggle(false);

  useEffect(() => {
    if (opened) {
      document.body.classList.add('hidden');
    } else {
      document.body.classList.remove('hidden');
    }
  }, [opened]);

  return (
    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
      <Button type='button' variant='text' color='error' onClick={toggleOpened}>
        <b>{t('profile.deleteProfile')}</b>
      </Button>
      <DeletePopup opened={opened} toggleOpened={toggleOpened} />
    </Box>
  );
}

function DeletePopup({ opened, toggleOpened }: { opened: boolean; toggleOpened: () => void }) {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { user, setAccessToken } = useAuth();
  const { t } = useTranslation();
  const { mutate: deleteProfile } = useMutation({
    mutationFn: () => deleteUser(user?.id),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['user'] });
      navigate('/');
      setAccessToken(null);
    },
  });

  if (!opened) return null;

  return (
    <Box className={styles.popupBackground} onClick={toggleOpened}>
      <Box className={styles.popupModalWrapper} onClick={(e) => e.stopPropagation()}>
        <Box className={styles.popupModal}>
          <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            <img src='/emoji/cry.png' alt='delete-profile' height={48} width={48} />
          </Box>
          <Typography sx={{ textAlign: 'center' }} variant='h3'>
            {t('profile.deleteProfileTitle')}
          </Typography>
          <Typography sx={{ textAlign: 'center' }} variant='body1'>
            {t('profile.deleteProfileDescription')}
          </Typography>
          <Box className={styles.popupModalButtons}>
            <Button type='button' variant='primary' color='error' size='slim' onClick={() => deleteProfile()}>
              {t('profile.deleteProfileButton')}
            </Button>
            <Button type='button' variant='outline' size='slim' onClick={toggleOpened}>
              {t('profile.cancelButton')}
            </Button>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
