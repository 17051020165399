import { useTranslation } from 'react-i18next';

import { SpecialityWithCoefficients } from '../../../../../api/types/specialities';
import { placeholderSpeciality } from '../../../constants/specialities';
import styles from '../GradesGraphsWrapper.module.scss';

interface Props {
  speciality: SpecialityWithCoefficients | null;
}

export function CalcDescription({ speciality }: Props) {
  const { t } = useTranslation();

  return (
    <div className={styles.calcDescriptionWrapper}>
      <p style={{ marginBottom: 16, maxWidth: 380 }}>{t('calculator.graph.description')}</p>
      <p>
        {t('calculator.graph.for')}&quot;{speciality?.name || '__'}&quot; {t('calculator.graph.nextCoefs')}
      </p>{' '}
      <ul>
        {Object.entries(speciality?.coefficients ?? placeholderSpeciality).map(([key, value]) => (
          <li key={key}>
            {t(`calculator.subjects.nominative.${key}`)}: {value ? `${value}` : '-'}
          </li>
        ))}
      </ul>
      <p style={{ marginBottom: 24, maxWidth: 380 }}>{t('calculator.graph.newSystem')}</p>
    </div>
  );
}
