import {
  Applicant,
  ApplicantMember,
  OtherUser,
  Student,
  Teacher,
  User,
  userUpdateDto,
  UserUpdateDto,
} from '../../../api/schemas/user';
import { OPTIONAL_SUBJECTS, REQUIRED_SUBJECTS } from '../constants/subjects';
import {
  ApplicantForm,
  ApplicantMemberForm,
  OtherUserForm,
  StudentForm,
  TeacherForm,
  UserForm,
} from '../schema/user-form';

const adaptApplicant = (user: Applicant | ApplicantMember) => ({
  requiredSubjects: REQUIRED_SUBJECTS.map((subject) => {
    const existingSubject = user.subjectScores?.find((subjectScore) => subjectScore.name === subject.name);
    const result = existingSubject ?? { id: null, name: subject.name, score: null };
    return { ...result, translation: subject.translation };
  }),
  optionalSubjects: OPTIONAL_SUBJECTS.map((subject) => {
    const existingSubject = user.subjectScores?.find((subjectScore) => subjectScore.name === subject.name);
    const result = existingSubject ?? { id: null, name: subject.name, score: null };
    return { ...result, translation: subject.translation };
  }),
  interestedSpecialities: user.interestedSpecialities?.map((speciality) => speciality.id) ?? [],
  selectedRegions: user.selectedRegions?.map((region) => region.id) ?? [],
  selectedUniversities: user.selectedUniversities?.map((university) => university.id) ?? [],
});

const adaptStudent = (user: Student) => ({
  facultyId: user.faculty?.id,
  specialityId: user.speciality?.id,
  universityId: user.university?.id,
});

const formAdapters = {
  APPLICANT: (user: Applicant): ApplicantForm => ({
    ...user,
    ...adaptApplicant(user),
  }),
  APPLICANT_MEMBER: (user: ApplicantMember): ApplicantMemberForm => ({
    ...user,
    ...adaptApplicant(user),
  }),
  STUDENT: (user: Student): StudentForm => ({
    ...user,
    ...adaptStudent(user),
  }),
  TEACHER: (user: Teacher): TeacherForm => ({ ...user, subjects: user.subjects.join(', ') }),
  OTHER: (user: OtherUser): OtherUserForm => ({ ...user }),
} as const;

type FormAdapter = (user: User) => UserForm;

export const adaptFormData = (user: User): UserForm => {
  return (formAdapters[user.userType] as FormAdapter)(user);
};

export const formToApiDto = (form: UserForm): UserUpdateDto => {
  if (form.userType === 'APPLICANT' || form.userType === 'APPLICANT_MEMBER') {
    const { requiredSubjects, optionalSubjects, ...rest } = form;
    return userUpdateDto.parse({
      ...rest,
      subjectScores: [...requiredSubjects, ...optionalSubjects].map((subject) => ({
        subjectName: subject.name,
        subjectScore: subject.score ? Number(subject.score) : null,
      })),
    });
  }

  if (form.userType === 'TEACHER') {
    const { universities, ...rest } = form;
    return userUpdateDto.parse({
      ...rest,
      universityIds: universities.map((university) => university.id),
      subjects: form.subjects.split(',').map((subject) => subject.trim()),
    });
  }

  return userUpdateDto.parse(form);
};
