import { useQuery, useQueryClient } from '@tanstack/react-query';

import { getMe } from '../../api/auth';

export const useUser = (enabled?: boolean) => {
  const queryClient = useQueryClient();
  const { data, isLoading, error } = useQuery({
    queryKey: ['user'],
    queryFn: () => getMe(),
    enabled,
  });

  const invalidateUser = () => {
    queryClient.invalidateQueries({ queryKey: ['user'] });
  };

  const logout = () => {
    queryClient.setQueryData(['user'], null);
    invalidateUser();
  };

  return { user: data, isLoading, error, invalidateUser, logout };
};
