import { Box, Typography } from '@mui/material';
import styles from '../AboutUs.module.scss';

export default function FooterSection() {
  return (
    <div className={styles.sectionWrap}>
      <div className={styles.footerSection}>
        <Box display='flex' alignItems='center' gap={2}>
          <Typography variant='h3'>Створено з</Typography>
          <img src='/emoji/heart.png' alt='Heart' className={styles.heart} />
        </Box>
        <Typography variant='h3'>студентами для абітурієнтів</Typography>
      </div>
    </div>
  );
}
