import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { ChipButton } from '../../../../components/ChipButton/ChipButton';
import { langPath } from '../../../../utils/langPath';
import { ArrowIcon, ArrowIconDirection } from '../../../Offers/icons/ArrowIcon';
import { useRegionFilters } from '../../hooks/useRegionFilters';

import './CitiesList.scss';

export const CitiesList = () => {
  const NUM_REGIONS = 6;
  const { t } = useTranslation();
  const { data, onSelect } = useRegionFilters();

  return (
    <div className='CitiesContainer'>
      <div className='CitiesTitle'>
        <h3>{t('translation:home.city')}</h3>
        <Link className='MoreButton' to={langPath({ pathname: '/offers', search: location.search })}>
          {t('translation:home.more')}
          <ArrowIcon direction={ArrowIconDirection.RIGHT} color='#F59D33' />
        </Link>
      </div>
      <div className='CitiesList'>
        {data?.slice(0, NUM_REGIONS).map((f, i) => (
          <ChipButton key={i} selected={f.isChecked} onClick={() => onSelect(f.id)}>
            {f.name}
          </ChipButton>
        ))}
      </div>
    </div>
  );
};
