import { Box, Typography } from '@mui/material';
import { ReactNode, useState } from 'react';
import { Path, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import Button from '../../../components/ButtonV2/Button';
import { ArrowIcon, ArrowIconDirection } from '../../Offers/icons/ArrowIcon';
import styles from '../profile.module.scss';
import { UserForm } from '../schema/user-form';

type CardProps<T extends UserForm> = {
  title: string;
  checkFields: Path<T>[];
  children: ReactNode;
  onClear?: () => void;
};

const statusIdentifier = {
  filled: '🟢',
  empty: '🟡',
};

export default function Card<T extends UserForm>(props: CardProps<T>) {
  const { t } = useTranslation();
  const [opened, setOpened] = useState(true);
  const form = useFormContext<T>();

  const status = props.checkFields.every((field) => {
    const value = form.getValues(field);
    if (Array.isArray(value)) {
      return value.length > 0;
    }
    return !!value;
  })
    ? 'filled'
    : 'empty';

  return (
    <Box className={styles.cardWrapper}>
      <Box className={styles.cardHeader}>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          <Typography variant='body2'>{statusIdentifier[status]}</Typography>
          <Typography variant='body2'>{props.title}</Typography>
        </Box>
        <ArrowIcon
          direction={opened ? ArrowIconDirection.UP : ArrowIconDirection.DOWN}
          color={opened ? '#F59D33' : '#3C474E'}
          onClick={() => setOpened(!opened)}
        />
      </Box>
      {opened && (
        <>
          {props.children}
          <Box className={styles.cardFooter}>
            {props.onClear && (
              <Button
                disabled={form.formState.isSubmitting}
                variant='text'
                text='body2'
                onClick={props.onClear}
                type='button'
              >
                {t('profile.buttons.clear')}
              </Button>
            )}
            <Button disabled={form.formState.isSubmitting} variant='text' text='body2' type='submit'>
              {t('profile.buttons.save')}
            </Button>
          </Box>
        </>
      )}
    </Box>
  );
}
