import './CodeChip.scss';

type CodeChipProps = {
  code: string | number | undefined;
  size: 'small' | 'big';
  padding?: boolean;
  className?: string;
  id?: string;
};

export const CodeChip = ({ code, size, padding = true, className = '', id }: CodeChipProps) => {
  const content = padding ? code?.toString().padStart(3, '0') : code;

  return (
    <span className={`code-chip ${className}`} data-size={size} id={id}>
      {content}
    </span>
  );
};
