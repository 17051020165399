import { SpecialityFilter } from '../../hooks/useSpecialitiesFilters';
import './Speciality.scss';

type SpecialityProps = {
  filter: SpecialityFilter;
  onSelectMultiple: (ids: number[], value: boolean) => void;
};

export const Speciality = ({ onSelectMultiple, filter }: SpecialityProps) => {
  const toggle = () => {
    onSelectMultiple(
      filter.branchRelatedSpecialities.map((subFilter) => subFilter.id),
      !filter.isChecked,
    );
  };

  return (
    <div className='Speciality' data-selected={filter.isChecked} onClick={toggle}>
      <div className='Icon'>{filter.emoji}</div>
      <p>{filter.branchName}</p>
      <div className='Code'>{filter.code}</div>
    </div>
  );
};
