import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { langPath } from '../../../../utils/langPath';
import { ArrowIcon, ArrowIconDirection } from '../../../Offers/icons/ArrowIcon';
import { useSpecialitiesFilters } from '../../hooks/useSpecialitiesFilters';
import { Speciality } from '../Speciality/Speciality';

import './SpecialitiesList.scss';

const NUM_ROWS = 3;

export const SpecialitiesList = () => {
  const { t } = useTranslation();

  return (
    <div className='SpecialitiesContainer'>
      <div className='SpecialitiesTitle'>
        <h3>{t('translation:home.specialities')}</h3>
        <Link className='MoreButton' to={langPath({ pathname: '/offers', search: location.search })}>
          {t('translation:home.more')}
          <ArrowIcon direction={ArrowIconDirection.RIGHT} color='#F59D33' />
        </Link>
      </div>
      <div className='SpecialitiesList'>
        {Array.from({ length: NUM_ROWS }).map((_, i) => (
          <SpecialitiesRow key={`row-${i}`} rowIndex={i} />
        ))}
      </div>
    </div>
  );
};

function SpecialitiesRow({ rowIndex }: { rowIndex: number }) {
  const { data, onSelectMultiple } = useSpecialitiesFilters();
  const filteredSpecialities = data?.filter((_, j) => j % NUM_ROWS == rowIndex);

  return (
    <div className='SpecialitiesRow'>
      {filteredSpecialities?.map((f, j) => (
        <Speciality key={rowIndex * NUM_ROWS + j} onSelectMultiple={onSelectMultiple} filter={f} />
      ))}
    </div>
  );
}
