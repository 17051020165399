import { Children, isValidElement, JSX, PropsWithChildren, useEffect, useMemo } from 'react';
import { Navigate } from 'react-router-dom';

import { UserType } from '../../schema/user-type';
import { useAuthForm } from '../../state/AuthFormContext';

type BranchProps = PropsWithChildren<{ type: UserType }>;

export const Branch = ({ type, children }: BranchProps): JSX.Element | null => {
  const { role, step, setTotalSteps } = useAuthForm();

  const forms = useMemo(() => Children.toArray(children), [children]);

  useEffect(() => {
    const totalSteps = forms.filter((form) => isValidElement(form) && form.type !== Navigate).length;
    setTotalSteps(type, totalSteps);
  }, []);

  if (role !== type) return null;

  return <>{forms[step - 1]}</>;
};
