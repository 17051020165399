import { useMemo } from 'react';

import { useSpecialities } from '../../../hooks/filters/useSpecialities';
import { SPECIALITIES_PARAM } from '../filters/constants';

import { useFilterSearchParams } from './useFilterSearchParams';

export const useSpecialitiesFilters = () => {
  const { data, isLoading, error } = useSpecialities();

  const { selectedFilters, onSelect, onSelectMultiple, isChecked } = useFilterSearchParams(SPECIALITIES_PARAM, {
    variant: 'multiple',
  });

  const specialities = useMemo(
    () =>
      data?.map((group) => ({
        ...group,
        code: codeRange(group.branchRelatedSpecialities),
        isChecked: group.branchRelatedSpecialities.some((item) => isChecked(item.id)),
        branchRelatedSpecialities: group.branchRelatedSpecialities
          .map((item) => ({
            ...item,
            isChecked: isChecked(item.id),
          }))
          .sort((a, b) => Number(a.code) - Number(b.code)),
      })),
    [data, selectedFilters],
  );

  return { data: specialities, selectedFilters, onSelect, onSelectMultiple, isLoading, error };
};

const codeRange = (subfilters: { code?: string }[]) => {
  const codes = subfilters.map((subItem) => Number(subItem.code)).filter((code) => !Number.isNaN(code));
  const asCode = (code: number) => code.toString().padStart(3, '0');
  return `${asCode(Math.min(...codes))}-${asCode(Math.max(...codes))}`;
};

export type SpecialityFilter = ReturnType<typeof useSpecialitiesFilters>['data'][number];
