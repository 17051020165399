import { z } from 'zod';

import { setupSchema } from './setup-form';

export const applicantPersonalInfoSchema = z.object({
  entranceYear: z.number(),
  country: z.string(),
  city: z.string(),
});

export const applicantSpecialitiesSchema = z.object({
  specialities: z.array(z.number()),
});

export const applicantPreferencesSchema = z.object({
  cityPreferences: z.array(z.number()),
  universityPreferences: z.array(z.number()),
});

export const applicantSchema = z.object({
  setupData: setupSchema.optional(),
  personalData: applicantPersonalInfoSchema.optional(),
  specialitiesData: applicantSpecialitiesSchema.optional(),
  preferencesData: applicantPreferencesSchema.optional(),
});

export type ApplicantSchema = z.infer<typeof applicantSchema>;
export type ApplicantPersonalInfoSchema = z.infer<typeof applicantPersonalInfoSchema>;
export type ApplicantSpecialitiesSchema = z.infer<typeof applicantSpecialitiesSchema>;
export type ApplicantPreferencesSchema = z.infer<typeof applicantPreferencesSchema>;
