import { useMemo } from 'react';

import { useRegions } from '../../../hooks/filters/useRegions';
import { REGIONS_PARAM } from '../filters/constants';

import { useFilterSearchParams } from './useFilterSearchParams';

export const useRegionFilters = () => {
  const { data, isLoading, error } = useRegions();
  const { selectedFilters, onSelect, isChecked } = useFilterSearchParams(REGIONS_PARAM, {
    variant: 'multiple',
  });

  const filters = useMemo(
    () =>
      data?.map((item) => ({
        ...item,
        isChecked: isChecked(item.id),
      })),
    [data, selectedFilters],
  );

  return { data: filters, selectedFilters, onSelect, isLoading, error };
};
