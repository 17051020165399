import './index.scss';

import cx from 'classnames';
import * as React from 'react';

/**
 * Props for the Checkbox component.
 */
type CheckboxProps = Omit<React.InputHTMLAttributes<HTMLInputElement>, 'type'> & {
  /**
   * If true, applies a limiter to the checkbox.
   */
  limiter?: boolean;

  /**
   * If true, renders the checkbox as a radio button.
   */
  radio?: boolean;

  /**
   * The variant of the checkbox, which determines its size.
   * Can be 'default', 'small', or 'big'.
   */
  variant?: 'default' | 'small' | 'big';

  /**
   * The default checked state of the checkbox.
   */
  defaultChecked?: boolean;
};

const styles = {
  default: 'CheckboxMarkIcon__default',
  small: 'CheckboxMarkIcon__small',
  big: 'CheckboxMarkIcon__big',
};

const Checkbox = React.forwardRef<HTMLInputElement, CheckboxProps>(
  ({ variant = 'default', defaultChecked = false, radio, className, style, ...props }, ref) => {
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      if (props.onChange) {
        props.onChange(event);
      }
    };

    return (
      <label className={cx('Checkbox', className)} style={style}>
        <input
          type={radio ? 'radio' : 'checkbox'}
          className='CheckboxInput'
          onChange={handleChange}
          defaultChecked={defaultChecked}
          {...props}
          ref={ref}
        />
        <CheckMarkIcon className={cx(styles[variant], 'CheckboxMarkIcon')} data-radio={radio} />
      </label>
    );
  },
);

type CheckMarkIconProps = React.HTMLAttributes<SVGElement> & {
  width?: string;
  height?: string;
};

const CheckMarkIcon = ({ width = '15', height = '12', ...rest }: CheckMarkIconProps) => (
  <svg {...rest} width={width} height={height} viewBox='0 0 17 13' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      /* eslint-disable-next-line max-len */
      d='M15.9326 0.677476C16.3868 1.10136 16.4115 1.81324 15.9876 2.26751L6.88976 12.0175C6.67157 12.2513 6.36418 12.3813 6.04443 12.3748C5.72469 12.3683 5.42281 12.226 5.21428 11.9836L0.812104 6.8648C0.406976 6.39373 0.460436 5.68343 0.93151 5.2783C1.40258 4.87317 2.11288 4.92663 2.51801 5.39771L6.1014 9.56439L14.3425 0.732494C14.7664 0.278224 15.4783 0.253592 15.9326 0.677476Z'
      fill='white'
    />
  </svg>
);

Checkbox.displayName = 'Checkbox';

export default Checkbox;
