import { TextField, TextFieldProps } from '@mui/material';
import { ChangeEventHandler } from 'react';
interface NumberInputProps extends Omit<TextFieldProps, 'type' | 'onChange'> {
  value: number | '';
  onChange: (value: number | '') => void;
  validator?: (value: number) => boolean;
}

export const NumberInput: React.FC<NumberInputProps> = ({
  value,
  onChange,
  variant = 'outlined',
  validator,
  ...props
}: NumberInputProps) => {
  const handleChange: ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement> = (event) => {
    const newValue = event.target.value;

    if (newValue === '') {
      onChange('');
      return;
    }

    if (/^\d+$/.test(newValue)) {
      const numValue = Number(newValue);
      if (!validator || validator(numValue)) {
        onChange(numValue);
      }
    }
  };

  return (
    <TextField
      type='number'
      variant={variant}
      value={value}
      onChange={handleChange}
      sx={{ textTransform: 'capitalize' }}
      {...props}
    />
  );
};
