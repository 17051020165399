import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { langPath } from '../../../../utils/langPath';
import { DetailedOffer } from '../../types/offer';

import styles from './Offer.module.scss';

export const DetailsCard = ({ offer }: { offer: DetailedOffer }) => {
  const { i18n } = useTranslation();
  return (
    <div className={styles.cardWrap}>
      <div className={styles.title}>Деталі</div>
      <div className={styles.card}>
        <Property title='ЗВО'>
          <Link
            to={langPath(`/universities/${offer.university.slug[i18n.language]}-${offer.university.id}`)}
            className={styles.propertyLink}
          >
            {offer.university.name}
          </Link>
        </Property>
        <Property title='Факультет'>{offer.facultyName}</Property>
        <Property title='Вартість навчання'>{`${offer.price} грн/рік`}</Property>
        <Property title='Форма навчання'>{offer.educationForm}</Property>
        <Property title='Кількість місць'>
          <span className={styles.noBreak}>{offer.maxBudgetLicence} місць бюджет,&nbsp;</span>
          <span className={styles.noBreak}>{offer.maxLicense} - контракт,&nbsp;</span>
          <span className={styles.noBreak}>{offer.totalApplies} - кількість поданих заяв</span>
        </Property>
        <Property title='Посилання на офіційний сайт'>
          <a href={offer.sitelink} target='_blank' rel='noreferrer' className={styles.propertyLink}>
            {offer.sitelink}
          </a>
        </Property>
      </div>
    </div>
  );
};

type PropertyProps = {
  title: string;
  children: ReactNode;
};

const Property = ({ children, title }: PropertyProps) => {
  return (
    <div className={styles.propertyWrap}>
      <div className={styles.propertyTitle}>{title}</div>
      <div className={styles.propertyValue}>{children}</div>
    </div>
  );
};
