import { useMemo } from 'react';

import { useQualifications } from '../../../hooks/filters/useQualifications';
import { EDUCATION_BASES_PARAM } from '../filters/constants';

import { useFilterSearchParams } from './useFilterSearchParams';

export const useEducationBasesFilters = () => {
  const { data, isLoading, error } = useQualifications();
  const { selectedFilter, onSelect, isChecked } = useFilterSearchParams(EDUCATION_BASES_PARAM, {
    variant: 'single',
    required: true,
    defaultValue: data?.[0]?.id,
  });

  const filters = useMemo(
    () =>
      data?.map((item) => ({
        ...item,
        isChecked: isChecked(item.id),
      })),
    [data, selectedFilter],
  );

  return { data: filters, selectedFilter, onSelect, isLoading, error };
};
