export const useCountryOptions = () => {
  const countryOptions = countries.map((country) => ({
    id: country,
    label: country,
  }));

  return { countryOptions };
};

const countries: string[] = [
  'Україна',
  'Австралія',
  'Австрія',
  'Азербайджан',
  'Албанія',
  'Алжир',
  'Ангола',
  'Андорра',
  'Аргентина',
  'Арменія',
  'Афганістан',
  'Багамські Острови',
  'Бангладеш',
  'Барбадос',
  'Бахрейн',
  'Беліз',
  'Бельгія',
  'Бенін',
  'Білорусь',
  'Болгарія',
  'Болівія',
  'Боснія і Герцеговина',
  'Ботсвана',
  'Бразилія',
  'Бруней',
  'Буркіна-Фасо',
  'Бурунді',
  'Бутан',
  'Вануату',
  'Ватикан',
  'Велика Британія',
  'Венесуела',
  "В'єтнам",
  'Вірменія',
  'Габон',
  'Гаїті',
  'Гаяна',
  'Гамбія',
  'Гана',
  'Гватемала',
  'Гвінея',
  'Гвінея-Бісау',
  'Греція',
  'Грузія',
  'Данія',
  'Джибуті',
  'Домініка',
  'Домініканська Республіка',
  'Еквадор',
  'Екваторіальна Гвінея',
  'Еритрея',
  'Естонія',
  'Есватіні',
  'Ефіопія',
  'Єгипет',
  'Ємен',
  'Замбія',
  'Зімбабве',
  'Ізраїль',
  'Індія',
  'Індонезія',
  'Ірак',
  'Іран',
  'Ірландія',
  'Ісландія',
  'Іспанія',
  'Італія',
  'Йорданія',
  'Кабо-Верде',
  'Казахстан',
  'Камбоджа',
  'Камерун',
  'Канада',
  'Катар',
  'Кенія',
  'Киргизстан',
  'Китай',
  'Кіпр',
  'Кірібаті',
  'Колумбія',
  'Коморські Острови',
  'Конго (Браззавіль)',
  'Конго (Кіншаса)',
  'Коста-Рика',
  "Кот-д'Івуар",
  'Куба',
  'Кувейт',
  'Лаос',
  'Латвія',
  'Лесото',
  'Ліберія',
  'Ліван',
  'Лівія',
  'Литва',
  'Ліхтенштейн',
  'Люксембург',
  'Маврикій',
  'Мавританія',
  'Мадагаскар',
  'Малаві',
  'Малайзія',
  'Малі',
  'Мальдіви',
  'Мальта',
  'Марокко',
  'Маршаллові Острови',
  'Мексика',
  'Мікронезія',
  'Мозамбік',
  'Молдова',
  'Монако',
  'Монголія',
  'Монтенегро',
  "М'янма",
  'Намібія',
  'Науру',
  'Непал',
  'Нідерланди',
  'Нігер',
  'Нігерія',
  'Нікарагуа',
  'Німеччина',
  'Нова Зеландія',
  'Норвегія',
  'Оман',
  'Пакистан',
  'Палау',
  'Панама',
  'Папуа Нова Гвінея',
  'Парагвай',
  'Перу',
  'Південна Африка',
  'Південна Корея',
  'Північна Корея',
  'Північна Македонія',
  'Польща',
  'Португалія',
  'росія',
  'Руанда',
  'Румунія',
  'Сальвадор',
  'Самоа',
  'Сан-Марино',
  'Сан-Томе і Принсіпі',
  'Саудівська Аравія',
  'Сейшельські Острови',
  'Сенегал',
  'Сент-Вінсент і Гренадини',
  'Сент-Кіттс і Невіс',
  'Сент-Люсія',
  'Сербія',
  'Сінгапур',
  'Сирія',
  'Словаччина',
  'Словенія',
  'Соломонові Острови',
  'Сомалі',
  'Судан',
  'Сурінам',
  'Східний Тимор',
  'Сьєрра-Леоне',
  'Таджикистан',
  'Таїланд',
  'Танзанія',
  'Того',
  'Тонга',
  'Тринідад і Тобаго',
  'Тувалу',
  'Туніс',
  'Туркменістан',
  'Туреччина',
  'Уганда',
  'Угорщина',
  'Узбекистан',
  'Уругвай',
  'Фіджі',
  'Філіппіни',
  'Фінляндія',
  'Франція',
  'Хорватія',
  'Центральноафриканська Республіка',
  'Чад',
  'Чехія',
  'Чилі',
  'Чорногорія',
  'Швейцарія',
  'Швеція',
  'Шрі-Ланка',
  'Ямайка',
  'Японія',
];
