import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';

import styles from './TabNavigation.module.scss';

export const TabNavigation: FC = () => {
  const { t } = useTranslation();

  const [searchParams, setSearchParams] = useSearchParams();

  const activeTab = searchParams.get('tab') || 'contacts';

  const tabs = [
    // { id: 'info', label: t('universityPage.tabs.info') },
    { id: 'contacts', label: t('universityPage.tabs.contacts') },
    { id: 'structure', label: t('universityPage.tabs.structure') },
    { id: 'specialties', label: t('universityPage.tabs.specialties') },
    { id: 'competitive', label: t('universityPage.tabs.competitive') },
  ];

  return (
    <div className={styles.tabsContainer}>
      {tabs.map((tab) => (
        <button
          key={tab.id}
          className={`${styles.tabButton} ${activeTab === tab.id ? styles.active : ''}`}
          onClick={() => setSearchParams({ tab: tab.id })}
          type='button'
        >
          {tab.label}
        </button>
      ))}
    </div>
  );
};
