import { Link } from '../Link/Link';

export default function AuthSection() {
  return (
    <div className='AuthContainer'>
      <Link variant='button-outline' to='/auth/login'>
        Увійти
      </Link>
      <Link variant='button' to='/auth/register'>
        Зареєструватись
      </Link>
    </div>
  );
}
