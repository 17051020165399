import { Typography } from '@mui/material';
import styles from '../Partners.module.scss';
import { Link } from '../../../components/Link/Link';

export default function BecomePartnerSection() {
  return (
    <div className={styles.sectionWrap}>
      <div className={styles.becomePartnerSection}>
        <Typography variant='h2'>Ставайте нашим партнером</Typography>
        <Typography variant='body1'>
          Маєте ідею спільного проєкту, хочете підтримати нас або поділитися ініціативою?
          <br />
          Заповніть коротку форму нижче — і ми зв’яжемося з вами якнайшвидше!
        </Typography>
        <Link
          variant='button'
          to='https://forms.gle/Mq15MVqdBoDjSeMp9'
          target='_blank'
          rel='noopener noreferrer'
          className={styles.becomePartnerButton}
          text='button'
        >
          Стати партнером
        </Link>
      </div>
    </div>
  );
}
