import { Typography } from '@mui/material';
import { FieldError, Merge } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import styles from '../auth.module.scss';

type ErrorType = Merge<FieldError, (FieldError | undefined)[]> | undefined;

export default function ValidationError({ error }: { error: ErrorType }) {
  const { t } = useTranslation();
  return error?.message ? (
    <Typography variant='body2' className={styles.error}>
      {t('auth.field-required')}
    </Typography>
  ) : null;
}
