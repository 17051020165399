import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { SortIcon } from '../../icons/SortIcon';

import styles from './SortButton.module.scss';

interface SortButtonProps {
  isActive: boolean;
  onToggle: () => void;
}

export const SortButton: FC<SortButtonProps> = ({ isActive, onToggle }) => {
  const { t } = useTranslation();

  return (
    <button
      className={`${styles.sortButton} ${isActive ? styles.active : ''}`}
      onClick={onToggle}
      aria-label={t('universityPage.common.sortButton.ariaLabel')}
    >
      <SortIcon className={styles.sortIcon} />
      {t('universityPage.common.sortButton.text')}
    </button>
  );
};
