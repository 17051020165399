import { useFormContext, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import FormAutocomplete from '../../../components/mui-form/FormAutocomplete';
import FormTextField from '../../../components/mui-form/FormTextField';
import { useFacultyOptions } from '../../../hooks/filters/useFaculties';
import { useSpecialityOptions } from '../../../hooks/filters/useSpecialities';
import { useUniversityOptions } from '../../../hooks/filters/useUniversities';
import { useAuth } from '../../Auth/state/AuthContext';
import Card from '../components/Card';
import InputGroup from '../components/InputGroup';
import NameCard from '../components/NameCard';
import { StudentForm } from '../schema/user-form';

export default function StudentProfile() {
  const { t } = useTranslation();
  const { user } = useAuth();
  const { universityOptions } = useUniversityOptions();
  const { specialityOptions } = useSpecialityOptions();

  const form = useFormContext<StudentForm>();

  const selectedUniversity = useWatch({ control: form.control, name: 'universityId' });
  const { facultyOptions } = useFacultyOptions([selectedUniversity]);

  const onClearStudyInfo = () => {
    form.setValue('universityId', null);
    form.setValue('facultyId', null);
    form.setValue('specialityId', null);
  };

  return (
    <>
      <NameCard name={user?.name} userType='STUDENT' />
      <Card title={t('profile.personalData.title')} checkFields={['name']}>
        <InputGroup>
          <FormTextField name='name' label={t('profile.personalData.name')} />
        </InputGroup>
      </Card>
      <Card
        title={t('profile.studyInfo.title')}
        checkFields={['universityId', 'facultyId', 'specialityId']}
        onClear={onClearStudyInfo}
      >
        <InputGroup>
          <FormAutocomplete
            name='universityId'
            label={t('profile.studyInfo.university')}
            options={universityOptions}
            placeholder={t('profile.studyInfo.universityPlaceholder')}
          />
          <FormAutocomplete
            disabled={!selectedUniversity}
            name='facultyId'
            label={t('profile.studyInfo.faculty')}
            options={facultyOptions}
            placeholder={t('profile.studyInfo.facultyPlaceholder')}
          />
          <FormAutocomplete
            name='specialityId'
            label={t('profile.studyInfo.speciality')}
            options={specialityOptions}
            placeholder={t('profile.studyInfo.specialityPlaceholder')}
          />
        </InputGroup>
      </Card>
    </>
  );
}
