import React from 'react';

import AbitlyLogo from '../../../components/Logo/AbitlyLogo';
import styles from '../auth.module.scss';

import CrossButton from './CrossButton';
import Step from './Step';

type RegisterBaseProps = {
  disableStep?: boolean;
  children: React.ReactNode;
};

export default function RegisterBase(props: RegisterBaseProps) {
  return (
    <div className={styles.regiterBaseWrap}>
      <div className={`${styles.registerBaseTopBar} ${styles.mobileBar}`}>
        {props.disableStep ? <span></span> : <Step />}
        <CrossButton />
      </div>
      <div className={styles.mobile}>
        <AbitlyLogo to='/' width={156} />
      </div>
      <div className={styles.registerBaseContent}>
        <div className={`${styles.desktopBar} ${styles.topLeftCorner}`}>
          {props.disableStep ? <span></span> : <Step />}
        </div>
        <div className={`${styles.desktopBar} ${styles.registerBaseTopBar}`}>
          <AbitlyLogo to='/' width={156} />
        </div>
        <CrossButton className={`${styles.topRightCorner} ${styles.desktopBar}`} />
        {props.children}
      </div>
    </div>
  );
}
