import { Grid2 } from '@mui/material';

import AbitlySection from './components/AbitlySection';
import MotivationSection from './components/MotivationSection';
import ParticipantsSection from './components/ParticipantsSection';
import ValuesSection from './components/ValuesSection';

import styles from './AboutUs.module.scss';
import HeaderSection from './components/HeaderSection';
import FooterSection from './components/FooterSection';

const AboutUs = () => {
  return (
    <Grid2 className={styles.mainContainer}>
      <HeaderSection />
      <AbitlySection />
      <MotivationSection />
      <ValuesSection />
      <ParticipantsSection />
      <FooterSection />
    </Grid2>
  );
};

export default AboutUs;
