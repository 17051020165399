import { Grid } from '@mui/material';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';
import { useTranslation } from 'react-i18next';

import { settings } from '../../../theme';
import { how_to_choose_high_education_en } from '../articles/en/how_to_choose_high_education';
import { nmt_coefficients_2025_en } from '../articles/en/nmt-coefficients-2025';
import { how_to_choose_high_education_uk } from '../articles/uk/how_to_choose_high_education';
import { nmt_coefficients_2025_uk } from '../articles/uk/nmt-coefficients-2025';
import ArticleCard from '../components/ArticleCard';

const useStyles = makeStyles(() => ({
  articlesWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '80px',
    width: '90%',
    maxWidth: '1200px',
    padding: '80px 40px',
    justifyContent: 'center',
    alignSelf: 'center',
    media: {
      sm: {
        gap: '60px',
        padding: '40px ',
      },
    },
  },
}));
function Articles() {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <>
      <Grid
        textAlign='center'
        py='60px'
        px='20px'
        mb='30px'
        sx={{
          backgroundColor: settings.primary20,
        }}
      >
        <Typography variant='h1'>{t('blog.title')}</Typography>
        <Typography variant='caption'>{t('blog.subTitle')}</Typography>
      </Grid>
      <div className={classes.articlesWrapper}>
        <ArticleCard markdowns={{ en: nmt_coefficients_2025_en, uk: nmt_coefficients_2025_uk }} />
        <ArticleCard markdowns={{ en: how_to_choose_high_education_en, uk: how_to_choose_high_education_uk }} />
      </div>
    </>
  );
}
export default Articles;
