import { FunctionComponent, ImgHTMLAttributes } from 'react';

import { APP_ICON_SIZE } from '../../common/config';
import { LANGUAGES } from '../../common/i18n';

import LogoIcon from './icons/logo.svg';
import UnitedKingdomIcon from './icons/uk.png';
import UkraineIcon from './icons/ukraine.png';

export const ICONS: Record<string, string> = {
  logo: LogoIcon,
  [LANGUAGES.uk]: UkraineIcon,
  [LANGUAGES.en]: UnitedKingdomIcon,
};

export interface AppIconProps extends ImgHTMLAttributes<HTMLImageElement> {
  color?: string;
  icon?: string;
  size?: string | number;
  title?: string;
}

const Icon: FunctionComponent<AppIconProps> = ({ icon = 'default', size = APP_ICON_SIZE, style, ...restOfProps }) => {
  const iconName = (icon || 'default').trim().toLowerCase();

  const iconPath = ICONS[iconName];
  if (!iconPath) {
    console.warn(`AppIcon: icon "${iconName}" is not found!`);
  }

  const classToRender = {
    height: size,
    size,
    style: { ...style },
    width: size,
    ...restOfProps,
  };

  return <img {...classToRender} src={iconPath} alt={iconName} />;
};

export default Icon;
