import { Box, Typography } from '@mui/material';

import { settings } from '../../../theme';
import { UserType } from '../../Auth/schema/user-type';
import { profileVariants } from '../constants/profileVariants';
import styles from '../profile.module.scss';

type NameCardProps = {
  name: string;
  userType: Exclude<UserType, 'UNKNOWN'>;
};

export default function NameCard({ name, userType }: NameCardProps) {
  return (
    <Box className={styles.cardWrapper}>
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
        <img src={profileVariants[userType].icon} alt={profileVariants[userType].description} />
        <Typography variant='body1'>{name}</Typography>
      </Box>
      <Typography color={settings.darkest80} variant='body2'>
        {profileVariants[userType].description}
      </Typography>
    </Box>
  );
}
