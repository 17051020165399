import { Typography } from '@mui/material';

import { NoQueryPage } from '../../components/NoQueryPage/NoQueryPage';
import './PrivacyPage.scss';

const PrivacyPage = () => {
  return (
    <>
      <div className='page-wrap'>
        <div className='block'>
          <Typography variant='h1'>Політика конфіденційності</Typography>
          <Typography variant='body2'>
            <b>Дата набирання чинності:</b> 01.01.2025
          </Typography>
        </div>
        <Typography variant='body2'>
          <span className='main-color'>Abitly.org</span> (&quot;ми&quot;, &quot;наш&quot; або &quot;нас&quot;) серйозно
          ставиться до захисту вашої конфіденційності. Ця Політика конфіденційності пояснює, як ми збираємо,
          використовуємо та захищаємо вашу інформацію під час використання нашого Сайту.
        </Typography>
        <div className='block'>
          <Typography variant='h1'>Збір інформації</Typography>
          <Typography variant='body2'>Ми можемо збирати наступні види даних:</Typography>
          <ul className='list'>
            <li>
              <Typography variant='body2'>
                <b>Особиста інформація:</b> Ім&apos;я, електронна пошта, інша інформація, яку ви надаєте при
                використанні нашого Сайту.
              </Typography>
            </li>

            <li>
              <Typography variant='body2'>
                <b>Технічна інформація:</b> Інформація про ваш пристрій, браузер, IP-адресу та поведінку на нашому
                Сайті.
              </Typography>
            </li>
          </ul>
        </div>
        <div className='block'>
          <Typography variant='h1'>Використання інформації</Typography>
          <Typography variant='body2'>Ми використовуємо вашу інформацію для:</Typography>
          <ul className='list'>
            <li>
              <Typography variant='body2'>Надання послуг та підтримки.</Typography>
            </li>
            <li>
              <Typography variant='body2'>Комунікації щодо оновлень або змін.</Typography>
            </li>
            <li>
              <Typography variant='body2'>Персоналізації вашого досвіду.</Typography>
            </li>
          </ul>
        </div>
        <div className='block'>
          <Typography variant='h1'>Зберігання даних</Typography>
          <Typography variant='body2'>
            Ми зберігаємо вашу інформацію лише стільки, скільки це необхідно для виконання вищезазначених цілей.
          </Typography>
        </div>
        <div className='block'>
          <Typography variant='h1'>Захист даних</Typography>
          <Typography variant='body2'>
            Ми використовуємо сучасні технології та протоколи для забезпечення безпеки вашої інформації. Дані
            передаються через захищені канали SSL.
          </Typography>
        </div>
        <div className='block'>
          <Typography variant='h1'>Треті сторони</Typography>
          <Typography variant='body2'>
            Ми не передаємо вашу інформацію третім сторонам, за винятком випадків, передбачених законом або коли це
            необхідно для надання послуг (наприклад, хостинг).
          </Typography>
        </div>
        <div className='block'>
          <Typography variant='h1'>Оновлення політики</Typography>
          <Typography variant='body2'>
            Ми залишаємо за собою право змінювати цю Політику конфіденційності. Оновлення будуть публікуватися на цій
            сторінці із зазначенням дати набрання чинності.
          </Typography>
        </div>
        <div className='block'>
          <Typography variant='h1'>Зв&apos;яжіться з нами</Typography>
          <Typography variant='body2'>
            Якщо у вас є запитання або пропозиції, будь ласка, зв’яжіться з нами за адресою{' '}
            <a className='link' href='mailto:info@abitly.org'>
              info@abitly.org
            </a>
            .
          </Typography>
        </div>
      </div>
    </>
  );
};

const WrappedPrivacyPage = () => (
  <NoQueryPage>
    <PrivacyPage />
  </NoQueryPage>
);

export default WrappedPrivacyPage;
