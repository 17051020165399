import { useMemo } from 'react';

import { useEducationForms } from '../../../hooks/filters/useEducationForms';
import { EDUCATION_FORM_PARAM } from '../filters/constants';

import { useFilterSearchParams } from './useFilterSearchParams';

export const useEducationFormFilters = () => {
  const { data, isLoading, error } = useEducationForms();
  const { selectedFilters, onSelect, isChecked } = useFilterSearchParams(EDUCATION_FORM_PARAM, {
    variant: 'multiple',
  });

  const filters = useMemo(
    () =>
      data?.map((item) => ({
        ...item,
        isChecked: isChecked(item.id),
      })),
    [data, selectedFilters],
  );

  return { data: filters, selectedFilters, onSelect, isLoading, error };
};
