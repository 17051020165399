import { Route, Routes, useLocation } from 'react-router-dom';

import UniversityPage from './UniversityPage/UniversityPage';

export const UniversitiesRoutes = () => {
  const location = useLocation();

  return (
    <Routes location={location}>
      {/* 
        I will need this later on, so plz just leave it here
        
        <Route path='/' element={<AllUniversitiesPage />} />
        <Route path='/regions' element={<AllRegionsPage />} />
        <Route path='/regions/:id' element={<RegionUniversitiesPage />} /> */}
      <Route path='/:slug' element={<UniversityPage />} />
    </Routes>
  );
};
