import { Navigate } from 'react-router-dom';

import { langPath } from '../../../../utils/langPath';
import RegisterBase from '../../components/RegisterBase';
import ApplicantPersonalInfoForm from '../RegisterFlow/Applicant/PersonalInfoForm';
import ApplicantPreferencesForm from '../RegisterFlow/Applicant/PreferencesForm';
import ApplicantSpecialitiesForm from '../RegisterFlow/Applicant/SpecialitiesForm';
import ApplicantMemberPersonalInfoForm from '../RegisterFlow/ApplicantMember/PersonalInfoForm';
import ApplicantMemberPreferencesForm from '../RegisterFlow/ApplicantMember/PreferencesForm';
import ApplicantMemberSpecialitiesForm from '../RegisterFlow/ApplicantMember/SpecialitiesForm';
import OtherUserForm from '../RegisterFlow/OtherUser/OtherForm';
import SetupForm from '../RegisterFlow/Setup/SetupForm';
import StudentForm from '../RegisterFlow/Student/StudentForm';
import TeacherForm from '../RegisterFlow/Teacher/TeacherForm';

import { Branch } from './Branch';

export default function RegisterMultiform() {
  return (
    <RegisterBase>
      <Branch type='UNKNOWN'>
        <SetupForm />
      </Branch>

      <Branch type='APPLICANT'>
        <SetupForm />
        <ApplicantPersonalInfoForm />
        <ApplicantSpecialitiesForm />
        <ApplicantPreferencesForm />
        <Navigate to={langPath('/auth/register-completed')} replace />
      </Branch>

      <Branch type='APPLICANT_MEMBER'>
        <SetupForm />
        <ApplicantMemberPersonalInfoForm />
        <ApplicantMemberSpecialitiesForm />
        <ApplicantMemberPreferencesForm />
        <Navigate to={langPath('/auth/register-completed')} replace />
      </Branch>

      <Branch type='STUDENT'>
        <SetupForm />
        <StudentForm />
        <Navigate to={langPath('/auth/register-completed')} replace />
      </Branch>

      <Branch type='TEACHER'>
        <SetupForm />
        <TeacherForm />
        <Navigate to={langPath('/auth/register-completed')} replace />
      </Branch>

      <Branch type='OTHER'>
        <SetupForm />
        <OtherUserForm />
        <Navigate to={langPath('/auth/register-completed')} replace />
      </Branch>
    </RegisterBase>
  );
}
