import { useTranslation } from 'react-i18next';

import FormTextField from '../../../components/mui-form/FormTextField';
import { useAuth } from '../../Auth/state/AuthContext';
import Card from '../components/Card';
import InputGroup from '../components/InputGroup';
import NameCard from '../components/NameCard';

export default function OtherUserProfile() {
  const { t } = useTranslation();
  const { user } = useAuth();

  return (
    <>
      <NameCard name={user?.name} userType='OTHER' />
      <Card title={t('profile.personalData.title')} checkFields={['name', 'aboutMe']}>
        <InputGroup>
          <FormTextField name='name' label={t('profile.personalData.name')} />
          <FormTextField name='aboutMe' label={t('profile.aboutMe.shortDescription')} />
        </InputGroup>
      </Card>
    </>
  );
}
