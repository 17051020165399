import { Box } from '@mui/material';
import { ReactNode } from 'react';

import { useToggle } from '../../../../hooks/utils/useToggle';
import { useShowMore } from '../../hooks/useShowMore';
import { ArrowIcon, ArrowIconDirection } from '../../icons/ArrowIcon';
import { CodeChip } from '../CodeChip/CodeChip';
import FiltersBlock from '../FilterBlock/FilterBlock';
import { FilterCheckbox } from '../FilterCheckbox/FilterCheckbox';
import { SubTitle } from '../SubTitle/SubTitle';

import styles from './FilterCard.module.scss';
export type FilterItem = {
  id?: number;
  name?: string;
  code?: string;
  isChecked: boolean;
};

type FilterCardProps = {
  title: ReactNode;
  itemsToShow?: number;
  data?: FilterItem[];
  selectedFilters?: number[];
  selectedFilter?: number;
  onSelect: (id: number | string) => void;
};

export const FilterCard = (props: FilterCardProps) => {
  const [opened, toggle] = useToggle(true);
  const isArray = Array.isArray(props.selectedFilters);
  const choice = isArray ? 'multiple' : 'single';

  let numberOfSelectedFilters = 0;
  if (isArray) {
    numberOfSelectedFilters = props.selectedFilters?.length ?? 0;
  } else if (props.selectedFilter !== undefined) {
    numberOfSelectedFilters = props.selectedFilter !== null ? 1 : 0;
  }

  return (
    <FiltersBlock>
      <div className={styles.wrapBlock}>
        <SubTitle onClick={toggle}>
          <CodeChip className={styles.filterCount} size='big' code={numberOfSelectedFilters} padding={false} />
          {props.title}
        </SubTitle>
        <ArrowIcon
          direction={opened ? ArrowIconDirection.UP : ArrowIconDirection.DOWN}
          color={opened ? '#F59D33' : '#3C474E'}
          onClick={toggle}
        />
      </div>
      {opened && <Filters {...props} choice={choice} />}
    </FiltersBlock>
  );
};

type FilterChoicesProps = {
  itemsToShow?: number;
  data?: FilterItem[];
  choice: 'single' | 'multiple';
  onSelect: (id: number | string) => void;
};

const Filters = (props: FilterChoicesProps) => {
  const { data, clicks, setClicks, countOfLeftFilters, step } = useShowMore(props.data, props.itemsToShow);

  return (
    <div>
      {data?.map((item) => (
        <FilterCheckbox key={item.id} item={item} choice={props.choice} size='big' onSelect={props.onSelect} />
      ))}
      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
        {countOfLeftFilters > 0 && (
          <p className={styles.showMore} onClick={() => setClicks((prev) => prev + 1)}>
            Показати ще {Math.min(countOfLeftFilters, step)}
          </p>
        )}
        {clicks > 0 && (
          <p className={styles.showMore} onClick={() => setClicks(0)}>
            Згорнути фільтри
          </p>
        )}
      </Box>
    </div>
  );
};
