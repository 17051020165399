import { Autocomplete, createFilterOptions, FormControl, TextField } from '@mui/material';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { fetchSpecialitiesAll } from '../../../../../../api/calculator';
import {
  SpecialityAllRes,
  SpecialityShort,
  SpecialityWithCoefficients,
} from '../../../../../../api/types/specialities';
import styles from '../../SubjectInputs.module.scss';
type SpecialityDropdownProps = {
  setSpecialityId: (speciality: number | null) => void;
};

export const SpecialityDropdown = ({ setSpecialityId }: SpecialityDropdownProps) => {
  const [specialitiesOptions, setSpecialitiesOptions] = useState<SpecialityShort[]>([]);

  const { t } = useTranslation();

  const handleSpecialityChange = (_event: React.SyntheticEvent, value: SpecialityShort | null) => {
    setSpecialityId(value ? value.id : null);
  };
  const flattenSpecialities = (data: SpecialityAllRes): SpecialityWithCoefficients[] => {
    const flatList: SpecialityWithCoefficients[] = [];

    data.forEach((branch) => {
      branch.relatedSpecialities.forEach((speciality) => {
        flatList.push(speciality);
      });
    });

    return flatList;
  };
  useEffect(() => {
    fetchSpecialitiesAll().then((data) => {
      const allSpecialities = flattenSpecialities(data);
      const sortedData = [...allSpecialities].sort((a, b) =>
        a.code.localeCompare(b.code, undefined, { sensitivity: 'base' }),
      );
      setSpecialitiesOptions(sortedData);
    });
  }, []);

  const filterOptions = createFilterOptions<SpecialityShort>({
    matchFrom: 'any',
    ignoreAccents: true,
    ignoreCase: true,
    trim: true,
    limit: 100,
  });
  return (
    <div className={styles.specialityDropdown}>
      <p className='h2' style={{ marginBottom: 4 }}>
        {t('calculator.inputs.titles.speciality')}
      </p>
      <p style={{ marginBottom: 16 }}>{t('calculator.inputs.descriptions.speciality')}</p>
      <FormControl sx={{ minWidth: 200, width: '100%' }}>
        <Autocomplete
          id='specialityDropdown'
          options={specialitiesOptions}
          onChange={handleSpecialityChange}
          getOptionLabel={({ code, name }) => `${code} ${name}`}
          renderInput={(params) => <TextField {...params} label={t('calculator.inputs.specialityLabel') + '*'} />}
          sx={{ textTransform: 'capitalize', backgroundColor: 'transparent' }}
          filterOptions={(options, state) => {
            const filtered = filterOptions(options, state);

            if (filtered.length === 0 && state.inputValue) {
              return options.filter((option) => {
                const searchTerm = state.inputValue.toLowerCase();
                const optionText = `${option.code} ${option.name}`.toLowerCase();

                return (
                  optionText.includes(searchTerm) ||
                  option.name.toLowerCase().includes(searchTerm) ||
                  option.code.toLowerCase().includes(searchTerm)
                );
              });
            }

            return filtered;
          }}
        />
      </FormControl>
    </div>
  );
};
