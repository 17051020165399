export const parseSlug = (paramValue: string) => {
  const lastHyphenIndex = paramValue.lastIndexOf('-');

  if (lastHyphenIndex === -1) {
    return { universitySlug: paramValue, id: '' };
  }

  const universitySlug = paramValue.substring(0, lastHyphenIndex);

  const id = paramValue.substring(lastHyphenIndex + 1);

  return { universitySlug, id };
};
