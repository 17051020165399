import { useTranslation } from 'react-i18next';

import Button from '../../../components/button';

import { ArrowRight } from './ArrowRight';
import styles from './ArticleCard.module.scss';

interface Props {
  markdowns: {
    [language: string]: {
      title: string;
      subTitle: string;
      src: string;
      markdown: string;
      tag: string;
      publishedDate?: string;
    };
  };
}

export default function ArticleCard({ markdowns }: Props) {
  const { i18n, t } = useTranslation();

  const { title, subTitle, src, tag, publishedDate } =
    markdowns[i18n.language] || markdowns['uk'] || Object.values(markdowns)[0];

  const formattedDate = publishedDate
    ? new Date(publishedDate).toLocaleDateString(i18n.language, {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
      })
    : '';

  return (
    <div className={styles.card}>
      <div className={styles.imageContainer}>
        <img src={src} alt={title} className={styles.image} />
      </div>

      <div className={styles.content}>
        <h2 className={styles.title}>{title}</h2>
        <p className={styles.description}>{subTitle}</p>
        {publishedDate && (
          <p className={styles.publishDate}>
            {t('blog.published')}: {formattedDate}
          </p>
        )}
        <Button className={styles.button} to={`/blog/${tag}`}>
          <span>Читати детальніше</span>
          <ArrowRight color='white' />
        </Button>
      </div>
    </div>
  );
}
