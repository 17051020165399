import { useMediaQuery } from '@mui/material';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { fetchSpecialitiesAll } from '../../api/calculator';
import { SpecialityAllRes } from '../../api/types/specialities';
import CustomBreadcrumbs from '../../components/Breadcrumbs/Breadcrumbs';
import { SEO } from '../../components/seo/Seo';
import { langPath } from '../../utils/langPath';
import { SmoothScroll } from '../../utils/smoothScroll';
import { RulesOfCalc } from '../Calculator/components/RulesOfCalc/RulesOfCalc';

import styles from './Coefficients.module.scss';
import { CoefficientsTable } from './components/CoefficientsTable/CoefficientsTable';
import { TelegramButtonLink, TelegramButtonLinkMobile } from './components/TelegramButtonLink/TelegramButtonLink';
import { BRANCHES } from './constants/branches';

const CoefficientsPage = () => {
  const isMobile = useMediaQuery('(max-width: 767px)');
  const [tableData, setTableData] = useState<SpecialityAllRes>([]);
  const { t } = useTranslation();

  useEffect(() => {
    fetchSpecialitiesAll().then((data) => setTableData(data));
  }, []);

  const breadcrumbItems = [
    {
      title: t('blog.title') || 'Блог',
      href: langPath('/blog'),
    },
    {
      title: t('coefficients.title') || 'Коефіцієнти НМТ',
      href: langPath('/blog/nmt-coefficients'),
    },
  ];

  return (
    <>
      <SmoothScroll />
      <SEO
        titleKey='seo:coefficients.title'
        descriptionKey='seo:coefficients.description'
        canonicalPath='/blog/nmt-coefficients'
      />
      <CustomBreadcrumbs items={breadcrumbItems} />
      <div className={styles.container}>
        <div className={styles.containerRight}>
          <div className={styles.textContainer}>
            {/* HEADER */}
            <h1 className={styles.title}>{t('coefficients.title')}</h1>
            <p className={styles.published}>{t('coefficients.published')}</p>
            <p>{t('coefficients.intro')}</p>
            <p className={styles.mon}>
              📌
              <Link
                to='https://mon.gov.ua/osvita/vyshcha-osvita-ta-osvita-doroslykh/vstupna-kampaniia-do-zakladiv-vyshchoi-osvity-2025-roku/poriadok-pryiomu-dlia-zdobuttia-vyshchoi-osvity-v-2025-rotsi'
                target='_blank'
                rel='noopener nofollow noreferrer'
              >
                {t('coefficients.mon_approved')}
              </Link>
              , {t('coefficients.calculate_your_score')}{' '}
              <Link to={langPath('/nmt-calculator')}>{t('coefficients.calculate_link')}</Link>
            </p>
            {/* HEADER */}

            {/* Table of contents */}
            <p className={styles.subtitle}>{t('coefficients.contents')}</p>
            <ol className={styles.list}>
              <li>
                <a href='#whatAreCoefs'>{t('coefficients.toc.what_are')}</a>
              </li>
              <li>
                <a href='#coefsBySpecialties'>{t('coefficients.toc.by_fields')}</a>
                <ul className={styles.sublist}>
                  {BRANCHES.map(({ code, name }) => (
                    <li key={code}>
                      <a href={`#${code}`}>
                        {code} «{name}»
                      </a>
                    </li>
                  ))}
                </ul>
              </li>
              <li>
                <a href='#howToCalc'>{t('coefficients.toc.how_to_calc')}</a>
              </li>
              <li>
                <a href='#howToUse'>{t('coefficients.toc.how_to_use')}</a>
              </li>
            </ol>
            {/* Table of contents */}

            {/* What are coefficients */}
            <h2 style={{ marginBottom: 16 }} className={styles.subtitle} id='whatAreCoefs'>
              {t('coefficients.what_are.title')}
            </h2>
            <p style={{ marginBottom: 8 }}>{t('coefficients.what_are.definition')}</p>
            <p style={{ marginBottom: 8 }}>{t('coefficients.what_are.simple_words')}</p>
            <ul style={{ marginBottom: 8 }}>
              <li>{t('coefficients.what_are.technical')}</li>
              <li>{t('coefficients.what_are.humanitarian')}</li>
            </ul>
            <p style={{ marginBottom: 24 }}>{t('coefficients.what_are.higher_coefficient')}</p>
            {/* What are coefficients */}

            {/* Table */}
            <h2 style={{ marginBottom: 16 }} className={styles.subtitle} id='coefsBySpecialties'>
              {t('coefficients.by_specialties.title')}
            </h2>
            <p>{t('coefficients.by_specialties.description')}</p>
            <Link
              to='https://t.me/abitlyorg'
              target='_blank'
              rel='noopener nofollow noreferrer'
              onClick={() => {
                if (!(window.location.hostname === 'localhost')) {
                  window.dataLayer = window.dataLayer || [];
                  window.dataLayer.push({
                    event: 'click_on_telegram',
                    element_position: 'blog coefficients page',
                  });
                }
              }}
            >
              {isMobile ? <TelegramButtonLinkMobile /> : <TelegramButtonLink />}
            </Link>
          </div>

          <div className={styles.tableContainer}>
            {tableData.map((branch) => (
              <div id={branch.branchCode} key={branch.id}>
                <CoefficientsTable {...branch} />
              </div>
            ))}
          </div>
          <br />
          {/* Table */}

          <div className={styles.textContainer}>
            <div id='howToCalc'>
              <RulesOfCalc />
            </div>
            <br />

            {/* HowToUse */}
            <h2 style={{ marginBottom: 16 }} id='howToUse' className={styles.subtitle}>
              {t('coefficients.how_to_use.title')}
            </h2>
            <div className={styles.adviceItem}>
              <div className={styles.checkIcon}>✓</div>
              <p>{t('coefficients.how_to_use.check_specialties')}</p>
            </div>
            <div className={styles.adviceItem}>
              <div className={styles.checkIcon}>✓</div>
              <p>{t('coefficients.how_to_use.fourth_subject')}</p>
            </div>
            <div className={styles.adviceItem}>
              <div className={styles.checkIcon}>✓</div>
              <div className={styles.mon}>
                <Link to={langPath('/nmt-calculator')} onClick={() => window.scrollTo(0, 0)}>
                  {t('coefficients.how_to_use.calculator')}
                </Link>{' '}
                {t('coefficients.how_to_use.calculator_description')}
                <p>{t('coefficients.how_to_use.compare')}</p>
                <p>{t('coefficients.how_to_use.example1')}</p>
                <p>{t('coefficients.how_to_use.example2')}</p>
                <p>{t('coefficients.how_to_use.example3')}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CoefficientsPage;
