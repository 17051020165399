import { Box } from '@mui/material';
import { ChangeEvent, useMemo, useState } from 'react';

import { useToggle } from '../../../../hooks/utils/useToggle';
import { useUniversitiesSearch } from '../../hooks/useUniversitiesSearch';
import { useUniversityFilters } from '../../hooks/useUniversityFilters';
import { ArrowIcon, ArrowIconDirection } from '../../icons/ArrowIcon';
import { CodeChip } from '../CodeChip/CodeChip';
import FiltersBlock from '../FilterBlock/FilterBlock';
import { FilterItem } from '../FilterCard/FilterCard';
import { FilterCheckbox } from '../FilterCheckbox/FilterCheckbox';
import { Search } from '../Search/Search';
import { SubTitle } from '../SubTitle/SubTitle';

import styles from './UniversityFilterCard.module.scss';

const DEFAULT_ITEMS_TO_SHOW = 5;
const SHOW_MORE_PAGE = 15;

type UniversityFilterCardProps = {
  title: string;
  itemsToShow?: number;
  data?: ReturnType<typeof useUniversityFilters>['data'];
  selectedFilters: number[];
  onSelect: (id: number | string) => void;
};

export const UniversityFilterCard = (props: UniversityFilterCardProps) => {
  const [opened, toggle] = useToggle(true);
  const { searchValue, search, filteredUniversities } = useUniversitiesSearch(props.data);

  return (
    <FiltersBlock>
      <div className={styles.wrapBlock}>
        <SubTitle onClick={toggle}>
          <CodeChip className={styles.filterCount} size='big' code={props.selectedFilters.length} padding={false} />
          {props.title}
        </SubTitle>
        <ArrowIcon
          direction={opened ? ArrowIconDirection.UP : ArrowIconDirection.DOWN}
          color={opened ? '#F59D33' : '#3C474E'}
          onClick={toggle}
        />
      </div>
      {opened && (
        <>
          <Search
            placeholder='Назва закладу або код'
            value={searchValue}
            onChange={(e: ChangeEvent<HTMLInputElement>) => search(e.target.value)}
          />
          <Filters {...props} data={filteredUniversities} />
        </>
      )}
    </FiltersBlock>
  );
};

type FiltersProps = {
  itemsToShow?: number;
  data?: FilterItem[];
  onSelect: (id: number | string) => void;
};

const Filters = (props: FiltersProps) => {
  const [showMoreClicks, setShowMoreClicks] = useState(0);
  const itemsToShow = props.itemsToShow || DEFAULT_ITEMS_TO_SHOW;

  const sortedData = useMemo(() => {
    return [...(props.data || [])].sort((a, b) => {
      if (a.isChecked && !b.isChecked) return -1;
      if (!a.isChecked && b.isChecked) return 1;
      return 0;
    });
  }, [props.data]);

  const filtersDataToShow = useMemo(() => {
    const totalItemsToShow = Math.min(itemsToShow + showMoreClicks * SHOW_MORE_PAGE, sortedData?.length ?? 0);
    return sortedData?.slice(0, totalItemsToShow);
  }, [showMoreClicks, sortedData, itemsToShow]);

  const countOfLeftFilters = useMemo(
    () => (sortedData?.length ?? 0) - (filtersDataToShow?.length ?? 0),
    [filtersDataToShow, sortedData],
  );

  return (
    <div>
      {filtersDataToShow?.map((item) => (
        <FilterCheckbox
          key={item.id}
          item={item}
          choice='multiple'
          size='big'
          onSelect={props.onSelect}
          code={item.id.toString().padStart(3, '0')}
        />
      ))}
      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
        {countOfLeftFilters > 0 && (
          <p className={styles.showMore} onClick={() => setShowMoreClicks((prev) => prev + 1)}>
            Показати ще {Math.min(countOfLeftFilters, SHOW_MORE_PAGE)}
          </p>
        )}
        {showMoreClicks > 0 && (
          <p className={styles.showMore} onClick={() => setShowMoreClicks(0)}>
            Згорнути фільтри
          </p>
        )}
      </Box>
    </div>
  );
};
