import { DetailedOffer } from '../../../types/offer';

export function groupSubjects(subjects: DetailedOffer['concursSubjects']): DetailedOffer['concursSubjects'][] {
  const uniqueSubjects = new Map();

  subjects.forEach((subject) => {
    uniqueSubjects.set(subject.name, subject);
  });

  const deduplicatedSubjects = Array.from(uniqueSubjects.values());

  return deduplicatedSubjects.map((subject) => [subject]);
}
