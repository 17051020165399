export const BRANCHES = [
  { code: 'A', name: 'Освіта' },
  { code: 'B', name: 'Культура, мистецтво та гуманітарні науки' },
  { code: 'C', name: 'Соціальні науки, журналістика та інформація' },
  { code: 'D', name: 'Бізнес, адміністрування та право' },
  { code: 'E', name: 'Природничі науки, математика та статистика' },
  { code: 'F', name: 'Інформаційні технології' },
  { code: 'G', name: 'Інженерія, виробництво та будівництво' },
  { code: 'H', name: 'Сільське, лісове, рибне господарство та ветеринарна медицина' },
  { code: 'I', name: "Охорона здоров'я та соціальне забезпечення" },
  { code: 'J', name: 'Транспорт та послуги' },
  { code: 'K', name: 'Безпека та оборона' },
];
