import { createContext, ReactNode, useContext, useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';

import { Pagination, SortType } from '../../../api/offers';
import { useOffers } from '../hooks/useOffers';
import { SubjectOffer, UniversityOffer } from '../types/offer';

export const UNIVERSITY_STORAGE_OFFERS_KEY = 'universityStorageOffers';
type OffersContextProps = {
  offers: SubjectOffer[] | UniversityOffer[];
  offerPagination: Pagination | undefined;
  sorting:
    | {
        sort: SortType;
        order: 'ASC' | 'DESC';
      }
    | undefined;
  fetchOffers: (options?: { page?: number; limit?: number }) => void;
  resetOffers: () => void;
  updateSorting: (sorting: SortType, order: 'ASC' | 'DESC') => void;
} | null;

const OffersContext = createContext<OffersContextProps>(null);

interface Props {
  children: ReactNode;
}

const OffersContextProvider = ({ children }: Props) => {
  const [searchParams] = useSearchParams();

  const { offers, offerPagination, sorting, fetchOffers, resetOffers, updateSorting } = useOffers();

  const value = useMemo(
    () => ({
      offers,
      offerPagination,
      searchParams,
      sorting,
      fetchOffers,
      resetOffers,
      updateSorting,
    }),
    [offers, offerPagination, searchParams, sorting, fetchOffers, resetOffers, updateSorting],
  );

  return <OffersContext.Provider value={value}>{children}</OffersContext.Provider>;
};

export const useOffersContext = (): NonNullable<OffersContextProps> => {
  const context = useContext(OffersContext);

  if (context === null || context === undefined) {
    throw new Error('useOffersContext was used outside of its Provider');
  }

  return context;
};

export { OffersContext, OffersContextProvider };
