import { useQuery } from '@tanstack/react-query';
import { useMemo } from 'react';

import { getSpecialities } from '../../api/filters';
export const useSpecialities = () => {
  const { data, isLoading, error } = useQuery({
    queryKey: ['specialities'],
    queryFn: () => getSpecialities(),
  });

  return { data, isLoading, error };
};

export const useSpecialityOptions = () => {
  const { data = [], isLoading, error } = useSpecialities();

  const specialityOptions = useMemo(() => {
    return data
      ?.flatMap((group) => group.branchRelatedSpecialities)
      .sort((a, b) => Number(a.code) - Number(b.code))
      .map((filter) => ({
        id: filter.id,
        label: `${filter.code} ${filter.name}`,
      }));
  }, [data]);

  return { specialityOptions, isLoading, error };
};
