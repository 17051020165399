import { zodResolver } from '@hookform/resolvers/zod';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import Button from '../../../../../components/ButtonV2/Button';
import { SpecialitiesInput } from '../../../../../components/mui-form/SpecialitiesInput';
import Label from '../../../components/Label';
import Section from '../../../components/Section';
import {
  ApplicantSchema,
  ApplicantSpecialitiesSchema,
  applicantSpecialitiesSchema,
} from '../../../schema/applicant-form';
import { useAuthForm } from '../../../state/AuthFormContext';

export default function SpecialitiesForm() {
  const { t } = useTranslation();
  const authStore = useAuthForm();

  const form = useForm<ApplicantSpecialitiesSchema>({
    resolver: zodResolver(applicantSpecialitiesSchema),
    defaultValues: (authStore.formData?.data as ApplicantSchema).specialitiesData || {
      specialities: [],
    },
  });

  const onSubmit = (data: ApplicantSpecialitiesSchema) => {
    authStore.appendFormData({
      type: 'APPLICANT',
      data: {
        ...authStore.formData?.data,
        specialitiesData: data,
      },
    });
    authStore.nextStep();
  };

  return (
    <FormProvider {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)}>
        <Section>
          <Label>{t('auth.registration-flow.specialities.applicant-label')}</Label>
          <SpecialitiesInput name='specialities' />
        </Section>

        <Button type='submit' variant='outline'>
          {t('auth.registration-flow.buttons.skip')}
        </Button>
        <Button type='submit'>{t('auth.registration-flow.buttons.next')}</Button>
      </form>
    </FormProvider>
  );
}
