import { FC } from 'react';
type SortIconProps = React.HTMLAttributes<SVGElement> & {
  width?: string;
  height?: string;
};

export const SortIcon: FC<SortIconProps> = ({ width = 18, height = 18, ...props }) => (
  <svg width={width} height={height} fill='none' {...props} viewBox='0 0 18 18' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M10.0811 4.97049H16.5M10.0811 8.58157H14.666M10.0811 12.1927H12.8321M4.35423 4.5V13.5M4.35423 13.5L1.5 10.7488M4.35423 13.5L7.31745 10.7488'
      stroke='#131214'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);
