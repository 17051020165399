import { Button } from '@mui/material';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { PartialSubjects, SpecialityWithCoefficients } from '../../../../api/types/specialities';

import { AdditionalGradesInputs } from './components/AdditionalGradesInputs/AdditionalGradesInputs';
import { MandatorySubjects } from './components/MandatorySubjects/MandatorySubjects';
import { OptionalSubjectInputs } from './components/OptionalSubjectInputs/OptionalSubjectInputs';
import { SpecialityDropdown } from './components/SpecialityDropdown/SpecialityDropdown';
import styles from './SubjectInputs.module.scss';

interface Props {
  speciality: SpecialityWithCoefficients | null;
  setSpecialityId: (speciality: number | null) => void;
  setGrades: (grades: PartialSubjects | null) => void;
  courseGrade: number | '';
  setCourseGrade: (value: number | '') => void;
}
export function SubjectInputs({ speciality, setSpecialityId, setGrades, courseGrade, setCourseGrade }: Props) {
  const [math, setMath] = useState<number | ''>(200);
  const [ukrainian, setUkrainian] = useState<number | ''>(200);
  const [history, setHistory] = useState<number | ''>(200);
  const [creativeCompetitionGrade, setCreativeCompetitionGrade] = useState<number | ''>('');
  const [canCalculate, setCanCalculate] = useState<boolean>(false);
  const [optionalGrades, setOptionalGrades] = useState<PartialSubjects>({
    biology: undefined,
    chemistry: undefined,
    physics: undefined,
    geography: undefined,
    ukrainianLiterature: undefined,
    foreignLanguage: undefined,
  });
  const { t } = useTranslation();

  useEffect(() => {
    setCanCalculate(
      !!speciality &&
        !!math &&
        !!ukrainian &&
        !!history &&
        (speciality.coefficients.creativeCompetition ? !!creativeCompetitionGrade : true) &&
        Object.values(optionalGrades).some(Number),
    );
  }, [speciality, math, ukrainian, history, creativeCompetitionGrade, optionalGrades]);

  return (
    <div className={styles.wrapper}>
      <SpecialityDropdown setSpecialityId={setSpecialityId} />
      <div className={styles.wrapperInputs}>
        <MandatorySubjects
          speciality={speciality}
          history={history}
          setHistory={setHistory}
          setMath={setMath}
          setUkrainian={setUkrainian}
          math={math}
          ukrainian={ukrainian}
        />
        <OptionalSubjectInputs speciality={speciality} setOptionalGrades={setOptionalGrades} />
        <AdditionalGradesInputs
          speciality={speciality}
          setSpecialityId={setSpecialityId}
          creativeCompetitionGrade={creativeCompetitionGrade}
          setCreativeCompetitionGrade={setCreativeCompetitionGrade}
          setCourseGrade={setCourseGrade}
          courseGrade={courseGrade}
        />
      </div>
      <div className={styles.calcButton}>
        <Button
          disabled={!canCalculate}
          onClick={() => {
            const gradesData = {
              mathematics: +math,
              ukrainian: +ukrainian,
              history: +history,
              creativeCompetition: creativeCompetitionGrade ? +creativeCompetitionGrade : undefined,
              ...optionalGrades,
            };

            setGrades(gradesData);

            if (!(window.location.hostname === 'localhost')) {
              const analyticsGrades = Object.fromEntries(
                Object.entries(gradesData).filter(([, value]) => value !== undefined),
              );

              window.dataLayer = window.dataLayer || [];
              window.dataLayer.push({
                event: 'nmt calculator click',
                speciality: speciality?.name,
                specialityCode: speciality?.code,
                courseGrade: courseGrade !== '' ? courseGrade : undefined,
                grades: analyticsGrades,
              });
            }
          }}
        >
          {t('calculator.button')}
        </Button>
      </div>
    </div>
  );
}
