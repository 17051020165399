import { Typography, TypographyProps } from '@mui/material';
import { Link as RouterLink, LinkProps as RouterLinkProps } from 'react-router-dom';

import { langPath } from '../../utils/langPath';
import './Link.scss';

type LinkProps = Omit<RouterLinkProps, 'className' | 'to'> & {
  variant?: 'text' | 'button' | 'button-outline';
  size?: 'default' | 'slim';
  text?: TypographyProps['variant'];
  icon?: string;
  className?: string;
  to: string | object;
  skipLanguagePrefix?: boolean;
};

const styles = {
  text: 'Link__text',
  button: 'Link__button',
  'button-outline': 'Link__button-outline',
};

const sizeStyles = {
  default: 'Link__default-size',
  slim: 'Link__slim-size',
};

export const Link = ({
  children,
  variant = 'text',
  size = 'default',
  text = 'body2',
  icon,
  className,
  to,
  skipLanguagePrefix = false,
  ...rest
}: LinkProps) => {
  return (
    <RouterLink
      {...rest}
      to={skipLanguagePrefix ? to : langPath(to)}
      className={`${styles[variant]} ${sizeStyles[size]} ${className} Link`}
    >
      {icon && <img className='icon' src={icon} alt={children?.toString() ?? `${children}-icon`} />}
      <Typography component='span' variant={text}>
        {children}
      </Typography>
    </RouterLink>
  );
};
