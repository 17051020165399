import { useMemo, useState } from 'react';

const SHOW_MORE_PAGE = 15;
const DEFAULT_ITEMS_TO_SHOW = 5;

export const useShowMore = <T>(data: T[], start?: number, step?: number) => {
  const [showMoreClicks, setShowMoreClicks] = useState(0);
  const _start = start || DEFAULT_ITEMS_TO_SHOW;
  const _step = step || SHOW_MORE_PAGE;

  const totalItemsToShow = useMemo(
    () => Math.min(_start + showMoreClicks * _step, data?.length ?? 0),
    [data, showMoreClicks],
  );

  const dataToShow = useMemo(() => data?.slice(0, totalItemsToShow), [data, totalItemsToShow]);

  const countOfLeftFilters = useMemo(() => (data?.length ?? 0) - (totalItemsToShow ?? 0), [data, totalItemsToShow]);

  return { data: dataToShow, clicks: showMoreClicks, setClicks: setShowMoreClicks, countOfLeftFilters, step: _step };
};
