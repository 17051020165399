import { zodResolver } from '@hookform/resolvers/zod';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import Button from '../../../../../components/ButtonV2/Button';
import FormTextField from '../../../../../components/mui-form/FormTextField';
import Label from '../../../components/Label';
import Section from '../../../components/Section';
import ValidationError from '../../../components/ValidationError';
import { otherUserFormSchema, OtherUserFormSchema, OtherUserSchema } from '../../../schema/other-user-form';
import { SetupSchema } from '../../../schema/setup-form';
import { useAuthForm } from '../../../state/AuthFormContext';

export default function OtherUserForm() {
  const authStore = useAuthForm();
  const { t } = useTranslation();
  const form = useForm<OtherUserFormSchema>({
    resolver: zodResolver(otherUserFormSchema),
    defaultValues: (authStore.formData?.data as OtherUserSchema).otherData || {},
  });

  const onSubmit = (data: OtherUserFormSchema) => {
    const setupData = authStore.formData?.data as SetupSchema;

    authStore.appendFormData({
      type: 'OTHER',
      data: {
        setupData,
        otherData: data,
      },
    });

    authStore.nextStep();
  };

  return (
    <FormProvider {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)}>
        <Section>
          <Label required>{t('auth.registration-flow.preferences.other-user.label')}</Label>
          <FormTextField
            name='about'
            label={t('auth.registration-flow.preferences.other-user.label')}
            placeholder={t('auth.registration-flow.preferences.other-user.placeholder')}
          />
          <ValidationError error={form.formState.errors.about} />
        </Section>
        <Button disabled={!form.formState.isValid} type='submit'>
          {t('auth.registration-flow.buttons.complete')}
        </Button>
      </form>
    </FormProvider>
  );
}
