import React, { useEffect, useState } from 'react';

import { parseSearchParams } from '../../utils/parseSearchParams';
import styles from './BookmarkButton.module.scss';

export function BookmarkButton({ id }: { id: number }) {
  const [active, setActive] = useState(false);

  useEffect(() => {
    const savedOffers = JSON.parse(localStorage.getItem('savedOffers') ?? '[]');
    setActive(savedOffers.includes(id));
  }, []);

  const saveOffer = (e: React.MouseEvent<HTMLButtonElement>) => {
    const savedOffers = JSON.parse(localStorage.getItem('savedOffers') ?? '[]');
    if (active) {
      localStorage.setItem('savedOffers', JSON.stringify(savedOffers.filter((savedId: number) => savedId !== id)));
    } else {
      localStorage.setItem('savedOffers', JSON.stringify([...savedOffers, id]));
    }

    if (!(window.location.hostname === 'localhost')) {
      const { specialities, regions } = parseSearchParams();
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        event: 'add_to_wish_list',
        filter_discipline_code: specialities.join(','),
        filter_geo_code: regions.join(','),
        offer_id: id,
      });
    }

    setActive(!active);
    e.stopPropagation();
  };

  return (
    <button className={styles.bookmarkButton} onClick={saveOffer}>
      {active ? (
        <svg width='12' height='16' viewBox='0 0 12 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
          <path
            d='M10.5 0H1.5C0.671563 0 0 0.682071 0 1.52347V15.6609C0 15.9222 0.279071 16.085 0.50129 15.9534L5.83204 12.7951C5.93583 12.7336 6.06417 12.7336 6.16796 12.7951L11.4987 15.9534C11.7209 16.085 12 15.9222 12 15.6609V1.52347C12 0.682071 11.3284 0 10.5 0Z'
            fill='#F59D33'
          />
        </svg>
      ) : (
        <svg width='12' height='16' viewBox='0 0 12 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
          <path
            d='M10.5 0H1.5C0.671563 0 0 0.682071 0 1.52347V15.6609C0 15.9222 0.279071 16.085 0.50129 15.9534L5.83204 12.7951C5.93583 12.7336 6.06417 12.7336 6.16796 12.7951L11.4987 15.9534C11.7209 16.085 12 15.9222 12 15.6609V1.52347C12 0.682071 11.3284 0 10.5 0ZM10.5 13.0085C10.5 13.2698 10.2209 13.4326 9.99871 13.3009L6.16796 11.0314C6.06417 10.9699 5.93583 10.9699 5.83204 11.0314L2.00129 13.3009C1.77907 13.4326 1.5 13.2698 1.5 13.0085V1.71391C1.5 1.6634 1.51975 1.61496 1.55492 1.57925C1.59008 1.54354 1.63777 1.52347 1.6875 1.52347H10.3125C10.4161 1.52347 10.5 1.60863 10.5 1.71378V13.0085Z'
            fill='#F59D33'
          />
        </svg>
      )}
    </button>
  );
}
