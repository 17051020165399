import { Link } from 'react-router-dom';

import { langPath } from '../../../utils/langPath';

import styles from './../auth.module.scss';

export default function CrossButton({ className }: { className?: string }) {
  return (
    <Link to={langPath('/')} className={`${styles.crossButton} ${className}`}>
      <img src='/images/auth/cross.svg' alt='Cross button' />
    </Link>
  );
}
