import { FormControl, InputLabel, MenuItem, Select, SelectProps } from '@mui/material';
import { Controller, FieldValues, Path, PathValue, useFormContext } from 'react-hook-form';

export type SelectOption = {
  value: string | number;
  label: string;
};

type FormSelectProps<T extends FieldValues> = {
  name: Path<T>;
  label: string;
  options: SelectOption[];
  defaultValue?: PathValue<T, Path<T>>;
} & Omit<SelectProps, 'name'>;

const FormSelect = <T extends FieldValues>({
  name,
  label,
  options,
  defaultValue = '' as PathValue<T, Path<T>>,
  ...rest
}: FormSelectProps<T>) => {
  const { control } = useFormContext<T>();

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      render={({ field: { value, ...fieldProps } }) => (
        <FormControl fullWidth>
          <InputLabel id={`${name}-label`}>{label}</InputLabel>
          <Select
            {...fieldProps}
            value={value ?? defaultValue}
            labelId={`${name}-label`}
            label={label}
            variant='outlined'
            {...rest}
          >
            {options.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )}
    />
  );
};

export default FormSelect;
