import { z } from 'zod';

export const userTypeSchema = z.enum(['APPLICANT', 'APPLICANT_MEMBER', 'STUDENT', 'TEACHER', 'OTHER']);

export const baseRegistrationSchema = z.object({
  name: z.string().optional(),
  userType: userTypeSchema,
});

export const applicantRegistrationSchema = baseRegistrationSchema.extend({
  userType: z.literal('APPLICANT'),
  enrollmentYear: z.number().optional(),
  country: z.string().optional(),
  city: z.string().optional(),
  aboutMe: z.string().optional(),
  aboutMeDetailed: z.string().optional(),
  expectations: z.string().optional(),
  expectationsDetailed: z.string().optional(),
  selectedRegions: z.array(z.number()).optional(),
  interestedSpecialities: z.array(z.number()).optional(),
  selectedUniversities: z.array(z.number()).optional(),
});

export const memberRegistrationSchema = baseRegistrationSchema.extend({
  userType: z.literal('APPLICANT_MEMBER'),
  enrollmentYear: z.number().optional(),
  country: z.string().optional(),
  city: z.string().optional(),
  selectedRegions: z.array(z.number()).optional(),
  interestedSpecialities: z.array(z.number()).optional(),
  selectedUniversities: z.array(z.number()).optional(),
});

export const studentRegistrationSchema = baseRegistrationSchema.extend({
  userType: z.literal('STUDENT'),
  enrollmentYear: z.number().optional(),
  universityId: z.number().optional(),
  facultyId: z.number().optional(),
  specialityId: z.number().optional(),
});

export const teacherRegistrationSchema = baseRegistrationSchema.extend({
  userType: z.literal('TEACHER'),
  universities: z.array(z.number()).optional(),
  subjects: z.array(z.string()).optional(),
});

export const otherRegistrationSchema = baseRegistrationSchema.extend({
  userType: z.literal('OTHER'),
  aboutMe: z.string().optional(),
});

export const registrationSchema = z.discriminatedUnion('userType', [
  applicantRegistrationSchema,
  memberRegistrationSchema,
  studentRegistrationSchema,
  teacherRegistrationSchema,
  otherRegistrationSchema,
]);

export const registrationResponseSchema = z.object({
  access_token: z.string(),
});

export type UserType = z.infer<typeof userTypeSchema>;
export type ApplicantRegistration = z.infer<typeof applicantRegistrationSchema>;
export type MemberRegistration = z.infer<typeof memberRegistrationSchema>;
export type StudentRegistration = z.infer<typeof studentRegistrationSchema>;
export type TeacherRegistration = z.infer<typeof teacherRegistrationSchema>;
export type OtherRegistration = z.infer<typeof otherRegistrationSchema>;
export type RegistrationDto = z.infer<typeof registrationSchema>;
export type RegistrationResponse = z.infer<typeof registrationResponseSchema>;
