export type YearOptions = {
  startYear: number;
  endYear: number;
};

export const useYearOptions = (options?: YearOptions) => {
  const startYear = options?.startYear || new Date().getFullYear();
  const endYear = options?.endYear || startYear + 5;
  const yearOptions = Array.from({ length: endYear - startYear }, (_, i) => startYear + i).map((x) => ({
    label: x.toString(),
    value: x,
  }));

  return { yearOptions };
};
