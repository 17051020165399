import { useTranslation } from 'react-i18next';

import { PartialSubjects, SpecialityWithCoefficients } from '../../../../api/types/specialities';

import { CalcDescription } from './components/CalcDescription';
import { GradesGraph } from './components/GradesGraph';
import styles from './GradesGraphsWrapper.module.scss';
interface Props {
  speciality: SpecialityWithCoefficients | null;
  grades: PartialSubjects | null;
  courseGrade: number | '';
}

export function GradesGraphsWrapper({ speciality, grades, courseGrade }: Props) {
  const { t } = useTranslation();

  return (
    <div>
      <h2 className={styles.h3}>{t('calculator.graph.title')}</h2>
      <div className={styles.gradesGraphWrapper}>
        <CalcDescription speciality={speciality} />
        <GradesGraph courseGrade={courseGrade} speciality={speciality} grades={grades} oldFormula={false} />
      </div>
    </div>
  );
}
