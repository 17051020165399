import { OffersByUniversitiesResponse } from '../../../../api/offers';
import { OfferCardData } from '../types/OfferCard';

export const transformToOfferCards = (data: OffersByUniversitiesResponse['data']): OfferCardData[] => {
  const allOffers: OfferCardData[] = [];

  data[0].faculties.forEach((faculty) => {
    faculty.specialities.forEach((specialty) => {
      specialty.offers.forEach((offer) => {
        allOffers.push({
          offerId: offer.id,
          universityName: data[0].universityName,
          facultyName: faculty.facultyName,
          specialtyName: specialty.specialityName,
          specialtyCode: specialty.specialityCode,
          minBudgetScore: offer.minBudgetScore,
          maxBudgetLicence: offer.maxBudgetLicence,
          maxLicense: offer.maxLicense,
          minContractScore: offer.minContractScore,
          price: offer.price,
          totalApplies: offer.totalApplies,
          universityId: +data[0].universityId,
        });
      });
    });
  });

  return allOffers;
};
