import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { SpecialityWithCoefficients } from '../../../../api/types/specialities';

const cellStyles = {
  borderRight: '1px solid #ccc',
  borderBottom: '1px solid #ccc',
  padding: '8px',
};

const headerCellStyles = {
  ...cellStyles,
  fontWeight: 'bold',
  backgroundColor: '#FDF0E0',
};

const groupHeaderCellStyles = {
  ...headerCellStyles,
  position: 'sticky',
  top: 0,
  zIndex: 3,
};

const subjectHeaderCellStyles = {
  ...headerCellStyles,
  position: 'sticky',
  top: '40px',
  zIndex: 2,
};

interface CoefficientsTableProps {
  id: 0;
  branchCode: string;
  branchName: string;
  relatedSpecialities: SpecialityWithCoefficients[];
}

export const CoefficientsTable: FC<CoefficientsTableProps> = ({ branchCode, branchName, relatedSpecialities }) => {
  const { t } = useTranslation();

  return (
    <>
      <h3 style={{ maxWidth: 732, margin: '24px auto 16px' }}>
        {branchCode} «{branchName}»
      </h3>
      <Paper sx={{ width: '100%', overflow: 'hidden' }}>
        <Typography
          variant='body2'
          align='center'
          sx={{
            padding: '12px',
            borderBottom: '1px solid #ccc',
            backgroundColor: '#FDF0E0',
            fontWeight: 'bold',
            position: 'sticky',
            top: 0,
            zIndex: 4,
          }}
        >
          {t('coefficients_table.competition_subjects')}
        </Typography>
        <TableContainer sx={{ maxHeight: 910 }}>
          <Table stickyHeader aria-label='sticky table'>
            <TableHead>
              <TableRow>
                <TableCell
                  rowSpan={2}
                  align='center'
                  sx={{
                    ...groupHeaderCellStyles,
                    verticalAlign: 'middle',
                    width: '50px',
                  }}
                >
                  {t('coefficients_table.branch_code_title')}
                </TableCell>
                <TableCell
                  rowSpan={2}
                  align='center'
                  sx={{
                    ...groupHeaderCellStyles,
                    verticalAlign: 'middle',
                    minWidth: '200px',
                  }}
                >
                  {t('coefficients_table.speciality_name')}
                </TableCell>
                <TableCell
                  colSpan={4}
                  align='center'
                  sx={{
                    ...groupHeaderCellStyles,
                  }}
                >
                  {t('coefficients_table.main_block')}
                </TableCell>
                <TableCell
                  colSpan={5}
                  align='center'
                  sx={{
                    ...groupHeaderCellStyles,
                  }}
                >
                  {t('coefficients_table.elective_subject')}
                </TableCell>
                <TableCell
                  rowSpan={2}
                  align='center'
                  sx={{
                    ...groupHeaderCellStyles,
                    borderRight: 'none',
                    verticalAlign: 'middle',
                    width: '100px',
                  }}
                >
                  {t('coefficients_table.creative_competition')}
                </TableCell>
              </TableRow>

              {/* Second header row */}
              <TableRow>
                <TableCell
                  align='center'
                  sx={{
                    ...subjectHeaderCellStyles,
                    width: '120px',
                  }}
                >
                  {t('coefficients_table.ukrainian')}
                </TableCell>
                <TableCell
                  align='center'
                  sx={{
                    ...subjectHeaderCellStyles,
                    width: '120px',
                  }}
                >
                  {t('coefficients_table.mathematics')}
                </TableCell>
                <TableCell
                  align='center'
                  sx={{
                    ...subjectHeaderCellStyles,
                    width: '120px',
                  }}
                >
                  {t('coefficients_table.history')}
                </TableCell>
                <TableCell
                  align='center'
                  sx={{
                    ...subjectHeaderCellStyles,
                    width: '120px',
                  }}
                >
                  {t('coefficients_table.foreign_language')}
                </TableCell>
                <TableCell
                  align='center'
                  sx={{
                    ...subjectHeaderCellStyles,
                    width: '100px',
                  }}
                >
                  {t('coefficients_table.biology')}
                </TableCell>
                <TableCell
                  align='center'
                  sx={{
                    ...subjectHeaderCellStyles,
                    width: '100px',
                  }}
                >
                  {t('coefficients_table.physics')}
                </TableCell>
                <TableCell
                  align='center'
                  sx={{
                    ...subjectHeaderCellStyles,
                    width: '100px',
                  }}
                >
                  {t('coefficients_table.chemistry')}
                </TableCell>
                <TableCell
                  align='center'
                  sx={{
                    ...subjectHeaderCellStyles,
                    width: '140px',
                  }}
                >
                  {t('coefficients_table.ukrainian_literature')}
                </TableCell>
                <TableCell
                  align='center'
                  sx={{
                    ...subjectHeaderCellStyles,
                    width: '120px',
                  }}
                >
                  {t('coefficients_table.geography')}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {relatedSpecialities.map(({ code, coefficients, id, name }) => (
                <TableRow key={id} hover>
                  <TableCell align='center' sx={cellStyles}>
                    {code}
                  </TableCell>
                  <TableCell sx={cellStyles}>{name}</TableCell>
                  <TableCell align='center' sx={cellStyles}>
                    {coefficients.ukrainian ?? '-'}
                  </TableCell>
                  <TableCell align='center' sx={cellStyles}>
                    {coefficients.mathematics ?? '-'}
                  </TableCell>
                  <TableCell align='center' sx={cellStyles}>
                    {coefficients.history ?? '-'}
                  </TableCell>
                  <TableCell align='center' sx={cellStyles}>
                    {coefficients.foreignLanguage ?? '-'}
                  </TableCell>
                  <TableCell align='center' sx={cellStyles}>
                    {coefficients.biology ?? '-'}
                  </TableCell>
                  <TableCell align='center' sx={cellStyles}>
                    {coefficients.physics ?? '-'}
                  </TableCell>
                  <TableCell align='center' sx={cellStyles}>
                    {coefficients.chemistry ?? '-'}
                  </TableCell>
                  <TableCell align='center' sx={cellStyles}>
                    {coefficients.ukrainianLiterature ?? '-'}
                  </TableCell>
                  <TableCell align='center' sx={cellStyles}>
                    {coefficients.geography ?? '-'}
                  </TableCell>
                  <TableCell align='center' sx={{ ...cellStyles, borderRight: 'none' }}>
                    {coefficients.creativeCompetition ?? '-'}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </>
  );
};
