import { OptionalSubject, SpecialityWithCoefficients } from '../../../api/types/specialities';
import { CalculatedGradeData } from '../types/calculated-grade';

/**
 * Returns data needed to display calculated grade information with comparisons
 */
export function getCalculatedGradeData(
  speciality: SpecialityWithCoefficients,
  ukrainian: number,
  mathematics: number,
  history: number,
  optionalSubjects: Array<{ subject: string; grade: number }>,
  creativeCompetitionGrade?: number,
  courseGrade = 0,
): CalculatedGradeData & {
  isProfileSubjectChosen: boolean;
  profileSubjects: OptionalSubject[];
  secondBestSubject: { subject: OptionalSubject; grade: number };
  equivalentSecondBestGrade: number;
  bestProfileSubject: {
    subject: OptionalSubject;
    grade: number;
  };
  areAllCoefsEqual: boolean; // Flag indicating if all optional subject coefficients are the same
} {
  // Calculate the final grade for a specific subject
  const calculateGradeWithSubject = (subject: OptionalSubject, subjectGrade: number) => {
    const subjectCoef = speciality.coefficients[subject] || 0;

    // Determine max coefficient as in the original formula
    const maxCoef = Object.entries(speciality.coefficients).reduce((max, [key, coef]) => {
      return key === 'ukrainian' || key === 'mathematics' || key === 'history' || key === 'creativeCompetition'
        ? max
        : Math.max(max, coef || 0);
    }, 0);

    // Calculate k according to the formula
    const k =
      speciality.coefficients.mathematics +
      speciality.coefficients.ukrainian +
      speciality.coefficients.history +
      (speciality.coefficients.creativeCompetition ?? 0) +
      (maxCoef + subjectCoef) / 2;

    // Calculate base grade from mandatory subjects
    let baseGrade =
      speciality.coefficients.ukrainian * ukrainian +
      speciality.coefficients.mathematics * mathematics +
      speciality.coefficients.history * history;

    // Add creative competition grade if applicable
    if (speciality.coefficients.creativeCompetition && creativeCompetitionGrade !== undefined) {
      baseGrade += speciality.coefficients.creativeCompetition * creativeCompetitionGrade;
    }

    // Calculate final grade using the provided formula
    const finalGrade = (baseGrade + subjectCoef * subjectGrade) / k + courseGrade;

    return Math.round(finalGrade * 10) / 10; // Round to 1 decimal place for display
  };

  // Calculate grades for each provided optional subject
  const subjectResults = optionalSubjects.map((optSubject) => ({
    subject: optSubject.subject as OptionalSubject,
    grade: calculateGradeWithSubject(optSubject.subject as OptionalSubject, optSubject.grade),
  }));

  // Find the subject that gives the highest grade
  const bestSubject =
    subjectResults.length > 0
      ? subjectResults.reduce((best, current) => (current.grade > best.grade ? current : best), {
          subject: 'unknown' as OptionalSubject,
          grade: 0,
        })
      : { subject: 'unknown' as OptionalSubject, grade: 0 };

  // Find all subjects with the highest coefficient (profile subjects)
  const filteredCoefficients = Object.entries(speciality.coefficients).filter(
    ([key]) => key !== 'ukrainian' && key !== 'mathematics' && key !== 'history' && key !== 'creativeCompetition',
  );

  // If no optional subjects exist in coefficients, use a safe default
  if (filteredCoefficients.length === 0) {
    return {
      subjectResults,
      bestSubject,
      profileSubject: 'unknown' as OptionalSubject,
      profileSubjects: [],
      profileSubjectCoef: 0,
      equivalentProfileGrade: 0,
      isProfileSubjectChosen: false,
      secondBestSubject: { subject: 'unknown' as OptionalSubject, grade: 0 },
      equivalentSecondBestGrade: 0,
      bestProfileSubject: { subject: 'unknown' as OptionalSubject, grade: 0 },
      areAllCoefsEqual: false,
    };
  }

  // Check if all optional subject coefficients are equal
  const areAllCoefsEqual =
    filteredCoefficients.length > 1 && filteredCoefficients.every(([, coef], _, arr) => coef === arr[0][1]);

  // Find the maximum coefficient among optional subjects
  const maxProfileCoef = filteredCoefficients.reduce((max, [, coef]) => Math.max(max, coef || 0), 0);

  // Find all subjects with this maximum coefficient
  const profileSubjects = filteredCoefficients
    .filter(([, coef]) => coef === maxProfileCoef)
    .map(([subject]) => subject as OptionalSubject);

  // Default profile subject
  let profileSubject: OptionalSubject = profileSubjects[0] || ('unknown' as OptionalSubject);
  let isProfileSubjectChosen = false;

  // Check if user has chosen any of the profile subjects
  for (const subject of profileSubjects) {
    const isChosen = optionalSubjects.some((opt) => opt.subject === subject);
    if (isChosen) {
      profileSubject = subject;
      isProfileSubjectChosen = true;
      break;
    }
  }

  // Find max coefficient for the formula - this should remain the same as before
  const maxCoef = Object.entries(speciality.coefficients).reduce((max, [key, coef]) => {
    return key === 'ukrainian' || key === 'mathematics' || key === 'history' || key === 'creativeCompetition'
      ? max
      : Math.max(max, coef || 0);
  }, 0);

  // Calculate k according to the formula
  const k =
    speciality.coefficients.mathematics +
    speciality.coefficients.ukrainian +
    speciality.coefficients.history +
    (maxCoef + maxProfileCoef) / 2;

  // Calculate base grade from mandatory subjects
  let baseGrade =
    speciality.coefficients.ukrainian * ukrainian +
    speciality.coefficients.mathematics * mathematics +
    speciality.coefficients.history * history;

  // Add creative competition grade if applicable
  if (speciality.coefficients.creativeCompetition && creativeCompetitionGrade !== undefined) {
    baseGrade += speciality.coefficients.creativeCompetition * creativeCompetitionGrade;
  }

  // Calculate what grade would be needed in profile subject to get the same final grade
  // Protect against division by zero
  let equivalentProfileGrade = 0;
  if (maxProfileCoef > 0) {
    equivalentProfileGrade = Math.round(((bestSubject.grade - courseGrade) * k - baseGrade) / maxProfileCoef);
  }

  // For the special case where the profile subject is already chosen
  // Find the second best subject and calculate what grade would be needed in it
  const secondBestSubject = { subject: 'unknown' as OptionalSubject, grade: 0 };
  let equivalentSecondBestGrade = 0;
  let bestProfileSubject = { subject: profileSubject, grade: 0 };

  if (isProfileSubjectChosen) {
    // Multiple profile subjects scenario handling - find the best performing one
    const chosenProfileSubjects = profileSubjects.filter((subject) =>
      optionalSubjects.some((opt) => opt.subject === subject),
    );

    if (chosenProfileSubjects.length > 0) {
      // Find which profile subject gives the best grade
      const profileSubjectResults = subjectResults.filter((result) => chosenProfileSubjects.includes(result.subject));

      if (profileSubjectResults.length > 0) {
        bestProfileSubject = profileSubjectResults.reduce(
          (best, current) => (current.grade > best.grade ? current : best),
          profileSubjectResults[0],
        );
      }
    }

    // If all coefficients are equal, just pick any other subject the user hasn't chosen
    if (areAllCoefsEqual) {
      const chosenSubjects = optionalSubjects.map((opt) => opt.subject);
      const otherSubject = filteredCoefficients.find(
        ([key]) => !chosenSubjects.includes(key) && key !== profileSubject,
      );

      if (otherSubject) {
        secondBestSubject.subject = otherSubject[0] as OptionalSubject;
        const secondBestSubjectCoef = otherSubject[1] || 0;

        if (secondBestSubjectCoef > 0) {
          equivalentSecondBestGrade = Math.round(
            ((bestProfileSubject.grade - courseGrade) * k - baseGrade) / secondBestSubjectCoef,
          );
        }
      }
    } else {
      // Find optional subjects excluding all profile subjects (not just the chosen one)
      const otherOptionalSubjects = filteredCoefficients
        .filter(([key]) => !profileSubjects.includes(key as OptionalSubject))
        .sort(([, coefA], [, coefB]) => (coefB || 0) - (coefA || 0));

      if (otherOptionalSubjects.length > 0) {
        // Take the subject with the highest coefficient among remaining subjects
        const secondBestSubjectEntry = otherOptionalSubjects[0];
        const secondBestSubjectCoef = secondBestSubjectEntry[1] || 0;

        // Set second best subject
        secondBestSubject.subject = secondBestSubjectEntry[0] as OptionalSubject;

        // Calculate what grade would be needed in the second best subject
        if (secondBestSubjectCoef > 0) {
          equivalentSecondBestGrade = Math.round(
            ((bestProfileSubject.grade - courseGrade) * k - baseGrade) / secondBestSubjectCoef,
          );
        }
      }
    }
  }

  return {
    // All calculated grades for each subject
    subjectResults,

    // The subject that gives the highest grade
    bestSubject,

    // The profile subject (with highest coefficient)
    profileSubject,

    // All profile subjects with the highest coefficient
    profileSubjects,

    // The coefficient of the profile subject
    profileSubjectCoef: maxProfileCoef,

    // What grade is needed in profile subject to achieve the same result
    equivalentProfileGrade,

    // Is the profile subject already in the user's chosen subjects?
    isProfileSubjectChosen,

    // Second best subject (when profile is already chosen)
    secondBestSubject,

    // What grade is needed in second best subject to achieve same result
    equivalentSecondBestGrade,

    // Best performing profile subject when multiple are chosen
    bestProfileSubject,

    // Indicates if all optional subject coefficients are equal
    areAllCoefsEqual,
  };
}
