import { Box } from '@mui/material';

import Button from '../../../../components/button';
import { useFilterButton } from '../../hooks/useFilterButton';

import LoadingSpinner from './LoadingSpinner';

export const ShowResultButton = () => {
  const { data, isLoading, enabled } = useFilterButton();

  if (isLoading) {
    return (
      <Button disabled={true} to='/offers/result'>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          Показати результати <LoadingSpinner />
        </Box>
      </Button>
    );
  }

  return (
    <Button disabled={data?.count === 0 || !enabled} to='/offers/result'>
      {!data?.count ? 'Показати результати' : `Показати результати (${data?.count})`}
    </Button>
  );
};
