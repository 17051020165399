import { SortType } from '../../../../api/offers';
import { SortIcon } from '../../icons/SortIcon';
import { useOffersContext } from '../../layouts/OffersContext';
import { parseSearchParams } from '../../utils/parseSearchParams';

import styles from './SortChip.module.scss';

type SortChipProps = {
  title: string;
  sorting: SortType;
};

export const SortChip = (props: SortChipProps) => {
  const { sorting, updateSorting } = useOffersContext();
  const active = sorting?.sort === props.sorting;

  const handleClick = () => {
    if (active && sorting.order == 'ASC') {
      updateSorting(props.sorting, 'DESC');
      return;
    }
    if (active && sorting.order == 'DESC') {
      updateSorting(null, 'ASC');
      return;
    }
    if (!(window.location.hostname === 'localhost')) {
      const { specialities, regions } = parseSearchParams();
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        event: `sort_by_${props.sorting}`,
        filter_discipline_code: specialities.join(','),
        filter_geo_code: regions.join(','),
        sort_type: sorting?.order === 'DESC' ? 'ASC' : 'DESC',
      });
    }

    updateSorting(props.sorting, 'ASC');
  };

  return (
    <button type='button' onClick={handleClick} className={styles.chip} data-active={active}>
      <div className={styles.icon}>
        <SortIcon type={active ? (sorting?.order ?? 'ASC') : 'ASC'} />
      </div>
      <div className={styles.title}>
        <p>{props.title}</p>
      </div>
    </button>
  );
};
