import React from 'react';

interface ArrowRightProps {
  color?: string;
  size?: number;
  className?: string;
  style?: React.CSSProperties;
}

export const ArrowRight: React.FC<ArrowRightProps> = ({ color = '#f59d33', size = 16, className = '', style = {} }) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 21 20'
      fill='none'
      stroke={color}
      strokeLinecap='round'
      strokeLinejoin='round'
      height={size}
      width={size}
      className={className}
      style={style}
    >
      <path
        d='M4.66663 10H16.3333M16.3333 10L10.5 4.16669M16.3333 10L10.5 15.8334'
        stroke='white'
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};
