import { useQuery } from '@tanstack/react-query';
import { useMemo } from 'react';

import { getUniversities } from '../../api/filters';

export const useUniversities = (selectedRegions?: number[]) => {
  const { data, isLoading, error } = useQuery({
    queryKey: ['universities', selectedRegions],
    queryFn: () => getUniversities(selectedRegions),
  });

  return { data, isLoading, error };
};

export const useUniversityOptions = (selectedRegions?: number[]) => {
  const { data = [], isLoading, error } = useUniversities(selectedRegions);

  const universityOptions = useMemo(() => {
    return data?.map((university) => ({
      id: university.id,
      label: university.name,
    }));
  }, [data]);

  return { universityOptions, isLoading, error };
};
