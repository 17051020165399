import { ReactNode } from 'react';
import { Controller, useFormContext, useWatch } from 'react-hook-form';

import { SpecialityFilterCard } from '../../pages/Offers/components/SpecialityFilterCardV2/SpecialityFilterCard';
import { useSpecialitiesFilters } from '../../pages/Offers/hooks/useSpecialitiesFilters';

type SpecialitiesInputProps = {
  title?: ReactNode;
  name: string;
};

export const SpecialitiesInput = ({ title, name }: SpecialitiesInputProps) => {
  const { data: specialities } = useSpecialitiesFilters();
  const { control, setValue } = useFormContext();

  const specialitiesIds = useWatch({ control, name: name }) || [];

  const adaptedSpecialities = specialities?.map((group) => ({
    ...group,
    isChecked: group.branchRelatedSpecialities?.some((filter) => specialitiesIds.includes(filter.id)),
    branchRelatedSpecialities: group.branchRelatedSpecialities?.map((filter) => ({
      ...filter,
      isChecked: specialitiesIds.includes(filter.id),
    })),
  }));

  const onSelect = (id: number) => {
    if (specialitiesIds.includes(id)) {
      setValue(
        name,
        specialitiesIds.filter((specialityId) => specialityId !== id),
      );
    } else {
      setValue(name, [...specialitiesIds, id]);
    }
  };

  const onSelectMultiple = (ids: number[], value: boolean) => {
    if (value) {
      setValue(name, [...specialitiesIds.filter((specialityId) => !ids.includes(specialityId)), ...ids]);
    } else {
      setValue(
        name,
        specialitiesIds.filter((specialityId) => !ids.includes(specialityId)),
      );
    }
  };

  return (
    <Controller
      name={name}
      control={control}
      render={() => (
        <SpecialityFilterCard
          title={title}
          data={adaptedSpecialities}
          selectedFilters={specialitiesIds}
          onSelect={onSelect}
          onSelectMultiple={onSelectMultiple}
        />
      )}
    />
  );
};
