import { useTranslation } from 'react-i18next';

import FormAutocomplete from '../../../components/mui-form/FormAutocomplete';
import FormTextField from '../../../components/mui-form/FormTextField';
import { useUniversityOptions } from '../../../hooks/filters/useUniversities';
import { useAuth } from '../../Auth/state/AuthContext';
import Card from '../components/Card';
import InputGroup from '../components/InputGroup';
import NameCard from '../components/NameCard';

export default function TeacherProfile() {
  const { t } = useTranslation();
  const { user } = useAuth();
  const { universityOptions } = useUniversityOptions();

  return (
    <>
      <NameCard name={user?.name} userType='TEACHER' />
      <Card title={t('profile.personalData.title')} checkFields={['name']}>
        <InputGroup>
          <FormTextField name='name' label={t('profile.personalData.name')} />
        </InputGroup>
      </Card>
      <Card title={t('profile.teachingInfo.title')} checkFields={['subjects', 'universities']}>
        <InputGroup>
          <FormAutocomplete
            name='universities'
            label={t('profile.teachingInfo.universities')}
            options={universityOptions}
            placeholder={t('profile.teachingInfo.universitiesPlaceholder')}
            multiple
          />
          <FormTextField name='subjects' label={t('profile.teachingInfo.subjects')} />
        </InputGroup>
      </Card>
    </>
  );
}
