import { zodResolver } from '@hookform/resolvers/zod';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import Button from '../../../../../components/ButtonV2/Button';
import FormAutocomplete from '../../../../../components/mui-form/FormAutocomplete';
import FormTextField from '../../../../../components/mui-form/FormTextField';
import { useUniversityOptions } from '../../../../../hooks/filters/useUniversities';
import Label from '../../../components/Label';
import Section from '../../../components/Section';
import ValidationError from '../../../components/ValidationError';
import { SetupSchema } from '../../../schema/setup-form';
import { TeacherFormSchema, teacherFormSchema, TeacherSchema } from '../../../schema/teacher-form';
import { useAuthForm } from '../../../state/AuthFormContext';

export default function TeacherForm() {
  const authStore = useAuthForm();
  const { t } = useTranslation();

  const form = useForm<TeacherFormSchema>({
    resolver: zodResolver(teacherFormSchema),
    defaultValues: (authStore.formData?.data as TeacherSchema).teacherData || {},
  });

  const { universityOptions } = useUniversityOptions();

  const onSubmit = (data: TeacherFormSchema) => {
    const setupData = authStore.formData?.data as SetupSchema;

    authStore.appendFormData({
      type: 'TEACHER',
      data: {
        setupData,
        teacherData: data,
      },
    });

    authStore.nextStep();
  };

  return (
    <FormProvider {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)}>
        <Section>
          <Label required>{t('auth.registration-flow.preferences.teacher-universities.label')}</Label>
          <FormAutocomplete
            name='universities'
            options={universityOptions}
            label={t('auth.registration-flow.preferences.teacher-universities.short-label')}
            multiple
            placeholder={t('auth.registration-flow.preferences.teacher-universities.placeholder')}
          />
          <ValidationError error={form.formState.errors.universities} />
        </Section>
        <Section>
          <Label required>{t('auth.registration-flow.preferences.teacher-subjects.label')}</Label>
          <FormTextField
            name='subjects'
            label={t('auth.registration-flow.preferences.teacher-subjects.label')}
            placeholder={t('auth.registration-flow.preferences.teacher-subjects.placeholder')}
          />
          <ValidationError error={form.formState.errors.subjects} />
        </Section>
        <Button disabled={!form.formState.isValid} type='submit'>
          {t('auth.registration-flow.buttons.complete')}
        </Button>
      </form>
    </FormProvider>
  );
}
