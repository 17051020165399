import { Typography } from '@mui/material';
import { FC, Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useToggle } from 'react-use';

import { langPath } from '../../../../../../../utils/langPath';
import { ArrowIcon } from '../../../../../../Offers/icons/ArrowIcon';
import styles from '../OffersTab.module.scss';

interface OfferCardProps {
  offerId: number;
  universityName: string;
  facultyName: string;
  specialtyName: string;
  specialtyCode: string;
  minBudgetScore: number | null;
  maxBudgetLicence: number | null;
  maxLicense: number | null;
  minContractScore: number | null;
  price: number | null;
  totalApplies: number | null;
  universityId: number;
}

export const OfferCard: FC<OfferCardProps> = ({
  offerId,
  universityName,
  facultyName,
  specialtyName,
  specialtyCode,
  minBudgetScore,
  maxBudgetLicence,
  maxLicense,
  minContractScore,
  price,
  totalApplies,
  universityId,
}) => {
  const { t } = useTranslation();
  const [isExpanded, toggleExpansion] = useToggle(true);

  const columns = [
    [
      {
        header: t('universityPage.offersTab.budgetPassingScore'),
        value: minBudgetScore !== null ? minBudgetScore : '-',
      },
      {
        header: t('universityPage.offersTab.budgetPlaces'),
        value: maxBudgetLicence !== null ? maxBudgetLicence : '-',
      },
      {
        header: t('universityPage.offersTab.totalPlaces'),
        value: maxLicense !== null ? maxLicense : '-',
      },
    ],
    [
      {
        header: t('universityPage.offersTab.contractPassingScore'),
        value: minContractScore !== null ? minContractScore : '-',
      },
      {
        header: t('universityPage.offersTab.contractCost'),
        value: price !== null ? price.toLocaleString() : '-',
      },
      {
        header: t('universityPage.offersTab.totalApplications'),
        value: totalApplies !== null ? totalApplies.toLocaleString() : '-',
      },
    ],
  ];

  return (
    <div className={styles.offerCard}>
      <div className={styles.universityHeader}>
        <div className={styles.universityInfo}>
          <span className={styles.universityIcon}>🏛</span>
          <span className={styles.universityName}>{universityName}</span>
        </div>
        <div className={styles.universityActions}>
          {/* <button className={styles.bookmarkButton}>
            <BookmarkIcon />
          </button> */}
          <button
            className={`${styles.expandButton} ${!isExpanded ? styles.collapsed : ''}`}
            onClick={toggleExpansion}
            aria-expanded={isExpanded}
            aria-label={
              isExpanded ? t('universityPage.common.actions.collapse') : t('universityPage.common.actions.expand')
            }
          >
            <ArrowIcon color='black' direction='DOWN' />
          </button>
        </div>
      </div>

      <div className={`${styles.offerContent} ${!isExpanded ? styles.hidden : ''}`}>
        <div className={styles.facultyInfo}>
          <span className={styles.facultyIcon}>🎓</span>
          <Typography>{facultyName}</Typography>
        </div>

        <div className={styles.specialtyInfo}>
          <span className={styles.specialtyCode}>{specialtyCode}</span>
          <Typography>{specialtyName}</Typography>
        </div>

        <div className={styles.scoresContainer}>
          {columns.map((column, colIndex) => (
            <div key={colIndex} className={styles.scoreColumn}>
              {column.map((item) => (
                <Fragment key={item.header}>
                  <div className={styles.header}>{item.header}</div>
                  <div className={styles.value}>{item.value}</div>
                </Fragment>
              ))}
            </div>
          ))}
        </div>

        <div className={styles.actionButtons}>
          <a
            target='_blank'
            rel='noreferrer noopener nofollow'
            href={`https://studsearch.org/?university=${universityId}`}
            className={styles.applyButton}
          >
            {t('universityPage.offersTab.actions.writeToStudents')}
          </a>
          <Link
            className={styles.detailsButton}
            target='_blank'
            rel='noreferrer noopener nofollow'
            to={langPath(`/offers/result/${offerId}`)}
          >
            <span>{t('universityPage.offersTab.actions.moreDetails')}</span>
            <img src='/emoji/arrow-right-to-line.svg' alt='arrow-right-to-line' />
          </Link>
        </div>
      </div>
    </div>
  );
};
