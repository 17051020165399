import { FilterCard } from '../../components/FilterCard/FilterCard';
import { OffersHeader } from '../../components/Header/Header';
import { ShowResultButton } from '../../components/ShowMoreBtn/ShowResultButton';
import { SpecialityFilterCard } from '../../components/SpecialityFilterCardV2/SpecialityFilterCard';
import { UniversityFilterCard } from '../../components/UniversityFilterCard/UniversityFilterCard';
import { useEducationBasesFilters } from '../../hooks/useEducationBasesFilters';
import { useEducationFormFilters } from '../../hooks/useEducationFormFilters';
import { useRegionFilters } from '../../hooks/useRegionFilters';
import { useSortingFilters } from '../../hooks/useSortingFilters';
import { useSpecialitiesFilters } from '../../hooks/useSpecialitiesFilters';
import { useUniversityFilters } from '../../hooks/useUniversityFilters';

import styles from './OffersFilters.module.scss';

export const OffersFilters = () => {
  const educationFormFilters = useEducationFormFilters();
  const educationBasesFilters = useEducationBasesFilters();
  const regionFilters = useRegionFilters();
  const sortingFilters = useSortingFilters();
  const universityFilters = useUniversityFilters();
  const specialitiesFilters = useSpecialitiesFilters();

  return (
    <div className={styles.offersWrap}>
      <OffersHeader />
      <div className={styles.filters}>
        <FilterCard title='Освітній рівень' {...educationBasesFilters} />
        <SpecialityFilterCard title='Галузь та спеціальність' {...specialitiesFilters} />
        <FilterCard title='Регіони' {...regionFilters} />
        <FilterCard title='Форма навчання' {...educationFormFilters} />
        <UniversityFilterCard title='Заклад вищої освіти (ЗВО)' {...universityFilters} />
        <FilterCard title='Групувати за' {...sortingFilters} />
      </div>

      <div className={styles.btnWrap}>
        <ShowResultButton />
      </div>
    </div>
  );
};
