import { useTranslation } from 'react-i18next';

import { SpecialityWithCoefficients } from '../../../../../../api/types/specialities';
import { gradesValidator } from '../../../../utils/gradesValidator';
import styles from '../../SubjectInputs.module.scss';
import { NumberInput } from '../NumberInput/NumberInput';
type MandatorySubjectsProps = {
  speciality: SpecialityWithCoefficients | null;
  math: number | '';
  setMath: (value: number | '') => void;
  ukrainian: number | '';
  setUkrainian: (value: number | '') => void;
  history: number | '';
  setHistory: (value: number | '') => void;
};

export const MandatorySubjects = ({
  speciality,
  history,
  math,
  setHistory,
  setMath,
  setUkrainian,
  ukrainian,
}: MandatorySubjectsProps) => {
  const { t } = useTranslation();

  return (
    <div className={styles.subjectInputs}>
      <p className='h2'>{t('calculator.inputs.titles.mandatory')}</p>
      <p style={{ marginBottom: 16 }}>{t('calculator.inputs.descriptions.mandatory')}</p>
      <div className={styles.inputWrapper}>
        <NumberInput
          id='math'
          validator={gradesValidator}
          label={t('calculator.subjects.nominative.mathematics') + '*'}
          value={math}
          onChange={setMath}
        />
        {speciality && <p>х&nbsp;{speciality.coefficients.mathematics}</p>}
      </div>
      <div className={styles.inputWrapper}>
        <NumberInput
          id='ukrainian'
          validator={gradesValidator}
          label={t('calculator.subjects.nominative.ukrainian') + '*'}
          value={ukrainian}
          onChange={setUkrainian}
        />
        {speciality && <p>х&nbsp;{speciality.coefficients.ukrainian}</p>}
      </div>
      <div className={styles.inputWrapper}>
        <NumberInput
          id='history'
          validator={gradesValidator}
          label={t('calculator.subjects.nominative.history') + '*'}
          value={history}
          onChange={setHistory}
        />
        {speciality && <p>х&nbsp;{speciality.coefficients.history}</p>}
      </div>
    </div>
  );
};
