import { useMemo } from 'react';

import { useUniversities } from '../../../hooks/filters/useUniversities';
import { UNIVERSITIES_PARAM } from '../filters/constants';

import { useFilterSearchParams } from './useFilterSearchParams';

export const useUniversityFilters = () => {
  const { data, isLoading, error } = useUniversities();

  const { selectedFilters, onSelect, isChecked } = useFilterSearchParams(UNIVERSITIES_PARAM, {
    variant: 'multiple',
  });

  const universities = useMemo(
    () =>
      data?.map((university) => ({
        ...university,
        isChecked: isChecked(university.id),
      })),
    [data, selectedFilters],
  );

  return { data: universities, selectedFilters, onSelect, isLoading, error };
};
