import { SubjectColors } from '../types/subject-colors';

export const subjectColors: SubjectColors = {
  foreignLanguage: '#FF8C00',
  biology: '#8B008B',
  physics: '#4682B4',
  chemistry: '#DC143C',
  ukrainianLiterature: '#FF69B4',
  geography: '#20B2AA',
  creativeCompetition: '#FFD700',
};
