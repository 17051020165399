import { useQuery } from '@tanstack/react-query';
import { useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';

import { SpecialitiesFilters, getOffersCount } from '../../../api/offers';
import {
  EDUCATION_BASES_PARAM,
  EDUCATION_FORM_PARAM,
  OFFER_TYPES_PARAM,
  REGIONS_PARAM,
  SPECIALITIES_PARAM,
  UNIVERSITIES_PARAM,
} from '../filters/constants';
export const useFilterButton = () => {
  const [searchParams] = useSearchParams();

  const params: SpecialitiesFilters = useMemo(() => {
    return {
      sort: undefined,
      order: 'ASC',
      qualificationId: Number(searchParams.get(EDUCATION_BASES_PARAM)),
      specialitiesIds: searchParams.get(SPECIALITIES_PARAM)?.split(',').map(Number) ?? [],
      regionIds: searchParams.get(REGIONS_PARAM)?.split(',').map(Number) ?? [],
      universityIds: searchParams.get(UNIVERSITIES_PARAM)?.split(',').map(Number) ?? [],
      educationFormIds: searchParams.get(EDUCATION_FORM_PARAM)?.split(',').map(Number) ?? [],
      budgetOnly: searchParams.get(OFFER_TYPES_PARAM) ? true : false,
    };
  }, [searchParams]);

  const enabled = !!(
    searchParams.get(SPECIALITIES_PARAM) ||
    searchParams.get(REGIONS_PARAM) ||
    searchParams.get(UNIVERSITIES_PARAM)
  );

  const { data, isLoading } = useQuery({
    queryKey: ['offers-count', params],
    queryFn: () => getOffersCount(params),
    enabled,
  });

  return { data, isLoading, enabled };
};
