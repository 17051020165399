import { Box, Typography } from '@mui/material';
import { langPath } from '../../../utils/langPath';
import styles from '../Partners.module.scss';
import CustomBreadcrumbs from '../../../components/Breadcrumbs/Breadcrumbs';

export default function PartnersHeaderSection() {
  const breadcrumbs = [{ title: 'Наші партнери', href: langPath('/partners') }];

  return (
    <Box className={styles.sectionWrap} sx={{ paddingTop: 3, paddingBottom: 3, marginTop: 3 }}>
      <div className={styles.headerSection}>
        <div className={styles.headerSubSection}>
          <Typography variant='h1'>Наші партнери</Typography>
          <CustomBreadcrumbs items={breadcrumbs} />
        </div>
      </div>
    </Box>
  );
}
