import { useQuery } from '@tanstack/react-query';
import { getPartners } from '../../api/partners';
import PartnersHeaderSection from './components/PartnersHeaderSection';
import PartnersSection from './components/PartnersSection';
import styles from './Partners.module.scss';
import BecomePartnerSection from './components/BecomePartnerSection';
import AbitlySection from './components/AbitlySection';
import { Typography } from '@mui/material';

export default function PartnersPage() {
  const { data: partners, isLoading } = useQuery({
    queryKey: ['partners'],
    queryFn: getPartners,
  });

  return (
    <div className={styles.mainContainer}>
      <PartnersHeaderSection />
      <AbitlySection />
      {isLoading ? (
        <Typography variant='h2' className={styles.sectionWrap}>
          Інформація про партнерів завантажується...
        </Typography>
      ) : (
        <>
          <PartnersSection
            title='🎓 Освітні партнери'
            description='Університети, факультети та інші освітні організації, які підтримують нашу місію, допомагають з інформаційною підтримкою, діляться новинами та відгуками студентів.'
            partners={partners?.educational}
          />
          <PartnersSection
            title='💼 Партнери з ІТ та індустрії'
            description='Компанії, які підтримують нас технологічно, надають ресурси, експертизу або допомагають створювати корисний контент для абітурієнтів.'
            partners={partners?.IT}
          />
          <PartnersSection
            title='📣 Інформаційні партнери'
            description='Медіа, блогери, освітні спільноти та інші платформи, які допомагають нам ділитися інформацією з більшою кількістю учнів, студентів та батьків.'
            partners={partners?.informative}
          />
          <PartnersSection
            title='🧡 Волонтерські та освітні організації'
            description='Наші друзі, які поширюють проєкт серед молоді та беруть активну участь у покращенні освіти в Україні.'
            partners={partners?.volunteer}
          />
        </>
      )}
      <BecomePartnerSection />
    </div>
  );
}
