import { UserType } from '../../Auth/schema/user-type';
import ApplicantMemberProfile from '../pages/ApplicantMemberProfile';
import ApplicantProfile from '../pages/ApplicantProfile';
import OtherUserProfile from '../pages/OtherUserProfile';
import StudentProfile from '../pages/StudentProfile';
import TeacherProfile from '../pages/TeacherProfile';

export const pages: Record<UserType, React.FC> = {
  APPLICANT: ApplicantProfile,
  APPLICANT_MEMBER: ApplicantMemberProfile,
  STUDENT: StudentProfile,
  TEACHER: TeacherProfile,
  OTHER: OtherUserProfile,
  UNKNOWN: () => null,
};
