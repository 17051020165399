import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';

import { UniversityInfo } from '../../../../../api/universities';
import styles from '../../UniversityPage.module.scss';
import ContactsTab from '../TabContent/ContactsTab/ContactsTab';
import OffersTab from '../TabContent/OffersTab/OffersTab';
import SpecialtiesTab from '../TabContent/SpecialtiesTab/SpecialtiesTab';
import StructureTab from '../TabContent/StructureTab/StructureTab';

interface UniversityTabsProps {
  universityData: UniversityInfo;
}

export const UniversityTabs: FC<UniversityTabsProps> = ({ universityData }) => {
  const { t, i18n } = useTranslation();
  const [searchParams] = useSearchParams();
  const activeTab = searchParams.get('tab') || 'contacts';

  switch (activeTab) {
    // case 'info':
    //   return (
    //     <InfoTab
    //       universityInfo={{
    //         studentCount: universityData?.studentsCount,
    //         teacherCount: universityData?.teachersCount,
    //         candidatesCount: universityData?.candidatesCount,
    //         professorsCount: universityData?.professorsCount,
    //         features: universityData?.features,
    //       }}
    //     />
    //   );
    case 'contacts':
      return (
        <ContactsTab
          contacts={{
            city: universityData?.contacts.city,
            address: universityData?.contacts.address,
            website: universityData?.contacts.website,
            admissionsCommittee: universityData?.contacts.admissionsCommittee,
          }}
        />
      );
    case 'structure':
      return <StructureTab universityShortName={universityData.shortName[i18n.language]} />;
    case 'specialties':
      return <SpecialtiesTab />;
    case 'competitive':
      return <OffersTab />;
    default:
      return <div className={styles.wrapperLoading}>{t('universityPage.common.noInformation')}</div>;
  }
};
