import { Link } from '@mui/material';
import { Fragment, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { PartialSubjects, SpecialityWithCoefficients } from '../../../../api/types/specialities';
import { langPath } from '../../../../utils/langPath';
import { getCalculatedGradeData } from '../../utils/getCalculatedGradeData';

import styles from './CalculatedGrade.module.scss';

interface Props {
  speciality: SpecialityWithCoefficients;
  grades: PartialSubjects;
  courseGrade: number;
}

const MAX_POSSIBLE_GRADE = 200;

export function CalculatedGrade({ courseGrade = 0, grades, speciality }: Props) {
  const { t } = useTranslation();

  const ukrainian = grades.ukrainian ?? 0;
  const mathematics = grades.mathematics ?? 0;
  const history = grades.history ?? 0;

  const creativeCompetitionGrade =
    grades.creativeCompetition !== undefined && grades.creativeCompetition !== null
      ? grades.creativeCompetition
      : undefined;

  const optionalSubjects = Object.entries(grades)
    .filter(
      ([subject, grade]) =>
        subject !== 'ukrainian' &&
        subject !== 'mathematics' &&
        subject !== 'history' &&
        subject !== 'creativeCompetition' &&
        grade,
    )
    .map(([subject, grade]) => ({
      subject,
      grade: grade ?? 0,
    }));

  const calculatedGradeData = useMemo(
    () =>
      getCalculatedGradeData(
        speciality,
        ukrainian,
        mathematics,
        history,
        optionalSubjects,
        creativeCompetitionGrade,
        courseGrade,
      ),
    [speciality, ukrainian, mathematics, history, optionalSubjects, creativeCompetitionGrade, courseGrade],
  );

  const {
    subjectResults,
    bestSubject,
    profileSubject,
    profileSubjects,
    profileSubjectCoef,
    equivalentProfileGrade,
    isProfileSubjectChosen,
    secondBestSubject,
    equivalentSecondBestGrade,
    bestProfileSubject,
    areAllCoefsEqual,
  } = calculatedGradeData;

  const isProfileGradeImpossible = equivalentProfileGrade > MAX_POSSIBLE_GRADE;
  const isSecondBestGradeImpossible = equivalentSecondBestGrade > MAX_POSSIBLE_GRADE;

  return (
    <>
      <h2>{t('calculator.calculatedGrade.title')}</h2>
      <div className={styles.wrapper}>
        <ul className={styles.gradesList}>
          {subjectResults.length > 0 ? (
            subjectResults.map(({ grade, subject }) => (
              <li key={subject}>
                {t('calculator.calculatedGrade.with')} {t(`calculator.subjects.instrumental.${subject}`)}
                {': '}
                <span className={styles.bold}>{grade}</span>
              </li>
            ))
          ) : (
            <li>{t('calculator.calculatedGrade.noSubjects')}</li>
          )}
        </ul>
        <p className={styles.bold}>{t('calculator.calculatedGrade.payAttention')}</p>

        <p className={styles.mainSubject}>
          {t('calculator.calculatedGrade.onSpeciality')} {speciality.code ? `${speciality.code} ` : ''}
          {speciality.name}{' '}
          {areAllCoefsEqual ? (
            t('calculator.calculatedGrade.allCoefsEqual')
          ) : (
            <>
              {t(`calculator.calculatedGrade.mainSubject${profileSubjects.length > 1 ? '.plural' : '.single'}`)}
              <span className={styles.bold}>
                {profileSubjects.length > 1 ? (
                  <>
                    {profileSubjects.map((subject, index) => (
                      <Fragment key={subject}>
                        {index > 0 &&
                          (index === profileSubjects.length - 1 ? t('calculator.calculatedGrade.and') : ', ')}{' '}
                        {t(`calculator.subjects.nominative.${subject}`)}{' '}
                      </Fragment>
                    ))}
                  </>
                ) : (
                  t(`calculator.subjects.nominative.${profileSubject}`)
                )}
              </span>{' '}
              – {t('calculator.calculatedGrade.withCoef')} <span className={styles.bold}>{profileSubjectCoef}</span>
            </>
          )}
        </p>

        {!areAllCoefsEqual &&
          (isProfileSubjectChosen ? (
            <p style={{ marginBottom: '8px' }}>
              {t('calculator.calculatedGrade.forExample')}{' '}
              <span className={styles.bold}>{bestProfileSubject.grade}</span> (
              {t(`calculator.subjects.nominative.${bestProfileSubject.subject}`)} {t('calculator.calculatedGrade.with')}{' '}
              {grades[bestProfileSubject.subject]}),
              {t('calculator.calculatedGrade.ifTake')}
              <span className={styles.bold}>
                {' '}
                {t(`calculator.subjects.accusative.${secondBestSubject.subject}`)}
              </span>{' '}
              {t('calculator.calculatedGrade.on')} <span className={styles.bold}>{equivalentSecondBestGrade}</span>
              {isSecondBestGradeImpossible && (
                <span className={styles.bold}> {t('calculator.calculatedGrade.impossible')}</span>
              )}
            </p>
          ) : (
            <p style={{ marginBottom: '8px' }}>
              {t('calculator.calculatedGrade.forExample')} <span className={styles.bold}>{bestSubject.grade}</span> (
              {t(`calculator.subjects.nominative.${bestSubject.subject}`)} {t('calculator.calculatedGrade.with')}{' '}
              {grades[bestSubject.subject]}
              ),
              {t('calculator.calculatedGrade.ifTake')}
              <span className={styles.bold}> {t(`calculator.subjects.accusative.${profileSubject}`)}</span>{' '}
              {t('calculator.calculatedGrade.on')} <span className={styles.bold}>{equivalentProfileGrade}</span>
              {isProfileGradeImpossible && (
                <span className={styles.bold}> {t('calculator.calculatedGrade.impossible')}</span>
              )}
            </p>
          ))}

        {!areAllCoefsEqual && <p style={{ marginBottom: '12px' }}>{t('calculator.calculatedGrade.compare')}</p>}
        <p>
          {t('calculator.calculatedGrade.officially')}{' '}
          <Link href={langPath('/blog/nmt-coefficients')}>{t('calculator.calculatedGrade.ourBlog')}</Link>
        </p>
      </div>
    </>
  );
}
