import { Typography } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { FiltersInput } from '../../../components/mui-form/FiltersInput';
import FormAutocomplete from '../../../components/mui-form/FormAutocomplete';
import FormSelect from '../../../components/mui-form/FormSelect';
import FormTextField from '../../../components/mui-form/FormTextField';
import { SpecialitiesInput } from '../../../components/mui-form/SpecialitiesInput';
import { useCountryOptions } from '../../../hooks/filters/useCountryOptions';
import { useUniversityOptions } from '../../../hooks/filters/useUniversities';
import { useYearOptions } from '../../../hooks/filters/useYearOptions';
import { useAuth } from '../../Auth/state/AuthContext';
import { useRegionFilters } from '../../Offers/hooks/useRegionFilters';
import Card from '../components/Card';
import InputGroup from '../components/InputGroup';
import NameCard from '../components/NameCard';
import { OPTIONAL_SUBJECTS, REQUIRED_SUBJECTS } from '../constants/subjects';
import { ApplicantMemberForm } from '../schema/user-form';
import { scoreValidation } from '../utils/score-validation';

export default function ApplicantMemberProfile() {
  const { t } = useTranslation();
  const { user } = useAuth();
  const { countryOptions } = useCountryOptions();
  const { universityOptions } = useUniversityOptions();
  const { data: regions } = useRegionFilters();
  const { yearOptions } = useYearOptions();

  const form = useFormContext<ApplicantMemberForm>();

  const onClearSubjects = () => {
    form.setValue(
      'requiredSubjects',
      form.getValues('requiredSubjects').map((subject) => ({ ...subject, score: null })),
    );
    form.setValue(
      'optionalSubjects',
      form.getValues('optionalSubjects').map((subject) => ({ ...subject, score: null })),
    );
  };

  return (
    <>
      <NameCard name={user?.name} userType='APPLICANT' />
      <Card title={t('profile.personalData.title')} checkFields={['name', 'country', 'city']}>
        <InputGroup>
          <FormTextField name='name' label={t('profile.personalData.name')} />
          <FormAutocomplete
            name='country'
            label={t('profile.personalData.applicantCountry')}
            options={countryOptions}
          />
          <FormTextField name='city' label={t('profile.personalData.applicantCity')} />
        </InputGroup>
      </Card>
      <Card title={t('profile.nmtScores.title')} checkFields={['subjectScores']} onClear={onClearSubjects}>
        <Typography variant='body2'>{t('profile.nmtScores.applicantDescription')}</Typography>
        <InputGroup title={t('profile.nmtScores.mandatorySubjects')}>
          {REQUIRED_SUBJECTS.map((subject, i) => (
            <FormTextField
              name={`requiredSubjects.${i}.score`}
              key={subject.name as string}
              label={`${t(subject.translation)}*`}
              validation={scoreValidation}
            />
          ))}
        </InputGroup>
        <InputGroup title={t('profile.nmtScores.optionalSubjects')}>
          {OPTIONAL_SUBJECTS.map((subject, i) => (
            <FormTextField
              name={`optionalSubjects.${i}.score`}
              key={subject.name as string}
              label={t(subject.translation)}
              validation={scoreValidation}
            />
          ))}
        </InputGroup>
      </Card>
      <Card
        title={t('profile.enrollmentInfo.title')}
        checkFields={['enrollmentYear', 'interestedSpecialities', 'selectedRegions', 'selectedUniversities']}
      >
        <InputGroup>
          <FormSelect name='enrollmentYear' label={t('profile.enrollmentInfo.year')} options={yearOptions} />
          <SpecialitiesInput
            title={<Typography variant='body2'>{t('profile.enrollmentInfo.specialities')}</Typography>}
            name='interestedSpecialities'
          />
          <FiltersInput
            title={<Typography variant='body2'>{t('profile.enrollmentInfo.regions')}</Typography>}
            name='selectedRegions'
            data={regions}
          />
          <Typography variant='body2'>{t('profile.enrollmentInfo.applicantUniversities')}</Typography>
          <FormAutocomplete
            name='selectedUniversities'
            label={t('profile.enrollmentInfo.universities')}
            options={universityOptions}
            placeholder={t('profile.enrollmentInfo.universitiesPlaceholder')}
            multiple
          />
        </InputGroup>
      </Card>
    </>
  );
}
