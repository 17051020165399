import { CheckMarkIcon } from '../../icons/CheckMarkIcon';
import { CodeChip } from '../CodeChip/CodeChip';
import { FilterItem } from '../FilterCard/FilterCard';

import styles from './FilterCheckbox.module.scss';

type FilterCheckboxProps = {
  item: FilterItem;
  choice: 'single' | 'multiple';
  code?: string;
  size: 'small' | 'big';
  onSelect: (id: number | string) => void;
};

export const FilterCheckbox = ({ item, choice, code, size, onSelect }: FilterCheckboxProps) => {
  return (
    <label className={code ? styles.wrapWithDesc : styles.wrapWithoutDesc}>
      <div className={styles.wrapCheckbox}>
        <input
          type='checkbox'
          onChange={() => onSelect(item.id)}
          className={styles.inputCheckBox}
          checked={!!item.isChecked}
        />
        <CheckMarkIcon
          className={size == 'small' ? styles.checkboxSmall : styles.checkboxBig}
          data-radio={choice === 'single'}
        />
        {code && (
          <span style={{ marginRight: size == 'small' ? '2px' : '4px' }}>
            <CodeChip code={code} size={size} />{' '}
          </span>
        )}
      </div>
      <div className={styles.title}>{item.name}</div>
    </label>
  );
};
