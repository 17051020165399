/* eslint-disable */
import cx from 'classnames';
import { Children, Fragment, ReactNode } from 'react';

import { Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { DEFAULT_LANGUAGE, LANGUAGES } from '../../common/i18n';
import { useHeaderMenu } from '../../hooks/general/useHeaderMenu';
import { useAuth } from '../../pages/Auth/state/AuthContext';
import { langPath } from '../../utils/langPath';
import Icon from '../icon';
import { Link } from '../Link/Link';
import AuthSection from './AuthSection';
import BurgerButton from './BurgerButton';
import HeaderMenuButton from './HeaderMenuButton';
import './index.scss';
import ProfileSection from './ProfileSection';

export enum HeaderType {
  LIGHT = 'LIGHT',
  DARK = 'DARK',
}

export const HeaderMenuGroup = ({ children }: { children?: ReactNode }) => {
  const buttons = Children.toArray(children);

  return (
    <div className='AppHeaderMenuGroup'>
      {buttons.map((button, i) => (
        <Fragment key={i}>
          {i > 0 && <div className='AppHeaderMenuGroup_Separator' />}
          {button}
        </Fragment>
      ))}
    </div>
  );
};

export const HeaderMenu = ({ open }: { open?: boolean }) => {
  const { t } = useTranslation();
  const { user } = useAuth();

  return (
    <div className={cx('AppHeaderMenu', 'AppHeaderMenuLight', { open })}>
      <div>
        {user && <ProfileSection />}
        <HeaderMenuGroup>
          <HeaderMenuButton emoji='/emoji/ukraine.png' name={t('header.searchOffers')} to='/' />
          <HeaderMenuButton emoji='/emoji/checkedMark.png' name={t('header.savedOffers')} to='/offers/saved' />
          <HeaderMenuButton emoji='/emoji/open-book.png' name={t('header.blog')} to='/blog' />
          {!user && (
            <HeaderMenuButton
              emoji='/emoji/abacus.png'
              name={t('header.specialities-calculator')}
              to='/nmt-calculator'
            />
          )}
        </HeaderMenuGroup>
        <HeaderMenuGroup>
          <HeaderMenuButton
            emoji='/emoji/graduationCap.png'
            name={t('header.studSearch')}
            to='https://studsearch.org/'
            target='_blank'
          />
        </HeaderMenuGroup>
        <HeaderMenuGroup>
          <HeaderMenuButton emoji='/emoji/hands.png' name={t('header.aboutUs')} to={'/about'} />
          <HeaderMenuButton
            emoji='/emoji/callMeHand.png'
            name={t('header.contactUs')}
            to='https://t.me/VladBandurin'
            target='_blank'
          />
        </HeaderMenuGroup>
        <HeaderMenuGroup>
          <HeaderMenuButton
            emoji='/emoji/moneyWithWings.png'
            name={t('header.donateServers')}
            to='https://send.monobank.ua/jar/3UNSF6txsH'
            target='_blank'
          />
        </HeaderMenuGroup>
        {!user && <AuthSection />}

        <div className='Annotations'>
          <Link className='HeaderLink' to='/privacy'>
            Політика конфіденційності
          </Link>
          &
          <Link className='HeaderLink' to='/terms'>
            Угода Користувача
          </Link>
        </div>
      </div>
    </div>
  );
};

export const Header = () => {
  const { i18n } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const { lang } = useParams<{ lang: string }>();

  const changeLanguage = () => {
    const newLanguage = i18n.language === DEFAULT_LANGUAGE ? LANGUAGES.en : DEFAULT_LANGUAGE;

    if (newLanguage !== i18n.language) {
      i18n.changeLanguage(newLanguage);
      localStorage.setItem('selectedLanguage', newLanguage);

      const pathWithoutLang = location.pathname.replace(/^\/[^\/]+/, '');

      const searchParams = location.search;

      navigate(`/${newLanguage}${pathWithoutLang}${searchParams}`);
    }
  };

  const { open, openDelayed, setOpen } = useHeaderMenu();

  return (
    <>
      <Grid className={cx('AppHeader', 'AppHeaderLight')}>
        <Grid>
          <Grid item container>
            <Link to={langPath({ pathname: '/', search: location.search })} style={{ cursor: 'pointer' }}>
              <img src={'/emoji/logo.svg'} alt='Logo' />
            </Link>
          </Grid>
          <Grid item container justifyContent='flex-end' alignItems='center'>
            <Icon
              style={{ cursor: 'pointer' }}
              onClick={() => changeLanguage()}
              width='40'
              height='40'
              icon={i18n.language}
            />
            <BurgerButton value={open} setValue={setOpen} />
          </Grid>
        </Grid>
      </Grid>
      {(open || openDelayed) && <HeaderMenu open={open} />}
    </>
  );
};

export default Header;
