import React, { ReactNode } from 'react';
import { Box, Typography } from '@mui/material';
import styles from '../AboutUs.module.scss';

export default function MotivationSection() {
  return (
    <Box className={styles.sectionWrap}>
      <Typography variant='h2'>Що нас рухає?</Typography>
      <Box className={styles.motivationSection}>
        <MotivationCard title='Місія'>
          Щоб більше абітурієнтів вступали до <b>українських</b> університетів, отримували якісну освіту та розвивали
          економіку нашої країни.
        </MotivationCard>
        <MotivationCard title='Візія'>
          Ми віримо, що кожна дитина — незалежно від того, де вона живе — у селі чи місті, має право на доступну й чесну
          інформацію, яка допоможе <b>свідомо</b> обрати освіту, що розкриє її потенціал.
        </MotivationCard>
      </Box>
    </Box>
  );
}

type MotivationCardProps = {
  title: string;
  children: ReactNode;
};

function MotivationCard({ title, children }: MotivationCardProps) {
  return (
    <Box className={styles.motivationCard}>
      <Typography variant='h3'>{title}</Typography>
      <Typography variant='body1' component='div'>
        {children}
      </Typography>
    </Box>
  );
}
