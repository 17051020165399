import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { useAnimated } from '../utils/useAnimated';

export const useHeaderMenu = () => {
  const location = useLocation();
  const [open, openDelayed, setOpen] = useAnimated(false);

  useEffect(() => {
    const handleEsc = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        setOpen(false);
      }
    };
    window.addEventListener('keydown', handleEsc);

    return () => {
      window.removeEventListener('keydown', handleEsc);
    };
  }, []);

  useEffect(() => {
    if (open) {
      document.body.classList.add('hidden');
    } else {
      document.body.classList.remove('hidden');
    }
  }, [open]);

  useEffect(() => {
    setOpen(false);
  }, [location]);

  return { open, openDelayed, setOpen };
};
