import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { getGoogleEndpoint } from '../../../../api/auth';
import Button from '../../../../components/ButtonV2/Button';
import AbitlyLogo from '../../../../components/Logo/AbitlyLogo';
import { langPath } from '../../../../utils/langPath';
import styles from '../../auth.module.scss';
import CrossButton from '../../components/CrossButton';

export default function LoginPage() {
  const { t } = useTranslation();

  const googleAuth = () => {
    const url = getGoogleEndpoint();
    window.location.href = url;
  };

  return (
    <div className={styles.loginPageWrapper}>
      <div className={styles.card}>
        <CrossButton className={styles.topRightCorner} />
        <AbitlyLogo to='/' className={styles.abitlyImage} />
        <Typography variant='h1'>{t('auth.login')}</Typography>
        <Typography variant='body1' className={styles.secondaryText}>
          {t('auth.login-welcome')}
        </Typography>
        <Button onClick={() => googleAuth()} icon='/images/auth/google.svg'>
          {t('auth.google-login')}
        </Button>
        <Typography variant='body1' className={styles.secondaryText}>
          {t('auth.no-account')}
        </Typography>

        <Link to={langPath('/auth/register')}>
          <Typography variant='body1'>{t('auth.login-register')}</Typography>
        </Link>
      </div>
    </div>
  );
}
