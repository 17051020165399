import { Typography } from '@mui/material';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { useUniversityOffers } from '../../../hooks/useUniversityOffers';

import { LoadMoreButton } from './components/LoadMoreBtn';
import { OfferCard } from './components/OfferTabCard';
import styles from './OffersTab.module.scss';

export const OffersTab: FC = () => {
  const { t } = useTranslation();
  const { isLoading, isFetching, hasMore, allOffers, universityId, columnizedOffers, handleLoadMore } =
    useUniversityOffers();

  if (isLoading) {
    return <div className={styles.loading}>{t('universityPage.common.loading')}</div>;
  }

  if (!allOffers || allOffers.length === 0) {
    return <div className={styles.noData}>{t('universityPage.common.noInformation')}</div>;
  }

  const { leftColumnCards, rightColumnCards } = columnizedOffers;

  return (
    <div className={styles.competitiveContainer}>
      <Typography variant='h2' marginBottom='12px'>
        {t('universityPage.offersTab.title')} 2024
      </Typography>

      <div className={styles.offersGridWrapper}>
        <div className={styles.offersGrid}>
          {leftColumnCards.map((offer) => (
            <OfferCard key={offer.offerId} {...offer} universityId={+universityId} />
          ))}
        </div>
        <div className={styles.offersGrid}>
          {rightColumnCards.map((offer) => (
            <OfferCard key={offer.offerId} {...offer} universityId={+universityId} />
          ))}
        </div>
      </div>

      <LoadMoreButton hasMore={hasMore} isLoading={isFetching} onLoadMore={handleLoadMore} />
    </div>
  );
};

export default OffersTab;
