import { basic } from '../common/constants';

import { api } from './api';
import { API } from './constants';
import { RegistrationDto, RegistrationResponse } from './schemas/auth';
import { User, UserUpdateDto } from './schemas/user';

export async function registerUser(dto: RegistrationDto): Promise<RegistrationResponse> {
  const response = await api.post(API.AUTH.REGISTRATION, dto);
  return response.data;
}

export async function getMe(): Promise<User | null> {
  const response = await api.get(API.AUTH.ME);
  return response.data;
}

export async function deleteUser(id: string): Promise<void> {
  await api.delete(`${API.AUTH.DELETE}/${id}`);
}

export function getGoogleEndpoint() {
  return `${basic.backendUrl}${API.AUTH.AUTH_GOOGLE}`;
}

export async function updateUser(user: UserUpdateDto) {
  const response = await api.patch(API.AUTH.PROFILE, user);
  return response.data;
}
