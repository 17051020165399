import { useMemo } from 'react';

import { SORTING_PARAM } from '../filters/constants';
import { sorting } from '../filters/sorting';

import { useFilterSearchParams } from './useFilterSearchParams';

export const useSortingFilters = () => {
  const { selectedFilter, onSelect, isChecked } = useFilterSearchParams(SORTING_PARAM, {
    variant: 'single',
    required: true,
    defaultValue: sorting[0].id,
  });

  const filters = useMemo(
    () =>
      sorting?.map((item) => ({
        ...item,
        isChecked: isChecked(item.id),
      })),
    [sorting, selectedFilter],
  );

  return { data: filters, selectedFilter, onSelect, isLoading: false, error: null };
};
