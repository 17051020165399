import { useQuery } from '@tanstack/react-query';

import { getQualifications } from '../../api/filters';

export const useQualifications = () => {
  const { data, isLoading, error } = useQuery({
    queryKey: ['qualifications'],
    queryFn: () => getQualifications(),
  });

  return { data, isLoading, error };
};
