import { Route, Routes, useLocation } from 'react-router-dom';

import { SEO } from '../../components/seo/Seo';
import CoefficientsPage from '../Coefficients/Coefficients';

import Articles from './pages/Articles';
import HowToChooseHighEducation from './pages/HowToChooseHighEducation';

const Blog = () => {
  const location = useLocation();

  return (
    <>
      <SEO titleKey='seo:blog.title' descriptionKey='seo:blog.description' canonicalPath='/blog' />
      <Routes location={location}>
        <Route path='/how-to-choose-high-education-in-ukraine' element={<HowToChooseHighEducation />} />
        <Route path='/nmt-coefficients' element={<CoefficientsPage />} />
        <Route path='/' element={<Articles />} />
      </Routes>
    </>
  );
};

export default Blog;
