import { ReactNode } from 'react';

import { Offer } from '../../types/offer';
import { parseSearchParams } from '../../utils/parseSearchParams';
import { BookmarkButton } from '../BookmarkButton/BookmarkButton';
import OffersButton from '../OffersButton/OffersButton';

import styles from './OfferCard.module.scss';

type OfferCardProps = {
  title: ReactNode | string;
  offers: Offer[];
  universityId: string;
};

export const OfferCard = ({ offers, title, universityId }: OfferCardProps) => {
  return (
    <>
      {offers.map((offer, index) => (
        <div key={offer.id} className={styles.card}>
          <div className={styles.titleWrap}>
            <div className={styles.title}>{title}</div>
            <BookmarkButton id={offer.id} />
          </div>
          <div className={styles.offersWrap}>
            <Property name='Прохідний бал бюджет' value={offer.minBudgetScore} />
            <Property name='Прохідний бал контракт' value={offer.minContractScore} />
            <Property name='Бюджетні місця' value={offer.maxBudgetLicence} />
            <Property name='Вартість контракту' value={offer.price} />
            <Property name='Всього місць' value={offer.maxLicense} />
            <Property name='Кількість поданих заяв' value={offer.totalApplies} />
          </div>
          <div className={styles.buttonsWrapper}>
            <OffersButton
              target='_blank'
              rel='noreferrer noopener nofollow'
              to={`https://studsearch.org/?university=${universityId}`}
              onClick={() => {
                if (!(window.location.hostname === 'localhost')) {
                  const { specialities, regions } = parseSearchParams();
                  window.dataLayer = window.dataLayer || [];
                  window.dataLayer.push({
                    event: 'click_on_send_message_to_students',
                    filter_discipline_code: specialities.join(','),
                    filter_geo_code: regions.join(','),
                    row_position: index,
                    offer_id: offer.id,
                    university_id: universityId,
                  });
                }
              }}
            >
              Написати студентам
            </OffersButton>
            <OffersButton
              emoji='/emoji/arrow-right-to-line.svg'
              to={{
                pathname: `/offers/result/${offer.id}`,
                search: location.search,
              }}
              onClick={() => {
                if (!(window.location.hostname === 'localhost')) {
                  const { specialities, regions } = parseSearchParams();
                  window.dataLayer = window.dataLayer || [];
                  window.dataLayer.push({
                    event: 'click_on_detail',
                    filter_discipline_code: specialities.join(','),
                    filter_geo_code: regions.join(','),
                    row_position: index,
                    offer_id: offer.id,
                    university_id: universityId,
                  });
                }
              }}
            >
              Детальніше
            </OffersButton>
          </div>
        </div>
      ))}
    </>
  );
};

function Property({ name, value }: { name: string; value: string | number | undefined }) {
  return (
    <div className={styles.propertyWrap}>
      <div>{name}</div>
      <div>{value ?? '–'}</div>
    </div>
  );
}
