export const formatUrl = (url: string) => {
  if (!url) return '';

  if (url.startsWith('http://') || url.startsWith('https://')) {
    if (url.startsWith('http://')) {
      return url.replace('http://', 'https://');
    }
    return url;
  }

  return `https://${url}`;
};
