export const trackTimeOnPage = (where: string) => {
  if (window.location.hostname === 'localhost') {
    return;
  }

  const startTime = Date.now();
  let isTracking = true;

  const pushTimeSpent = () => {
    if (!isTracking) return;

    isTracking = false;
    const timeSpentInSeconds = Math.round((Date.now() - startTime) / 1000);

    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: `time_on_${where}`,
      time_on_page: timeSpentInSeconds.toString(),
    });
  };

  window.addEventListener('beforeunload', pushTimeSpent);
  window.addEventListener('pagehide', pushTimeSpent);

  return {
    cleanup: () => {
      if (isTracking) {
        pushTimeSpent();
      }
      window.removeEventListener('beforeunload', pushTimeSpent);
      window.removeEventListener('pagehide', pushTimeSpent);
    },
  };
};
