import { Typography } from '@mui/material';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { useUniversityStructure } from '../../../hooks/useUniversityStructure';
import { SearchBar } from '../../SearchBar/SearchBar';
import { SortButton } from '../../SortButton/SortButton';

import { StructureList } from './components/StructureList';
import styles from './StructureTab.module.scss';

export const StructureTab: FC<{ universityShortName: string }> = ({ universityShortName }) => {
  const { t } = useTranslation();
  const {
    searchTerm,
    sortAlphabetically,
    isLoading,
    sortedFaculties,
    sortedInstitutions,
    handleSearchChange,
    toggleSorting,
  } = useUniversityStructure();

  return (
    <div className={styles.structureContainer}>
      <Typography variant='h2' marginBottom='12px'>
        {t('universityPage.structureTab.title')}
      </Typography>

      <div className={styles.controlsContainer}>
        <SearchBar searchTerm={searchTerm} onSearchChange={handleSearchChange} />
        <SortButton isActive={sortAlphabetically} onToggle={toggleSorting} />
      </div>

      <StructureList
        title={t('universityPage.structureTab.sections.faculties') + ` ${universityShortName}`}
        items={sortedFaculties}
        isLoading={isLoading}
      />

      <StructureList
        title={t('universityPage.structureTab.sections.institutions') + ` ${universityShortName}`}
        items={sortedInstitutions}
        isLoading={isLoading}
      />
    </div>
  );
};

export default StructureTab;
