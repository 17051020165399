import { useQuery } from '@tanstack/react-query';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { getUniversityStructureById } from '../../../../api/filters';
import { useToggle } from '../../../../hooks/utils/useToggle';
import { parseSlug } from '../utils/parseSlug';

export interface Speciality {
  code: string;
  name: string;
}

export interface Faculty {
  id: number | string;
  name: {
    [key: string]: string;
  };
  specialities: Speciality[];
}

export interface Institution {
  id: number | string;
  name: {
    [key: string]: string;
  };
  specialities: Speciality[];
}

export interface StructureData {
  faculties: Faculty[];
  institutions: Institution[];
}

export const useUniversityStructure = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [sortAlphabetically, toggleSorting] = useToggle(false);
  const { i18n } = useTranslation();
  const { slug } = useParams<{ slug: string }>();
  const lang = i18n.language;

  const { id } = parseSlug(slug);

  const { data, isLoading } = useQuery({
    queryKey: ['universityStructure', id],
    queryFn: () => getUniversityStructureById(id),
    enabled: !!id,
  });

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  };

  // Filter and sort faculties
  const sortedFaculties = useMemo(() => {
    if (!data) return [];

    const filtered = data.faculties.filter(
      (faculty) =>
        faculty.name[lang].toLowerCase().includes(searchTerm.toLowerCase()) ||
        faculty.specialities.some((dept) => dept.name.toLowerCase().includes(searchTerm.toLowerCase())),
    );

    return [...filtered].sort((a, b) => {
      const compareResult = a.name[lang].localeCompare(b.name[lang]);
      return sortAlphabetically ? compareResult : -compareResult;
    });
  }, [data, searchTerm, sortAlphabetically, lang]);

  // Filter and sort institutions
  const sortedInstitutions = useMemo(() => {
    if (!data) return [];

    const filtered = data.institutions.filter(
      (institution) =>
        institution.name[lang].toLowerCase().includes(searchTerm.toLowerCase()) ||
        institution.specialities.some((dept) => dept.name.toLowerCase().includes(searchTerm.toLowerCase())),
    );

    return [...filtered].sort((a, b) => {
      const compareResult = a.name[lang].localeCompare(b.name[lang]);
      return sortAlphabetically ? compareResult : -compareResult;
    });
  }, [data, searchTerm, sortAlphabetically, lang]);

  return {
    searchTerm,
    sortAlphabetically,
    isLoading,
    sortedFaculties,
    sortedInstitutions,
    handleSearchChange,
    toggleSorting,
  };
};
