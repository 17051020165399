import { keepPreviousData, useQuery } from '@tanstack/react-query';
import { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';

import { getOffersByUniversities, OffersByUniversitiesResponse } from '../../../../api/offers';
import { OfferCardData } from '../types/OfferCard';
import { parseSlug } from '../utils/parseSlug';
import { transformToOfferCards } from '../utils/transformToOfferCards';

export const useUniversityOffers = () => {
  const limit = 10;
  const [page, setPage] = useState(1);
  const [allOffers, setAllOffers] = useState<OfferCardData[]>([]);
  const { slug } = useParams<{ slug: string }>();

  const { id: universityId } = parseSlug(slug);

  const { data, isLoading, isFetching } = useQuery<OffersByUniversitiesResponse>({
    queryKey: ['offers-by-university', universityId, limit, page],
    queryFn: () =>
      getOffersByUniversities({
        universityIds: [+universityId],
        budgetOnly: false,
        order: 'ASC',
        page: Number(page),
        limit,
      }),
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    staleTime: Infinity,
    placeholderData: keepPreviousData,
  });

  useEffect(() => {
    if (data?.data && page === 0) {
      const offers = transformToOfferCards(data.data);
      setAllOffers(offers);
    } else if (data?.data) {
      setAllOffers((prev) => [...prev, ...transformToOfferCards(data.data)]);
    }
  }, [data?.data, page]);

  const hasMore = useMemo(() => {
    if (!data?.pagination) return false;
    return page < data.pagination.numOfPages;
  }, [page, data?.pagination]);

  const handleLoadMore = () => {
    if (!isFetching && hasMore) {
      setPage(page + 1);
    }
  };

  // Split offers into two columns removing duplicates
  const columnizedOffers = useMemo(() => {
    if (!allOffers || allOffers.length === 0) return { leftColumnCards: [], rightColumnCards: [] };

    const uniqueOffers = allOffers.filter(
      (item, index, arr) => arr.findIndex((e) => e?.offerId === item?.offerId) === index,
    );

    return {
      leftColumnCards: uniqueOffers.filter((_, index) => index % 2 === 0),
      rightColumnCards: uniqueOffers.filter((_, index) => index % 2 !== 0),
    };
  }, [allOffers]);

  return {
    isLoading,
    isFetching,
    hasMore,
    allOffers,
    universityId: Number(universityId),
    columnizedOffers,
    handleLoadMore,
  };
};
