import { useEffect, useState } from 'react';

import { University } from '../../../api/schemas/filters';
import { FilterItem } from '../components/FilterCard/FilterCard';

const adapted = (s: string) => (s ? s.toLowerCase().replace(/ /g, '') : '');

export function useUniversitiesSearch(data: FilterItem[]) {
  const [searchValue, setSearchValue] = useState('');
  const [filteredUniversities, setFilteredUniversities] = useState<FilterItem[]>(data);

  useEffect(() => {
    setFilteredUniversities(data);
  }, [data]);

  const search = (value: string) => {
    let filteredData = data;
    if (value.match(/^\d\d+$/)) {
      filteredData = data.filter((item) => item.id.toString().padStart(3, '0').includes(value));
    } else if (value.match(/^..+$/)) {
      filteredData = data.filter(
        (item: University) =>
          adapted(item.name).includes(adapted(value)) || adapted(item.short_name).includes(adapted(value)),
      );
    }

    setFilteredUniversities(filteredData);
    setSearchValue(value);
  };

  return { searchValue, search, filteredUniversities };
}
