import React, { PropsWithChildren, forwardRef } from 'react';
import './ChipButton.scss';

type ChipButtonProps = PropsWithChildren<
  {
    selected: boolean;
  } & React.HTMLProps<HTMLDivElement>
>;

const ChipButton = forwardRef<HTMLDivElement, ChipButtonProps>(({ selected, children, ...buttonProps }, ref) => {
  return (
    <div ref={ref} className='ChipButton' data-selected={selected} {...buttonProps}>
      {children}
    </div>
  );
});

ChipButton.displayName = 'ChipButton';

export { ChipButton };
