import React from 'react';
import styles from '../AboutUs.module.scss';
import { Box, Typography } from '@mui/material';
import CustomBreadcrumbs from '../../../components/Breadcrumbs/Breadcrumbs';
import { langPath } from '../../../utils/langPath';

export default function HeaderSection() {
  const breadcrumbs = [{ title: 'Хто ми', href: langPath('/about') }];

  return (
    <Box className={styles.sectionWrap} sx={{ paddingTop: 3, paddingBottom: 3, marginTop: 3 }}>
      <div className={styles.headerSection}>
        <div className={styles.headerSubSection}>
          <Typography variant='h1'>Хто ми?</Typography>
          <CustomBreadcrumbs items={breadcrumbs} />
        </div>
        <img src='/images/about-us/hat1.svg' alt='Graduation hat' className={styles.hat1} />
        <img src='/images/about-us/hat2.svg' alt='Graduation hat' className={styles.hat2} />
        <img src='/images/about-us/hat3.svg' alt='Graduation hat' className={styles.hat3} />
      </div>
    </Box>
  );
}
