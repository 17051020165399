import { SpecialityWithCoefficients } from '../../../api/types/specialities';

export const calculateRequiredGrade = ({
  speciality,
  ukrainian,
  mathematics,
  history,
  creativeCompetitionGrade,
  optionalSubjectCoef,
  targetCompetitiveScore,
  courseGrade,
  oldFormula,
}: {
  speciality: SpecialityWithCoefficients;
  ukrainian: number;
  mathematics: number;
  history: number;
  optionalSubjectCoef: number;
  creativeCompetitionGrade: number | undefined;
  targetCompetitiveScore: number;
  courseGrade: number | '';
  oldFormula: boolean;
}): number => {
  if (!optionalSubjectCoef) {
    return 500;
  }

  const maxCoef = oldFormula
    ? optionalSubjectCoef
    : Object.entries(speciality.coefficients).reduce((max, [subject, grade]) => {
        return subject === 'ukrainian' ||
          subject === 'mathematics' ||
          subject === 'history' ||
          subject === 'creativeCompetition'
          ? max
          : Math.max(max, grade || 0);
      }, 0);

  const k =
    (speciality.coefficients.mathematics || 0) +
    (speciality.coefficients.ukrainian || 0) +
    (speciality.coefficients.history || 0) +
    (speciality.coefficients.creativeCompetition || 0) +
    (maxCoef + optionalSubjectCoef) / 2;

  if (!k) {
    return 500;
  }

  let baseGrade =
    (speciality.coefficients.ukrainian || 0) * (ukrainian || 0) +
    (speciality.coefficients.mathematics || 0) * (mathematics || 0) +
    (speciality.coefficients.history || 0) * (history || 0);

  if (speciality.coefficients.creativeCompetition && creativeCompetitionGrade !== undefined) {
    baseGrade += (speciality.coefficients.creativeCompetition || 0) * creativeCompetitionGrade;
  }

  const parsedCourseGrade = courseGrade === '' ? 0 : Number(courseGrade) || 0;

  try {
    const requiredSubjectGrade = ((targetCompetitiveScore - parsedCourseGrade) * k - baseGrade) / optionalSubjectCoef;

    if (isNaN(requiredSubjectGrade)) {
      return 500;
    }

    return Math.max(100, requiredSubjectGrade);
  } catch {
    return 500;
  }
};
