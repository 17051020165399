import { basic } from '../common/constants';
import { FilterGroup, FilterTypes, FilterTypeToData, SubFilter } from '../pages/Offers/types/filter';
import { queryFrom } from '../utils/query';

import { api } from './api';
import { API } from './constants';
import { EducationalForm, Faculty, Qualification, Speciality, University } from './schemas/filters';
import { Region } from './types/region';
import { UniversityInfo, UniversitySpecialities, UniversityStructure } from './universities';

export const fetchFilterData = async <T extends FilterTypes>(
  filterType: T,
  url: string,
): Promise<FilterTypeToData[T]> => {
  try {
    const response = await fetch(url);
    if (!response.ok) {
      throw new Error(`Failed to fetch ${filterType} data`);
    }
    const data = await response.json();
    return data;
  } catch (error) {
    console.error(`Error fetching ${filterType} data:`, error);
    return [];
  }
};

type SpecialityApiResponse = {
  branchName: string;
  emoji: string;
  branchRelatedSpecialities: SubFilter[];
}[];

export const fetchSpecialities = async (year: number = 2024): Promise<FilterGroup[]> => {
  try {
    const response = await fetch(`${basic.backendUrl}${API.FILTERS.SPECIALITIES}?year=${year}`);
    if (!response.ok) {
      throw new Error('Failed to fetch specialities data');
    }
    const data: SpecialityApiResponse = await response.json();
    const defaultFilterState = { isChecked: false, isDisabled: false, isDefault: false };
    return data.map((item, i) => ({
      id: i,
      name: item.branchName,
      code: codeRange(item.branchRelatedSpecialities),
      isNestedOpen: false,
      isOpened: false,
      ...item,
      ...defaultFilterState,
      filters: item.branchRelatedSpecialities.map((subItem) => ({
        ...subItem,
        ...defaultFilterState,
        field_id: i,
      })),
    }));
  } catch (error) {
    console.error('Error fetching specialities data:', error);
    return [];
  }
};

const codeRange = (subfilters: SubFilter[]) => {
  const codes = subfilters.map((subItem) => Number(subItem.code));
  const asCode = (code: number) => code.toString().padStart(3, '0');
  return `${asCode(Math.min(...codes))}-${asCode(Math.max(...codes))}`;
};

export const fetchFaculties = async (universityIds?: number[]): Promise<Faculty[]> => {
  try {
    const query = queryFrom({ universityIds });
    const response = await fetch(`${basic.backendUrl}${API.FILTERS.FACULTIES}${query}`);
    if (!response.ok) {
      throw new Error('Failed to fetch faculties data');
    }
    const data: Faculty[] = await response.json();
    return data;
  } catch {
    console.error('Error fetching faculties data:');
    return [];
  }
};

export const getRegions = async (): Promise<Region[]> => {
  const response = await api.get(API.FILTERS.REGIONS);
  return response.data;
};

export const getFaculties = async (universityIds?: number[]): Promise<Faculty[]> => {
  const response = await api.get(API.FILTERS.FACULTIES, { params: { universityIds: universityIds?.join(',') } });
  return response.data;
};

export const getQualifications = async (): Promise<Qualification[]> => {
  const response = await api.get(API.FILTERS.QUALIFICATIONS);
  return response.data;
};

export const getSpecialities = async (year: number = 2024): Promise<Speciality[]> => {
  const response = await api.get(`${API.FILTERS.SPECIALITIES}?year=${year}`);
  return response.data;
};

export const getUniversities = async (regionIds?: number[]): Promise<University[]> => {
  const response = await api.get(API.FILTERS.UNIVERSITIES, { params: { regionIds: regionIds?.join(',') } });
  return response.data;
};

export const getUniversityInfoById = async (id: number | string): Promise<UniversityInfo> => {
  const response = await api.get(API.FILTERS.UNIVERSITIES + `/${id}` + API.FILTERS.UNIVERSITY_INFO);
  return response.data;
};

export const getUniversityStructureById = async (id: number | string): Promise<UniversityStructure> => {
  const response = await api.get(API.FILTERS.UNIVERSITIES + `/${id}` + API.FILTERS.UNIVERSITY_STRUCTURE);
  return response.data;
};

export const getUniversitySpecialitiesById = async (id: number | string): Promise<UniversitySpecialities[]> => {
  const response = await api.get(API.FILTERS.UNIVERSITIES + `/${id}` + API.FILTERS.UNIVERSITY_SPECIALITIES);
  return response.data;
};

export const getEducationalForms = async (): Promise<EducationalForm[]> => {
  const response = await api.get(API.FILTERS.EDUCATION_FORMS);
  return response.data;
};
