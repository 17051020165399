import { Link, LinkProps } from 'react-router-dom';

import { langPath } from '../../utils/langPath';
import AppRipples, { RippleColor } from '../ripple';
import './index.scss';

type HeaderMenuButtonProps = {
  emoji?: string;
  name: string;
} & LinkProps;

const HeaderMenuButton = ({ emoji, name, to, ...props }: HeaderMenuButtonProps) => {
  return (
    <Link className='AppHeaderMenuButton' to={langPath(to)} {...props}>
      <div className='AppHeaderMenuButton_Box'>
        <img className='AppHeaderMenuButton_Emoji' src={emoji} alt='emoji' />
      </div>
      <span className='AppHeaderMenuButton_Name'>{name}</span>
      <AppRipples color={RippleColor.primary100} />
    </Link>
  );
};

export default HeaderMenuButton;
