import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import styles from '../OffersTab.module.scss';

interface LoadMoreButtonProps {
  hasMore: boolean;
  isLoading: boolean;
  onLoadMore: () => void;
}

export const LoadMoreButton: FC<LoadMoreButtonProps> = ({ hasMore, isLoading, onLoadMore }) => {
  const { t } = useTranslation();

  if (!hasMore) return null;

  return (
    <div className={styles.loadMoreContainer}>
      <button className={styles.loadMoreButton} onClick={onLoadMore} disabled={isLoading}>
        {isLoading ? t('universityPage.common.loading') : t('universityPage.offersTab.loadMore.button')}
      </button>
    </div>
  );
};
