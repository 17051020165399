import { Box, Typography } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import Button from '../../../components/ButtonV2/Button';
import { ArrowIcon, ArrowIconDirection } from '../../Offers/icons/ArrowIcon';
import styles from '../profile.module.scss';

export default function Header() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const form = useFormContext();

  return (
    <Box className={styles.header}>
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
        <Box sx={{ cursor: 'pointer', display: 'flex', alignItems: 'center' }} onClick={() => navigate(-1)}>
          <ArrowIcon direction={ArrowIconDirection.LEFT} color='#F59D33' />
        </Box>
        <Typography variant='h3'>{t('profile.title')}</Typography>
      </Box>
      <Button disabled={form.formState.isSubmitting} variant='text' text='body2' type='submit'>
        {t('profile.buttons.save')}
      </Button>
    </Box>
  );
}
