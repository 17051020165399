import { Path, To } from 'react-router-dom';

import i18n, { LANGUAGES } from '../common/i18n';

export const langPath = <T extends string | To>(to: T): T => {
  const currentLang = i18n.language;

  if (typeof to === 'string') {
    if (!to) return `/${currentLang}` as T;

    if (to.startsWith('http') || to.startsWith('#')) return to;

    const hasLangPrefix = Object.values(LANGUAGES).some((lang) => to.startsWith(`/${lang}/`) || to === `/${lang}`);

    if (hasLangPrefix) return to;

    return `/${currentLang}${to.startsWith('/') ? to : `/${to}`}` as T;
  }

  if (to && typeof to === 'object') {
    const pathname = (to as Partial<Path>).pathname || '';

    if (!pathname)
      return {
        ...(to as object),
        pathname: `/${currentLang}`,
      } as T;

    if (pathname.startsWith('http')) return to;

    const hasLangPrefix = Object.values(LANGUAGES).some(
      (lang) => pathname.startsWith(`/${lang}/`) || pathname === `/${lang}`,
    );

    if (hasLangPrefix) return to;

    return {
      ...(to as object),
      pathname: `/${currentLang}${pathname.startsWith('/') ? pathname : `/${pathname}`}`,
    } as T;
  }

  return to;
};
