import { useQuery } from '@tanstack/react-query';
import { useMemo } from 'react';

import { getRegions } from '../../api/filters';

export const useRegions = () => {
  const { data, isLoading, error } = useQuery({
    queryKey: ['regions'],
    queryFn: () => getRegions(),
  });

  return { data, isLoading, error };
};

export const useRegionOptions = () => {
  const { data = [], isLoading, error } = useRegions();

  const regionOptions = useMemo(() => {
    return data?.map((region) => ({
      id: region.id,
      label: region.name,
    }));
  }, [data]);

  return { regionOptions, isLoading, error };
};
