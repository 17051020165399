import React from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import { LANGUAGES } from '../../common/i18n';

const DOMAIN = 'https://abitly.org';

const removeLanguagePrefix = (path: string): string => {
  if (!path) return '/';

  const langRegex = new RegExp(`^/(${Object.values(LANGUAGES).join('|')})(/?|/.*)$`);
  const match = path.match(langRegex);

  if (match) {
    return match[2] || '/';
  }

  return path;
};

type SEOProps = {
  titleKey?: string;
  descriptionKey?: string;
  keywordsKey?: string;
  title?: string;
  description?: string;
  keywords?: string;
  canonicalPath?: string;
  image?: string;
  rectangleImage?: string;
  squareImage?: string;
  noindex?: boolean;
  locale?: string;
  type?: string;
  siteName?: string;
};

export const SEO: React.FC<SEOProps> = ({
  titleKey,
  descriptionKey,
  keywordsKey,
  title,
  description,
  keywords,
  canonicalPath,
  rectangleImage,
  squareImage,
  noindex = false,
  locale,
  type = 'website',
  siteName = 'Abitly',
}) => {
  const { t, i18n } = useTranslation(['seo', 'translation']);
  const location = useLocation();
  const currentLanguage = locale || i18n.language;

  const pathWithoutLang = removeLanguagePrefix(canonicalPath || location.pathname);

  const canonicalUrl = `${DOMAIN}/${currentLanguage}${pathWithoutLang}`;

  const pageTitle = titleKey ? t(titleKey) : title || t('seo:defaultTitle', 'Abitly');
  const pageDescription = descriptionKey
    ? t(descriptionKey)
    : description || t('seo:defaultDescription', 'Студенти готові відповісти на важливі питання абітурієнтів');
  const pageKeywords = keywordsKey ? t(keywordsKey) : keywords || t('seo:defaultKeywords', '');

  const defaultImageUrl = `${DOMAIN}${'/open-graph.jpg'}`;

  const rectangleImageUrl = rectangleImage
    ? rectangleImage.startsWith('http')
      ? rectangleImage
      : `${DOMAIN}${rectangleImage}`
    : defaultImageUrl;

  const squareImageUrl = squareImage
    ? squareImage.startsWith('http')
      ? squareImage
      : `${DOMAIN}${squareImage}`
    : defaultImageUrl;

  return (
    <Helmet>
      <title>{pageTitle}</title>
      <meta name='title' content={pageTitle} />
      <meta name='description' content={pageDescription} />
      {pageKeywords && <meta name='keywords' content={pageKeywords} />}

      <link rel='canonical' href={canonicalUrl} />

      <html lang={currentLanguage} />
      <meta httpEquiv='content-language' content={currentLanguage} />

      {Object.values(LANGUAGES).map((lang) => (
        <link key={lang} rel='alternate' hrefLang={lang} href={`${DOMAIN}/${lang}${pathWithoutLang}`} />
      ))}

      <link rel='alternate' hrefLang='x-default' href={`${DOMAIN}/${LANGUAGES.uk}${pathWithoutLang}`} />

      {/* Robots control */}
      {noindex ? <meta name='robots' content='noindex, nofollow' /> : <meta name='robots' content='index, follow' />}

      {/* Open Graph / Facebook - Uses rectangle image */}
      <meta property='og:title' content={pageTitle} />
      <meta property='og:description' content={pageDescription} />
      <meta property='og:url' content={canonicalUrl} />
      <meta property='og:type' content={type} />
      <meta property='og:site_name' content={siteName} />
      <meta property='og:locale' content={currentLanguage} />
      <meta property='og:image' content={rectangleImageUrl} />
      {rectangleImage && (
        <>
          <meta property='og:image:width' content='1200' />
          <meta property='og:image:height' content='630' />
          <meta property='og:image:type' content='image/jpeg' />
        </>
      )}

      {squareImage && (
        <>
          <meta property='og:image' content={squareImageUrl} />
          <meta property='og:image:width' content='1080' />
          <meta property='og:image:height' content='1080' />
          <meta property='og:image:type' content='image/jpeg' />
        </>
      )}

      {/* Twitter - Uses rectangle image for summary_large_image */}
      <meta name='twitter:card' content='summary_large_image' />
      <meta name='twitter:title' content={pageTitle} />
      <meta name='twitter:description' content={pageDescription} />
      <meta name='twitter:url' content={canonicalUrl} />
      <meta name='twitter:image' content={rectangleImageUrl} />

      {/* Additional Twitter square image format for summary card */}
      {squareImage && (
        <>
          <meta name='twitter:card' content='summary' />
          <meta name='twitter:image' content={squareImageUrl} />
        </>
      )}
    </Helmet>
  );
};
