import { z } from 'zod';

import { setupSchema } from './setup-form';

export const otherUserFormSchema = z.object({
  about: z.string(),
});

export const otherUserSchema = z.object({
  setupData: setupSchema,
  otherData: otherUserFormSchema.optional(),
});

export type OtherUserFormSchema = z.infer<typeof otherUserFormSchema>;
export type OtherUserSchema = z.infer<typeof otherUserSchema>;
