import { Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import Button from '../../../../components/button';
import { CitiesList } from '../../components/CitiesList/CitiesList';
import { SpecialitiesList } from '../../components/SpecialitiesList/SpecialitiesList';
import { useFilterButton } from '../../hooks/useFilterButton';
import { parseSearchParams } from '../../utils/parseSearchParams';
import './OfferWelcomePage.scss';

export const OfferWelcomePage = () => {
  const { t } = useTranslation();
  const { enabled } = useFilterButton();

  return (
    <Grid item container lg={6} md={6} sm={12} textAlign='center' p='0px 16px' mt='48px'>
      <Typography
        m='auto'
        variant='h1'
        maxWidth='324px'
        textAlign='center'
        justifyContent='center'
        flexWrap='wrap'
        display='flex'
      >
        {t('translation:home.title')}
      </Typography>
      <Typography variant='body2' py='10px' mb='16px'>
        {t('translation:home.subTitle')}
      </Typography>
      <SpecialitiesList />
      <CitiesList />

      <Button
        disabled={!enabled}
        to='/offers/result'
        onClick={() => {
          if (!(window.location.hostname === 'localhost') && enabled) {
            const { specialities, regions } = parseSearchParams();

            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
              event: 'search offers click',
              filter_discipline_code: specialities.join(','),
              filter_geo_code: regions.join(','),
            });
          }
        }}
      >
        {t('translation:home.button')}
      </Button>
    </Grid>
  );
};
