import { Grid, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { getOverrides, MuiMarkdown } from 'mui-markdown';
import { useTranslation } from 'react-i18next';

import { LANGUAGES } from '../../../common/i18n';
import { how_to_choose_high_education_en } from '../articles/en/how_to_choose_high_education';
import { how_to_choose_high_education_uk } from '../articles/uk/how_to_choose_high_education';

const useStyles = makeStyles(() => ({
  noScrollbar: {
    overflow: 'auto',
    // width: '50%',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
    scrollbarWidth: 'none',
    msOverflowStyle: 'none',
  },
}));
const HowToChooseHighEducation = () => {
  const classes = useStyles();

  const { i18n } = useTranslation();
  const markdown =
    i18n.language === LANGUAGES.uk
      ? how_to_choose_high_education_uk.markdown
      : how_to_choose_high_education_en.markdown;

  return (
    <Grid container flexDirection='row' justifyContent='center' my='20px' alignItems='safe center' overflow='auto'>
      <Grid item container lg={8} md={8} sm={8} py='20px' minHeight='100vh' className={classes.noScrollbar}>
        <MuiMarkdown
          overrides={{
            ...getOverrides({}), // This will keep the other default overrides.
            h1: {
              props: {
                style: { margin: '15px' },
              },
            },
            p: {
              component: Typography,
              props: {
                variant: 'body2',
                style: { color: 'black', margin: '15px' },
              },
            },
            img: {
              props: {
                style: { width: '100%' },
              },
            },
          }}
        >
          {markdown}
        </MuiMarkdown>
      </Grid>
    </Grid>
  );
};

export default HowToChooseHighEducation;
