import { useEffect, useMemo, useState } from 'react';

import { getOffer } from '../../../../api/offers';
import { DetailedOffer, Offer } from '../../types/offer';
import { CodeChip } from '../CodeChip/CodeChip';
import { OfferCard } from '../OfferCard/OfferCard';

import styles from './SavedOfferCard.module.scss';

export const SavedOfferCard = ({ offerId }: { offerId: number }) => {
  const [offer, setOffer] = useState<DetailedOffer | null>();
  const offerData = useMemo(() => {
    return [
      {
        id: offer?.id,
        minBudgetScore: offer?.minBudgetScore,
        maxBudgetLicence: offer?.maxBudgetLicence,
        minContractScore: offer?.minContractScore,
        maxLicense: offer?.maxLicense,
        price: offer?.price,
        totalApplies: offer?.totalApplies,
      } as Offer,
    ];
  }, [offer]);

  useEffect(() => {
    async function fetchOffer() {
      const result = await getOffer(offerId);
      setOffer(result);
    }

    fetchOffer();
  }, []);

  return (
    <div className={styles.cardWrap}>
      <OfferCard title={<Title offer={offer} />} offers={offerData} universityId={offer?.university.id || ''} />
    </div>
  );
};

function Title({ offer }: { offer: DetailedOffer | null | undefined }) {
  return (
    <div className={styles.titleWrap}>
      <div className={styles.speciality}>
        <CodeChip code={offer?.speciality.code} size='big' /> {offer?.speciality.name}
      </div>
      <div>🏛 {offer?.university.name}</div>
    </div>
  );
}
