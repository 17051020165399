import { Link, useNavigate } from 'react-router-dom';

import { langPath } from '../../../../utils/langPath';
import { ArrowIcon, ArrowIconDirection } from '../../icons/ArrowIcon';

import styles from './Header.module.scss';

export const OffersHeader = () => {
  const navigate = useNavigate();

  const handleClearingOfFilters = () => {
    navigate({
      pathname: langPath('/offers'),
      search: '',
    });
  };

  return (
    <div className={styles.wrap}>
      <div className={styles.wrapBlock}>
        <Link to={langPath({ pathname: '/', search: location.search })}>
          <ArrowIcon direction={ArrowIconDirection.LEFT} className={styles.arrowLeftIcon} color='#A7A7A7' />
        </Link>
        <h1 className={styles.title}>Фільтри</h1>
      </div>
      <div onClick={handleClearingOfFilters}>
        <p className={styles.clearButton}>{'Очистити все'}</p>
      </div>
    </div>
  );
};
