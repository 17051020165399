import { Typography, TypographyProps } from '@mui/material';
import cx from 'classnames';
import { ButtonHTMLAttributes, forwardRef } from 'react';
import './Button.scss';

type ButtonProps = ButtonHTMLAttributes<HTMLButtonElement> & {
  variant?: 'primary' | 'outline' | 'text';
  color?: 'primary' | 'error';
  size?: 'default' | 'slim';
  text?: TypographyProps['variant'];
  icon?: string;
};

const styles = {
  primary: 'ButtonV2__primary',
  outline: 'ButtonV2__outline',
  text: 'ButtonV2__text',
};

const colorStyles = {
  primary: '',
  error: 'error',
};

const sizeStyles = {
  default: '',
  slim: 'slim',
};

const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  (
    { children, variant = 'primary', text = 'body2', size = 'default', color = 'primary', className, ...props },
    ref,
  ) => {
    return (
      <button
        ref={ref}
        className={cx('ButtonV2', styles[variant], sizeStyles[size], colorStyles[color], className)}
        {...props}
      >
        {props.icon && <img className='icon' src={props.icon} alt={children?.toString() ?? 'Button icon'} />}
        <Typography variant={text} component='span'>
          {children}
        </Typography>
      </button>
    );
  },
);

Button.displayName = 'Button';

export default Button;
