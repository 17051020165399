import { Box, IconButton } from '@mui/material';
import Typography from '@mui/material/Typography';

import { settings } from '../../../theme';
import styles from '../AboutUs.module.scss';
import { Participant } from '../participants';

export function PersonCard(participant: Participant) {
  const { firstName, lastName, role, description, img, links } = participant;
  const fullName = `${firstName} ${lastName}`;

  return (
    <Box className={styles.participantCard}>
      <img className={styles.participantImage} src={`/images/participants/${img}`} alt={`${fullName} - ${role}`} />
      <Box display='flex' flexDirection='column' gap='12px'>
        <Box display='flex' flexDirection='column' gap='4px'>
          <Typography variant='h3'>{fullName}</Typography>
          <Typography variant='body2' color={settings.darkest60}>
            {role}
          </Typography>
        </Box>
        <Typography variant='body1' color={settings.darkest60}>
          {description}
        </Typography>
        <Box sx={{ opacity: 0.5 }}>
          <IconButton href={links.linkedIn} target='_blank' rel='noopener noreferrer'>
            <img width='20px' src='/emoji/linkedin.svg' alt={`LinkedIn of ${fullName}`} />
          </IconButton>
          {links.instagram && (
            <IconButton href={links.instagram} target='_blank' rel='noopener noreferrer'>
              <img width='20px' src='/emoji/instagram.svg' alt={`Instagram of ${fullName}`} />
            </IconButton>
          )}
          <IconButton href={links.telegram} target='_blank' rel='noopener noreferrer'>
            <img width='20px' src='/emoji/telegram.svg' alt={`Telegram of ${fullName}`} />
          </IconButton>
        </Box>
      </Box>
    </Box>
  );
}
