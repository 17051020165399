import { zodResolver } from '@hookform/resolvers/zod';
import { FormProvider, useForm, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import Button from '../../../../../components/ButtonV2/Button';
import FormAutocomplete from '../../../../../components/mui-form/FormAutocomplete';
import FormSelect from '../../../../../components/mui-form/FormSelect';
import { useFacultyOptions } from '../../../../../hooks/filters/useFaculties';
import { useSpecialityOptions } from '../../../../../hooks/filters/useSpecialities';
import { useUniversityOptions } from '../../../../../hooks/filters/useUniversities';
import { useYearOptions } from '../../../../../hooks/filters/useYearOptions';
import Label from '../../../components/Label';
import Section from '../../../components/Section';
import ValidationError from '../../../components/ValidationError';
import { SetupSchema } from '../../../schema/setup-form';
import { studentFormSchema, StudentFormSchema, StudentSchema } from '../../../schema/student-form';
import { useAuthForm } from '../../../state/AuthFormContext';

export default function StudentForm() {
  const authStore = useAuthForm();
  const { t } = useTranslation();

  const form = useForm<StudentFormSchema>({
    resolver: zodResolver(studentFormSchema),
    defaultValues: (authStore.formData?.data as StudentSchema).studentData || {},
  });

  const { yearOptions } = useYearOptions({
    startYear: 2008,
    endYear: new Date().getFullYear() + 1,
  });

  const { universityOptions } = useUniversityOptions();

  const selectedUniversity = useWatch({ control: form.control, name: 'university' });
  const { facultyOptions } = useFacultyOptions([selectedUniversity]);

  const { specialityOptions } = useSpecialityOptions();

  const onSubmit = (data: StudentFormSchema) => {
    const setupData = authStore.formData?.data as SetupSchema;

    authStore.appendFormData({
      type: 'STUDENT',
      data: {
        setupData,
        studentData: data,
      },
    });

    authStore.nextStep();
  };

  return (
    <FormProvider {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)}>
        <Section>
          <Label required>{t('auth.registration-flow.entrance-year.label')}</Label>
          <FormSelect
            name='entranceYear'
            label={t('auth.registration-flow.entrance-year.label')}
            options={yearOptions}
          />
          <ValidationError error={form.formState.errors.entranceYear} />
        </Section>
        <Section>
          <Label>{t('auth.registration-flow.preferences.student-universities.label')}</Label>
          <FormAutocomplete
            name='university'
            options={universityOptions}
            label={t('auth.registration-flow.preferences.student-universities.short-label')}
            placeholder={t('auth.registration-flow.preferences.student-universities.placeholder')}
          />
          <ValidationError error={form.formState.errors.university} />
        </Section>
        <Section>
          <Label required>{t('auth.registration-flow.preferences.student-faculties.label')}</Label>
          <FormAutocomplete
            name='faculty'
            options={facultyOptions}
            label={t('auth.registration-flow.preferences.student-faculties.short-label')}
            placeholder={t('auth.registration-flow.preferences.student-faculties.placeholder')}
          />
          <ValidationError error={form.formState.errors.faculty} />
        </Section>
        <Section>
          <Label required>{t('auth.registration-flow.preferences.student-specialities.label')}</Label>
          <FormAutocomplete
            name='speciality'
            options={specialityOptions}
            label={t('auth.registration-flow.preferences.student-specialities.short-label')}
            placeholder={t('auth.registration-flow.preferences.student-specialities.placeholder')}
          />
          <ValidationError error={form.formState.errors.speciality} />
        </Section>
        <Button disabled={!form.formState.isValid} type='submit'>
          {t('auth.registration-flow.buttons.complete')}
        </Button>
      </form>
    </FormProvider>
  );
}
