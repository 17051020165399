import { useToggle } from '../../../../hooks/utils/useToggle';
import { SpecialityFilter } from '../../hooks/useSpecialitiesFilters';
import { ArrowIcon, ArrowIconDirection } from '../../icons/ArrowIcon';
import { FilterCheckbox } from '../FilterCheckbox/FilterCheckbox';
import { SubTitle } from '../SubTitle/SubTitle';

import { SpecialityCheckbox } from './Checkbox';
import styles from './SpecialityFilterCard.module.scss';

type SpecialityDropdownProps = {
  filterGroup: SpecialityFilter;
  opened?: boolean;
  onSelect: (id: number | string) => void;
  onSelectMultiple: (ids: number[], value: boolean) => void;
};

export const SpecialityDropdown = ({ filterGroup, opened, onSelect, onSelectMultiple }: SpecialityDropdownProps) => {
  const [open, toggleOpen] = useToggle(false);

  const isOpened = opened ?? open;

  return (
    <>
      <div className={styles.wrapDropdownBlock}>
        <div className={styles.checkboxBlock}>
          <SpecialityCheckbox filterGroup={filterGroup} onSelectMultiple={onSelectMultiple} />
          <SubTitle onClick={toggleOpen}>
            <div className={styles.dropdownTitleWrap}>
              <div className={styles.icon}>{filterGroup.emoji}</div>
              <div className={styles.dropdownTextWrap}>
                <span className={styles.dropdownName}>{filterGroup.branchName}</span>
                <span className={styles.dropdownCode}>{filterGroup.code}</span>
              </div>
            </div>
          </SubTitle>
        </div>
        <ArrowIcon
          direction={isOpened ? ArrowIconDirection.UP : ArrowIconDirection.DOWN}
          color={isOpened ? '#F59D33' : '#3C474E'}
          onClick={toggleOpen}
        />
      </div>
      <div className={styles.filters}>
        {isOpened &&
          filterGroup.branchRelatedSpecialities.map((filter) => (
            <FilterCheckbox
              key={filter.id}
              item={filter}
              onSelect={onSelect}
              choice='multiple'
              code={filter.code}
              size='small'
            />
          ))}
      </div>
    </>
  );
};
