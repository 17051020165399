import { Typography } from '@mui/material';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { Link } from '../../../../components/Link/Link';
import styles from '../../auth.module.scss';
import RegisterBase from '../../components/RegisterBase';
import Section from '../../components/Section';
import { useAuthForm } from '../../state/AuthFormContext';

export default function RegisterCompleted() {
  const { completeRegistration } = useAuthForm();
  const { t } = useTranslation();

  useEffect(() => {
    completeRegistration();
  }, []);

  return (
    <RegisterBase disableStep>
      <div className={styles.registerCompletedPageWrapper}>
        <img className={styles.heartImage} src='/images/auth/heart.png' alt='Heart' />
        <Section>
          <Typography variant='h2'>{t('auth.registration-completed.thank-you')}</Typography>
          <Typography variant='body1'>{t('auth.registration-completed.description')}</Typography>
        </Section>

        <Link to={'/offers/result'} variant='button'>
          {t('auth.registration-completed.view-offers')}
        </Link>
        <Link to={'/'} variant='button-outline'>
          {t('auth.registration-completed.to-home')}
        </Link>
      </div>
    </RegisterBase>
  );
}
