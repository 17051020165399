import { ReactNode } from 'react';
import { Controller, useFormContext, useWatch } from 'react-hook-form';

import { FilterCard, FilterItem } from '../../pages/Offers/components/FilterCard/FilterCard';

type FiltersInputProps = {
  title?: ReactNode;
  name: string;
  data: FilterItem[];
};

export const FiltersInput = ({ title, name, data }: FiltersInputProps) => {
  const { control, setValue } = useFormContext();

  const ids = useWatch({ control, name: name }) || [];

  const adaptedSpecialities = data?.map((item) => ({
    ...item,
    isChecked: ids.includes(item.id),
  }));

  const onSelect = (id: number) => {
    if (ids.includes(id)) {
      setValue(
        name,
        ids.filter((filterId: number) => filterId !== id),
      );
    } else {
      setValue(name, [...ids, id]);
    }
  };

  return (
    <Controller
      name={name}
      control={control}
      render={() => <FilterCard title={title} data={adaptedSpecialities} selectedFilters={ids} onSelect={onSelect} />}
    />
  );
};
