import { Box, Typography } from '@mui/material';
import styles from '../AboutUs.module.scss';
import AbitlyLogo from '../../../components/Logo/AbitlyLogo';
import { ReactNode } from 'react';

export default function AbitlySection() {
  return (
    <Box className={styles.sectionWrap}>
      <div className={styles.abitlySection}>
        <img src='/images/about-us/iphone-preview.png' />
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
          <div className={styles.logoContainer}>
            <AbitlyLogo />
          </div>
          <Typography variant='body1'>
            — волонтерський проєкт, створений студентами для абітурієнтів. Ми допомагаємо зрозуміло та без стресу знайти
            найкращу освіту в Україні й обрати спеціальність, яка справді підходить.
          </Typography>
          <Note>
            📌 Нас об’єднало бажання зробити вступ прозорим та доступним. І ми віримо, що кожен абітурієнт заслуговує на
            якісну освіту.
          </Note>
        </Box>
      </div>
    </Box>
  );
}

function Note({ children }: { children: ReactNode }) {
  return (
    <Typography variant='body1' component='div' className={styles.note}>
      {children}
    </Typography>
  );
}
