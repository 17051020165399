import { Box, Typography } from '@mui/material';
import styles from '../AboutUs.module.scss';
import { PARTICIPANTS } from '../participants';
import { PersonCard } from './PersonCard';

export default function ParticipantsSection() {
  return (
    <Box className={styles.sectionWrap}>
      <Typography variant='h2'>Наша команда</Typography>
      <Typography sx={{ maxWidth: 550 }}>
        Ми — студенти, розробники, дизайнери й просто ентузіасти, які хочуть змінити освітню систему на краще.
      </Typography>
      <div className={styles.participantsGrid}>
        {PARTICIPANTS.map((data) => (
          <PersonCard key={`${data.firstName}-${data.lastName}`} {...data} />
        ))}
      </div>
    </Box>
  );
}
