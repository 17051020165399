import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { PartialSubjects, SpecialityWithCoefficients } from '../../../../../../api/types/specialities';
import { gradesValidator } from '../../../../utils/gradesValidator';
import styles from '../../SubjectInputs.module.scss';
import { NumberInput } from '../NumberInput/NumberInput';

type OptionalSubjectInputsProps = {
  speciality: SpecialityWithCoefficients | null;
  setOptionalGrades: (grades: PartialSubjects) => void;
};

export const OptionalSubjectInputs: React.FC<OptionalSubjectInputsProps> = ({
  speciality,
  setOptionalGrades,
}: OptionalSubjectInputsProps) => {
  const { t } = useTranslation();

  const [biology, setBiology] = useState<number | ''>('');
  const [chemistry, setChemistry] = useState<number | ''>('');
  const [physics, setPhysics] = useState<number | ''>('');
  const [geography, setGeography] = useState<number | ''>('');
  const [ukrainianLiterature, setUkrainianLiterature] = useState<number | ''>('');
  const [foreignLanguage, setForeignLanguage] = useState<number | ''>('');

  useEffect(() => {
    setOptionalGrades({
      biology: biology ? +biology : undefined,
      chemistry: chemistry ? +chemistry : undefined,
      physics: physics ? +physics : undefined,
      geography: geography ? +geography : undefined,
      ukrainianLiterature: ukrainianLiterature ? +ukrainianLiterature : undefined,
      foreignLanguage: foreignLanguage ? +foreignLanguage : undefined,
    });
  }, [biology, chemistry, physics, geography, ukrainianLiterature, foreignLanguage]);

  return (
    <div className={styles.optionalSubjectsWrapper}>
      <p className='h2'>{t('calculator.inputs.titles.optional')}</p>
      <p>{t('calculator.inputs.descriptions.optional')}</p>
      <div className={styles.optionalSubjects}>
        <div className={styles.inputWrapper}>
          <NumberInput
            id='biology'
            validator={gradesValidator}
            label={t('calculator.subjects.nominative.biology')}
            value={biology}
            placeholder={t('calculator.subjects.nominative.biology')}
            onChange={setBiology}
          />
          {speciality && <p>х&nbsp;{speciality.coefficients.biology}</p>}
        </div>
        <div className={styles.inputWrapper}>
          <NumberInput
            id='chemistry'
            validator={gradesValidator}
            label={t('calculator.subjects.nominative.chemistry')}
            value={chemistry}
            placeholder={t('calculator.subjects.nominative.chemistry')}
            onChange={setChemistry}
          />
          {speciality && <p>х&nbsp;{speciality.coefficients.chemistry}</p>}
        </div>
        <div className={styles.inputWrapper}>
          <NumberInput
            id='physics'
            validator={gradesValidator}
            label={t('calculator.subjects.nominative.physics')}
            value={physics}
            placeholder={t('calculator.subjects.nominative.physics')}
            onChange={setPhysics}
          />
          {speciality && <p>х&nbsp;{speciality.coefficients.physics}</p>}
        </div>
        <div className={styles.inputWrapper}>
          <NumberInput
            id='geography'
            validator={gradesValidator}
            label={t('calculator.subjects.nominative.geography')}
            value={geography}
            placeholder={t('calculator.subjects.nominative.geography')}
            onChange={setGeography}
          />
          {speciality && <p>х&nbsp;{speciality.coefficients.geography}</p>}
        </div>
        <div className={styles.inputWrapper}>
          <NumberInput
            id='ukrainianLiterature'
            validator={gradesValidator}
            label={t('calculator.subjects.nominative.ukrainianLiterature')}
            value={ukrainianLiterature}
            placeholder={t('calculator.subjects.nominative.ukrainianLiterature')}
            onChange={setUkrainianLiterature}
          />
          {speciality && <p>х&nbsp;{speciality.coefficients.ukrainianLiterature}</p>}
        </div>
        <div className={styles.inputWrapper}>
          <NumberInput
            id='foreignLanguage'
            validator={gradesValidator}
            label={t('calculator.subjects.nominative.foreignLanguage')}
            value={foreignLanguage}
            placeholder={t('calculator.subjects.nominative.foreignLanguage')}
            onChange={setForeignLanguage}
          />
          {speciality && <p>х&nbsp;{speciality.coefficients.foreignLanguage}</p>}
        </div>
      </div>
    </div>
  );
};
