import { useMediaQuery } from '@mui/material';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { fetchSpecialityById } from '../../api/calculator';
import { PartialSubjects, SpecialityWithCoefficients } from '../../api/types/specialities';
import { SEO } from '../../components/seo/Seo';

import styles from './CalculatorPage.module.scss';
import { CalculatedGrade } from './components/CalculatedGrade/CalculatedGrade';
import { GradesGraphsWrapper } from './components/GradesGraph/GradesGraphsWrapper';
import { RulesOfCalc } from './components/RulesOfCalc/RulesOfCalc';
import { SubjectInputs } from './components/SubjectInputs/SubjectInputs';
import { TelegramButtonLink, TelegramButtonLinkMobile } from './components/TelegramLinkBanner/TelegramLinkBanner';
const CalculatorPage = () => {
  const { t } = useTranslation();

  const [specialityId, setSpecialityId] = useState<number | null>(null);
  const [speciality, setSpeciality] = useState<SpecialityWithCoefficients | null>(null);
  const [grades, setGrades] = useState<PartialSubjects | null>(null);
  const [courseGrade, setCourseGrade] = useState<number | ''>('');

  useEffect(() => {
    if (specialityId === null) return;
    const fetchSpeciality = async () => {
      return await fetchSpecialityById(specialityId);
    };
    fetchSpeciality()
      .then((data) => {
        setSpeciality(data);

        if (!(window.location.hostname === 'localhost')) {
          window.dataLayer = window.dataLayer || [];
          window.dataLayer.push({
            event: 'Calculator Selector Speciality',
            speciality: data.name,
            specialityCode: data.code,
          });
        }
      })
      .catch((error) => console.error(error));
  }, [specialityId]);

  const isMobile = useMediaQuery('(max-width: 767px)');

  return (
    <>
      <SEO
        titleKey='seo:calculator.title'
        descriptionKey='seo:calculator.description'
        canonicalPath='/nmt-calculator'
        rectangleImage='/images/calculator/OpenGraph.jpg'
        squareImage='/images/calculator/OpenGraph1200x1200.jpg'
      />
      <div className={styles.container}>
        <div className={styles.containerRight}>
          <h1 className={styles.title}>{t('calculator.title')}</h1>
          <div className={styles.textWrapper}>
            <p>{t('calculator.willHelpYou')}</p>
            <ul className={styles.list}>
              <li>
                {t('calculator.list.first.text')}
                <span>{t('calculator.list.first.textBold')}</span>
              </li>
              <li>
                {t('calculator.list.second.text1')}
                <span>{t('calculator.list.second.textBold')}</span>
                {t('calculator.list.second.text2')}
              </li>
            </ul>
          </div>

          <SubjectInputs
            speciality={speciality}
            setSpecialityId={setSpecialityId}
            setGrades={setGrades}
            setCourseGrade={setCourseGrade}
            courseGrade={courseGrade}
          />

          {speciality && grades && (
            <CalculatedGrade speciality={speciality} grades={grades} courseGrade={courseGrade || 0} />
          )}

          <Link
            to='https://t.me/abitlyorg'
            target='_blank'
            rel='noopener nofollow noreferrer'
            onClick={() => {
              if (!(window.location.hostname === 'localhost')) {
                window.dataLayer = window.dataLayer || [];
                window.dataLayer.push({
                  event: 'click_on_telegram',
                  element_position: 'calculator page',
                });
              }
            }}
          >
            {isMobile ? <TelegramButtonLinkMobile /> : <TelegramButtonLink />}
          </Link>

          <GradesGraphsWrapper speciality={speciality} grades={grades} courseGrade={courseGrade} />
          <RulesOfCalc />
        </div>
      </div>
    </>
  );
};

export default CalculatorPage;
