import { Box, Typography } from '@mui/material';
import { ReactNode } from 'react';

import styles from '../profile.module.scss';
export default function InputGroup({ title, children }: { title?: string; children: ReactNode }) {
  return (
    <Box className={styles.inputGroupWrapper}>
      {title && <Typography variant='button'>{title}</Typography>}
      {children}
    </Box>
  );
}
