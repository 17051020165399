import { Typography } from '@mui/material';

import styles from '../auth.module.scss';

export default function Label({ children, required }: { children: string; required?: boolean }) {
  return (
    <Typography variant='h4' marginRight='20px'>
      {children}
      {required && <span className={styles.required}>*</span>}
    </Typography>
  );
}
