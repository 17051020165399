import { createTheme } from '@mui/material/styles';

export const settings = {
  fontFamilyMariupol: "'Mariupol', sans-serif",
  fontFamilyInter: "'Inter', sans-serif",

  white: '#FFFFFF',

  darkest100: '#0B1922',
  darkest80: '#3C474E',
  darkest60: '#6D757A',
  darkest40: '#9DA3A7',
  darkest20: '#CED1D3',
  darkest10: '#E7E8E9',
  darkest5: '#F3F3F4',

  primary100: '#F59D33',
  primary60: '#F9C485',
  primary40: '#FBD8AD',
  primary20: '#FDEBD6',
  primary15: '#FDF0E0',
  primary10: '#FEF5EB',

  secondaryBlue100: '#22DDE9',
  secondaryPink100: '#FF3950',
  secondaryPurple100: '#9912F5',
};

export const typography = {
  h1: {
    fontFamily: settings.fontFamilyMariupol,
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '40px',
    lineHeight: '109%',
    color: settings.darkest100,
  },
  h2: {
    fontFamily: settings.fontFamilyMariupol,
    fontStyle: 'normal',
    fontWeight: 'bold',
    color: settings.darkest100,
    fontSize: '30px',
    lineHeight: '100%',
  },
  h3: {
    fontFamily: settings.fontFamilyMariupol,
    fontStyle: 'normal',
    fontWeight: 'bold',
    color: settings.darkest100,
    fontSize: '24px',
    lineHeight: '116%',
  },
  h4: {
    fontFamily: settings.fontFamilyMariupol,
    fontStyle: 'normal',
    fontWeight: 'bold',
    color: settings.darkest100,
    fontSize: '18px',
    lineHeight: '124%',
  },
  h5: undefined,
  h6: undefined,
  subtitle1: {
    fontFamily: settings.fontFamilyMariupol,
    fontStyle: 'normal',
    fontWeight: 'normal',
    color: settings.darkest100,
    fontSize: '14px',
    lineHeight: '140%',
  },
  subtitle2: {
    fontFamily: settings.fontFamilyMariupol,
    fontStyle: 'normal',
    fontWeight: 'normal',
    color: settings.darkest100,
    fontSize: '10px',
    lineHeight: '140%',
  },
  body1: {
    fontFamily: settings.fontFamilyMariupol,
    fontStyle: 'normal',
    fontWeight: 'normal',
    color: settings.darkest100,
    fontSize: '16px',
    lineHeight: '150%',
  },
  body2: {
    fontFamily: settings.fontFamilyMariupol,
    fontStyle: 'normal',
    fontWeight: 'normal',
    color: settings.darkest100,
    fontSize: '14px',
    lineHeight: '140%',
  },
  button: {
    fontFamily: settings.fontFamilyMariupol,
    fontStyle: 'normal',
    fontWeight: 'bold',
    color: settings.darkest100,
    fontSize: '16px',
    lineHeight: '126%',
    textTransform: 'none',
  },
  caption: {
    fontFamily: settings.fontFamilyMariupol,
    fontStyle: 'normal',
    fontWeight: 'normal',
    color: settings.darkest100,
    fontSize: '20px',
    lineHeight: '100%',
  },
  overline: {
    fontFamily: settings.fontFamilyMariupol,
    fontStyle: 'normal',
    fontWeight: 'normal',
    color: settings.darkest100,
    fontSize: '10px',
    lineHeight: '140%',
    textTransform: 'normal',
    letterSpacing: 'normal',
  },
};

let theme = createTheme({});

theme = createTheme(theme, {
  typography: {
    fontFamily: ['Inter', 'sans-serif'].join(','),
    h1: {
      ...typography.h1,
      [theme.breakpoints.only('sm')]: {
        fontSize: '40px',
      },
    },
    h2: typography.h2,
    h3: typography.h3,
    h4: typography.h4,
    h5: typography.h5,
    h6: typography.h6,
    subtitle1: typography.subtitle1,
    subtitle2: typography.subtitle2,
    body1: typography.body1,
    body2: typography.body2,
    button: typography.button,
    caption: typography.caption,
    overline: typography.overline,
  },
  palette: {
    mode: 'light',
    background: {
      default: settings.darkest20,
    },
    primary: {
      main: settings.primary100,
    },
    secondary: {
      main: settings.secondaryPurple100,
    },
    info: {
      main: settings.white,
      dark: settings.darkest100,
      light: settings.darkest80,
      contrastText: settings.darkest60,
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {},
    },
    MuiTypography: {
      defaultProps: {
        variantMapping: {
          h1: 'h1',
          h2: 'h2',
          h3: 'h3',
          h4: 'h4',
          h5: 'h5',
          h6: 'h6',
          subtitle1: 'p',
          subtitle2: 'p',
          body1: 'p',
          body2: 'p',
        },
      },
    },
    MuiAppBar: {
      defaultProps: {
        elevation: 0,
        position: 'relative',
      },
      styleOverrides: {
        root: {
          backgroundColor: '#232233',
          color: '#f8f9f8',
          padding: '1rem 0',
        },
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          margin: '1rem 0',
        },
      },
    },

    MuiListItemText: {
      styleOverrides: {
        root: {
          color: '#f5f6f6',
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          borderRadius: '10px !important',
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          boxSizing: 'border-box',
          width: '100%',
          borderRadius: '12px',
          background: settings.white,
          '& .MuiInputBase-input': {
            paddingBottom: '6px',
            paddingTop: '30px',
            paddingLeft: '20px',
            paddingRight: '20px',
            height: '24px',
          },
          '& .MuiInputLabel-root': {
            fontFamily: settings.fontFamilyMariupol,
            fontSize: '16px',
            fontWeight: 'normal',
            transform: 'translate(20px, 18px) scale(1)',
            color: settings.darkest60,
          },
          '& .MuiInputLabel-shrink': {
            transform: 'translate(20px, 8px) scale(0.75)',
            color: settings.darkest100,
          },
        },
      },
    },
    MuiButton: {
      defaultProps: {
        variant: 'contained',
        disableElevation: true,
        color: 'primary',
      },
      styleOverrides: {
        root: {
          flex: '0 0 auto',
          border: 'none',
          outline: 'none',
          width: '100%',
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'center',
          backgroundColor: settings.primary100,
          padding: '20px',
          boxSizing: 'border-box',

          textAlign: 'center',
          borderRadius: '12px',

          cursor: 'pointer',
          userSelect: 'none',
          transition: 'opacity 0.1s ease-in-out, transform 0.2s cubic-bezier(0.32, 0, 0.15, 1.75)',
          '&.disabled': {
            opacity: 0.5,
          },
          '&:hover': {
            transform: 'scale(0.99)',
            backgroundColor: settings.primary100,
          },
          '&:active': {
            transform: 'scale(0.95)',
          },
        },
      },
    },
    MuiCard: {
      defaultProps: {
        elevation: 0,
      },
      styleOverrides: {
        root: {
          borderRadius: '10px',
          transitionDuration: '.3s',
          transitionProperty: 'all',
          ':hover': {
            boxShadow: '0px 5px 5px rgba(0,0,0,0.05)',
            transform: 'scale(1.025)',
          },
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        root: {
          '& .MuiOutlinedInput-root': {
            paddingTop: '0',
            paddingBottom: '0',
            paddingLeft: '0',
            display: 'flex',
            flexDirection: 'column-reverse',
            alignItems: 'flex-start',
            flexGrow: 1,
          },
          '& .MuiInputBase-input': {
            paddingBottom: '6px !important',
            paddingTop: '30px !important',
            paddingLeft: '20px !important',
            paddingRight: '20px !important',
            height: '24px !important',
          },
          '& .MuiAutocomplete-input': {
            width: 'calc(100% - 100px) !important',
          },
          '& .MuiAutocomplete-tag': {
            width: '100%',
            padding: '0px 5px',
            backgroundColor: settings.white,
            display: 'flex',
            justifyContent: 'flex-start',
          },
          '& .MuiAutocomplete-tag span': {
            flexGrow: 1,
          },
          '& .MuiAutocomplete-tag .MuiChip-deleteIcon': {
            color: settings.primary60,
          },
        },
        endAdornment: {
          right: '20px !important',
          top: 'calc(16px) !important',
          transform: 'none',
        },
        popupIndicator: {
          color: settings.darkest100,
        },
        clearIndicator: {
          color: settings.darkest100,
        },
        inputRoot: {
          padding: '0 !important',
        },
        option: {
          ...typography.body1,
          padding: '12px 20px',
        },
        paper: {
          borderRadius: '12px',
          marginTop: '4px',
          boxShadow: '0px 5px 5px rgba(0,0,0,0.05)',
        },
        listbox: {
          padding: '8px 0',
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        notchedOutline: {
          border: 'none',
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          ...typography.body1,
          padding: '12px 20px',
          '&:hover': {
            backgroundColor: settings.darkest20,
          },
          '&.Mui-selected': {
            backgroundColor: settings.primary100 + '20',
            '&:hover': {
              backgroundColor: settings.primary100 + '30',
            },
          },
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        root: {
          backgroundColor: settings.white,
          borderRadius: '12px',
        },
        select: {
          ...typography.body1,
          paddingBottom: '6px',
          paddingTop: '30px',
          paddingLeft: '20px',
          paddingRight: '20px',
          height: '24px',
        },
        icon: {
          color: settings.darkest100,
          right: '20px',
        },
      },
    },

    MuiFormControl: {
      styleOverrides: {
        root: {
          width: '100%',
          borderRadius: '12px',
          backgroundColor: settings.white,
        },
      },
    },

    MuiInputLabel: {
      styleOverrides: {
        root: {
          ...typography.body1,
          transform: 'translate(20px, 18px) scale(1)',
          '&.MuiInputLabel-shrink': {
            transform: 'translate(20px, 8px) scale(0.75)',
          },
        },
        outlined: {
          transform: 'translate(20px, 18px) scale(1)',
          '&.MuiInputLabel-shrink': {
            transform: 'translate(20px, 8px) scale(0.75)',
          },
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          borderRadius: '12px',
          boxShadow:
            '0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12)',
        },
      },
    },
  },
});

export { theme };
