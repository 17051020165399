import { useQuery } from '@tanstack/react-query';
import { useMemo } from 'react';

import { getFaculties } from '../../api/filters';

export const useFaculties = (selectedUniversities?: number[]) => {
  const { data, isLoading, error } = useQuery({
    queryKey: ['faculties', selectedUniversities],
    queryFn: () => getFaculties(selectedUniversities),
  });

  return { data, isLoading, error };
};

export const useFacultyOptions = (selectedUniversities?: number[]) => {
  const { data = [], isLoading, error } = useFaculties(selectedUniversities);

  const facultyOptions = useMemo(
    () =>
      data?.map((faculty) => ({
        id: faculty.id,
        label: faculty.name,
      })) ?? [],
    [data],
  );

  return { facultyOptions, isLoading, error };
};
