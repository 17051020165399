import { Typography } from '@mui/material';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { useUniversitySpecialties } from '../../../hooks/useUniversitySpecialties';
import { SearchBar } from '../../SearchBar/SearchBar';
import { SortButton } from '../../SortButton/SortButton';

import styles from './SpecialtiesTab.module.scss';

export const SpecialtiesTab: FC = () => {
  const { t, i18n } = useTranslation();

  const { searchTerm, sortAlphabetically, isLoading, sortedSpecialities, handleSearchChange, toggleSorting } =
    useUniversitySpecialties();

  return (
    <div className={styles.structureContainer}>
      <Typography variant='h2' marginBottom='12px'>
        {t('universityPage.specialtiesTab.title')}
      </Typography>

      <div className={styles.controlsContainer}>
        <SearchBar searchTerm={searchTerm} onSearchChange={handleSearchChange} />
        <SortButton isActive={sortAlphabetically} onToggle={toggleSorting} />
      </div>

      <ul className={styles.specialitiesContainer}>
        {isLoading ? (
          <span>{t('universityPage.common.loading')}</span>
        ) : (
          sortedSpecialities.map((speciality) => (
            <li key={speciality.id}>
              <div className={styles.specialityHeader}>
                <div className={styles.specialityCode}>{speciality.code}</div>
                <Typography variant='h3' className={styles.specialityName}>
                  {speciality.name}
                </Typography>
              </div>
              <ul className={styles.facultiesList}>
                {speciality.relatedFaculties.map((faculty) => (
                  <li key={faculty.id} className={styles.facultyItem}>
                    <span className={styles.facultyIcon}>
                      <img src='/emoji/graduation-cap.svg' alt='graduation-cap' />
                    </span>
                    <Typography>{faculty.name[i18n.language]}</Typography>
                  </li>
                ))}
              </ul>
            </li>
          ))
        )}
      </ul>
    </div>
  );
};

export default SpecialtiesTab;
