import { useMemo, useState } from 'react';

import { SpecialityFilter } from './useSpecialitiesFilters';

export const useSpecialitySearch = (data: SpecialityFilter[]) => {
  const [searchValue, setSearchValue] = useState('');

  const adapted = (s: string) => s?.toLowerCase().replace(/ /g, '') ?? '';

  const filteredFilters = useMemo(() => {
    if (!searchValue) return data;

    if (searchValue.match(/^\d\d+$/)) {
      return data
        .filter((item) => item.branchRelatedSpecialities.some((filter) => filter.code.includes(searchValue)))
        .map((item) => ({
          ...item,
          branchRelatedSpecialities: item.branchRelatedSpecialities.filter((filter) =>
            filter.code.includes(searchValue),
          ),
        }));
    }

    if (searchValue.match(/^..+$/)) {
      return data
        .filter((item) =>
          item.branchRelatedSpecialities.some((filter) => adapted(filter.name).includes(adapted(searchValue))),
        )
        .map((item) => ({
          ...item,
          branchRelatedSpecialities: item.branchRelatedSpecialities.filter((filter) =>
            adapted(filter.name).includes(adapted(searchValue)),
          ),
        }));
    }

    return data;
  }, [data, searchValue]);

  const search = (value: string) => {
    setSearchValue(value);
  };

  return { searchValue, search, filteredFilters };
};
