import { useEffect, useState } from 'react';
import { Navigate, useSearchParams } from 'react-router-dom';

import { langPath } from '../../../../utils/langPath';
import { useAuth } from '../../state/AuthContext';
import { useAuthForm } from '../../state/AuthFormContext';

const redirect = {
  userAlreadyRegistered: langPath('/'),
  userNotRegistered: langPath('/auth/register/form'),
  fallback: langPath('/auth/register'),
};

export default function CallbackPage() {
  const [searchParams] = useSearchParams();
  const { setAccessToken } = useAuth();
  const { startRegistration } = useAuthForm();
  const [redirectTo, setRedirectTo] = useState<string>(redirect.fallback);

  useEffect(() => {
    const token = searchParams.get('token');
    const isRegistrationComplete = searchParams.get('isRegistrationComplete');

    setAccessToken(token);

    if (isRegistrationComplete === 'true') {
      setRedirectTo(redirect.userAlreadyRegistered);
    }
    if (isRegistrationComplete === 'false') {
      startRegistration();
      setRedirectTo(redirect.userNotRegistered);
    }
  }, []);

  return <Navigate to={redirectTo} replace />;
}
