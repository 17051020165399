import { RegistrationDto } from '../../../api/schemas/auth';
import { Form } from '../state/AuthFormContext';

export const mapFormDataToDto = (form: Form): RegistrationDto => {
  switch (form.type) {
    case 'APPLICANT':
      return {
        name: form.data.setupData?.name,
        userType: form.type,
        enrollmentYear: form.data.personalData?.entranceYear,
        country: form.data.personalData?.country,
        city: form.data.personalData?.city,
        aboutMe: undefined,
        aboutMeDetailed: undefined,
        expectations: undefined,
        expectationsDetailed: undefined,
        selectedRegions: form.data.preferencesData?.cityPreferences,
        interestedSpecialities: form.data.specialitiesData?.specialities,
        selectedUniversities: form.data.preferencesData?.universityPreferences,
      };
    case 'APPLICANT_MEMBER':
      return {
        name: form.data.setupData?.name,
        userType: form.type,
        enrollmentYear: form.data.personalData?.entranceYear,
        country: form.data.personalData?.country,
        city: form.data.personalData?.city,
        selectedRegions: form.data.preferencesData?.cityPreferences,
        interestedSpecialities: form.data.specialitiesData?.specialities,
        selectedUniversities: form.data.preferencesData?.universityPreferences,
      };
    case 'STUDENT':
      return {
        name: form.data.setupData?.name,
        userType: form.type,
        enrollmentYear: form.data.studentData?.entranceYear,
        universityId: form.data.studentData?.university,
        facultyId: form.data.studentData?.faculty,
        specialityId: form.data.studentData?.speciality,
      };
    case 'TEACHER':
      return {
        name: form.data.setupData?.name,
        userType: form.type,
        universities: form.data.teacherData?.universities,
        subjects: form.data.teacherData?.subjects.split(',').map((x) => x.trim()),
      };
    case 'OTHER':
      return {
        name: form.data.setupData?.name,
        userType: form.type,
        aboutMe: form.data.otherData?.about,
      };
    default: {
      return {};
    }
  }
};
