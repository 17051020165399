import { OptionalSubject, PartialSubjects, SpecialityWithCoefficients } from '../../../api/types/specialities';

import { calculateRequiredGrade } from './calculateRequiredGrade';

const calculateMinimumCompetitiveScore = (
  speciality: SpecialityWithCoefficients,
  grades: PartialSubjects | null,
  courseGrade: number | '',
  oldFormula: boolean,
): number => {
  const ukrainian = grades?.ukrainian || 200;
  const mathematics = grades?.mathematics || 200;
  const history = grades?.history || 200;
  const creativeCompetitionGrade = grades?.creativeCompetition || undefined;

  let lowestOptionalCoef = 1;
  let lowestOptionalSubject: OptionalSubject | null = null;

  const optionalSubjects: OptionalSubject[] = [
    'foreignLanguage',
    'biology',
    'physics',
    'chemistry',
    'ukrainianLiterature',
    'geography',
  ];

  optionalSubjects.forEach((subject) => {
    const coef = speciality.coefficients[subject];
    if (coef && (coef < lowestOptionalCoef || lowestOptionalSubject === null)) {
      lowestOptionalCoef = coef;
      lowestOptionalSubject = subject;
    }
  });

  if (!lowestOptionalSubject) {
    return 100;
  }

  const maxCoef = oldFormula
    ? lowestOptionalCoef
    : Object.entries(speciality.coefficients).reduce((max, [subject, grade]) => {
        return subject === 'ukrainian' ||
          subject === 'mathematics' ||
          subject === 'history' ||
          subject === 'creativeCompetition'
          ? max
          : Math.max(max, grade || 0);
      }, 0);

  const k =
    (speciality.coefficients.mathematics || 0) +
    (speciality.coefficients.ukrainian || 0) +
    (speciality.coefficients.history || 0) +
    (speciality.coefficients.creativeCompetition || 0) +
    (maxCoef + lowestOptionalCoef) / 2;

  let baseGrade =
    (speciality.coefficients.ukrainian || 0) * ukrainian +
    (speciality.coefficients.mathematics || 0) * mathematics +
    (speciality.coefficients.history || 0) * history;

  if (speciality.coefficients.creativeCompetition && creativeCompetitionGrade !== undefined) {
    baseGrade += (speciality.coefficients.creativeCompetition || 0) * creativeCompetitionGrade;
  }

  baseGrade += lowestOptionalCoef * 100;

  const parsedCourseGrade = courseGrade === '' ? 0 : Number(courseGrade) || 0;

  const minCompetitiveScore = baseGrade / k + parsedCourseGrade;

  return Math.ceil(minCompetitiveScore);
};

export const generateInverseGraphData = (
  speciality: SpecialityWithCoefficients | null,
  grades: PartialSubjects | null,
  courseGrade: number | '',
  oldFormula: boolean,
) => {
  const data: Array<{
    competitiveScore: number;
    [key: string]: number | undefined;
  }> = [];

  if (!speciality) {
    return { data, minimumCompetitiveScore: 100 };
  }

  const relevantSubjects = Object.entries(speciality.coefficients)
    .filter(
      ([subject, coef]) =>
        coef &&
        subject !== 'ukrainian' &&
        subject !== 'mathematics' &&
        subject !== 'history' &&
        subject !== 'creativeCompetition',
    )
    .map(([subject]) => subject);

  if (relevantSubjects.length === 0) {
    return { data, minimumCompetitiveScore: 100 };
  }

  const minimumCompetitiveScore = calculateMinimumCompetitiveScore(speciality, grades, courseGrade, oldFormula);

  const startScore = Math.min(minimumCompetitiveScore, 200);

  for (let competitiveScore = startScore; competitiveScore <= 200; competitiveScore++) {
    const dataPoint: {
      competitiveScore: number;
      [key: string]: number | undefined;
    } = {
      competitiveScore,
    };

    for (const subject of relevantSubjects) {
      try {
        const coef = speciality.coefficients[subject as keyof SpecialityWithCoefficients['coefficients']];

        if (!coef) continue;

        const requiredSubjectScore = calculateRequiredGrade({
          speciality,
          ukrainian: grades?.ukrainian || 200,
          mathematics: grades?.mathematics || 200,
          history: grades?.history || 200,
          creativeCompetitionGrade: grades?.creativeCompetition ?? undefined,
          optionalSubjectCoef: coef,
          targetCompetitiveScore: competitiveScore,
          courseGrade,
          oldFormula,
        });

        if (!isNaN(requiredSubjectScore) && requiredSubjectScore < 500) {
          dataPoint[subject + 'Line'] = requiredSubjectScore;
        }
      } catch (error) {
        console.error(`Error calculating score for ${subject}:`, error);
      }
    }

    if (Object.keys(dataPoint).length > 1) {
      data.push(dataPoint);
    }
  }

  return { data, minimumCompetitiveScore };
};
