import { useTranslation } from 'react-i18next';

import { SpecialityWithCoefficients } from '../../../../../../api/types/specialities';
import { gradesValidator } from '../../../../utils/gradesValidator';
import styles from '../../SubjectInputs.module.scss';
import { NumberInput } from '../NumberInput/NumberInput';
type AdditionalGradesInputsProps = {
  speciality: SpecialityWithCoefficients | null;
  setSpecialityId: (speciality: number | null) => void;
  courseGrade: number | '';
  setCourseGrade: (value: number | '') => void;
  creativeCompetitionGrade: number | '';
  setCreativeCompetitionGrade: (value: number | '') => void;
};

export const AdditionalGradesInputs = ({
  speciality,
  setCourseGrade,
  courseGrade,
  setCreativeCompetitionGrade,
  creativeCompetitionGrade,
}: AdditionalGradesInputsProps) => {
  const { t } = useTranslation();
  return (
    <div className={styles.additionalGradesInputs}>
      <p className='h2'>{t('calculator.inputs.titles.additional')}</p>
      <p>{t('calculator.inputs.descriptions.additional')}</p>
      <div
        style={{
          maxWidth: speciality ? 425 : 375,
        }}
        className={`${styles.inputWrapper} ${speciality ? styles.hasCoefficient : ''}`}
      >
        <NumberInput
          id='creativeCompetition'
          validator={gradesValidator}
          label={t('calculator.subjects.nominative.creativeCompetition')}
          placeholder={t('calculator.subjects.nominative.creativeCompetition')}
          value={creativeCompetitionGrade}
          onChange={setCreativeCompetitionGrade}
        />
        {speciality && <p>х&nbsp;{speciality.coefficients.creativeCompetition}</p>}
      </div>
      <div
        style={{
          maxWidth: speciality ? 425 : 375,
        }}
        className={styles.inputWrapper}
      >
        <NumberInput
          id='courseGrade'
          validator={(value: number) => value >= 0 && value <= 10}
          label={t('calculator.subjects.nominative.course')}
          placeholder={t('calculator.subjects.nominative.course')}
          value={courseGrade}
          onChange={setCourseGrade}
        />
        {speciality && <p></p>}
      </div>
    </div>
  );
};
