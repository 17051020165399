import { zodResolver } from '@hookform/resolvers/zod';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import Button from '../../../../../components/ButtonV2/Button';
import FormAutocomplete from '../../../../../components/mui-form/FormAutocomplete';
import FormSelect from '../../../../../components/mui-form/FormSelect';
import FormTextField from '../../../../../components/mui-form/FormTextField';
import { useCountryOptions } from '../../../../../hooks/filters/useCountryOptions';
import { useYearOptions } from '../../../../../hooks/filters/useYearOptions';
import Label from '../../../components/Label';
import Section from '../../../components/Section';
import ValidationError from '../../../components/ValidationError';
import {
  ApplicantPersonalInfoSchema,
  applicantPersonalInfoSchema,
  ApplicantSchema,
} from '../../../schema/applicant-form';
import { SetupSchema } from '../../../schema/setup-form';
import { useAuthForm } from '../../../state/AuthFormContext';

export default function PersonalInfoForm() {
  const { t } = useTranslation();
  const authStore = useAuthForm();
  const { yearOptions } = useYearOptions();
  const { countryOptions } = useCountryOptions();

  const form = useForm<ApplicantPersonalInfoSchema>({
    resolver: zodResolver(applicantPersonalInfoSchema),
    defaultValues: (authStore.formData?.data as ApplicantSchema).personalData || {
      entranceYear: new Date().getFullYear(),
    },
  });

  const onSubmit = (data: ApplicantPersonalInfoSchema, event?: React.BaseSyntheticEvent) => {
    const setupData = authStore.formData?.data as SetupSchema;
    authStore.appendFormData({
      type: 'APPLICANT',
      data: {
        setupData: setupData,
        personalData: data,
      },
    });
    const submitEvent = event?.nativeEvent as SubmitEvent;
    const submitter = submitEvent.submitter as HTMLButtonElement;
    authStore.nextStep();
    if (submitter?.name === 'complete') {
      authStore.nextStep();
      authStore.nextStep();
    }
  };

  return (
    <FormProvider {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)}>
        <Section>
          <Label required>{t('auth.registration-flow.entrance-year.label')}</Label>
          <FormSelect
            name='entranceYear'
            label={t('auth.registration-flow.entrance-year.applicant-label')}
            options={yearOptions}
          />
          <ValidationError error={form.formState.errors.entranceYear} />
        </Section>
        <Section>
          <Label required>{t('auth.registration-flow.location.applicant-label')}</Label>
          <FormAutocomplete
            name='country'
            options={countryOptions}
            label={t('auth.country')}
            placeholder={t('auth.registration-flow.location.applicant-country-placeholder')}
          />
          <ValidationError error={form.formState.errors.country} />
          <FormTextField
            name='city'
            label={t('auth.city')}
            placeholder={t('auth.registration-flow.location.applicant-city-placeholder')}
          />
          <ValidationError error={form.formState.errors.city} />
        </Section>
        <Button disabled={!form.formState.isValid} variant='outline' type='submit' name='complete'>
          {t('auth.registration-flow.buttons.complete')}
        </Button>
        <Button disabled={!form.formState.isValid} type='submit' name='continue'>
          {t('auth.registration-flow.buttons.fill-all')}
        </Button>
      </form>
    </FormProvider>
  );
}
