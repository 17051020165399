import { Typography } from '@mui/material';
import { Partner } from '../../../api/types/partners';
import styles from '../Partners.module.scss';

type PartnersSectionProps = {
  title: string;
  description: string;
  partners: Partner[];
};

export default function PartnersSection(props: PartnersSectionProps) {
  if (!props.partners?.length) {
    return null;
  }

  return (
    <div className={styles.sectionWrap}>
      <Typography variant='h2'>{props.title}</Typography>
      <Typography sx={{ maxWidth: 550 }} variant='body1'>
        {props.description}
      </Typography>
      <div className={styles.partnerCards}>
        {props.partners?.map((partner) => <PartnerCard key={partner.id} partner={partner} />)}
      </div>
    </div>
  );
}

function PartnerCard({ partner }: { partner: Partner }) {
  return (
    <a href={partner.websiteUrl} target='_blank' rel='noopener noreferrer' className={styles.partnerCard}>
      <img src={partner.photoUrl} alt={partner.name} />
    </a>
  );
}
