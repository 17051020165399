import { Typography } from '@mui/material';
import { FC } from 'react';
import { Fragment } from 'react/jsx-runtime';
import { useTranslation } from 'react-i18next';

import { UniversityContacts } from '../../../types/universityContacts';
import { formatUrl } from '../../../utils/formatUrl';

import styles from './ContactsTab.module.scss';

export interface ContactsTabProps {
  contacts: UniversityContacts;
}

export const ContactsTab: FC<ContactsTabProps> = ({ contacts }) => {
  const {
    city,
    address,
    website: { name, url },
    admissionsCommittee,
  } = contacts;

  const { t, i18n } = useTranslation();
  const lang = i18n.language;

  const contactItems = [
    {
      label: t('universityPage.contactsTab.labels.city'),
      value: city[lang],
    },
    {
      label: t('universityPage.contactsTab.labels.location'),
      value: address[lang],
    },
    {
      label: t('universityPage.contactsTab.labels.website'),
      value: name[lang],
      href: formatUrl(url),
    },
  ];

  const admissionsCommitteeItems = [
    { href: `mailto:${admissionsCommittee.email}`, text: admissionsCommittee.email, key: 'email' },
    {
      multipleLinks: admissionsCommittee.phone
        ? admissionsCommittee.phone.split(',').map((phone) => ({
            href: `tel:${phone.trim()}`,
            text: phone.trim(),
          }))
        : [],
      key: 'phone',
    },
  ];

  return (
    <div className={styles.contactsContainer}>
      <Typography variant='h2' marginBottom='12px'>
        {t('universityPage.contactsTab.title')}
      </Typography>

      {contactItems.map((item) => (
        <div key={item.value} className={styles.contactSection}>
          <Typography variant='body2'>{item.label}</Typography>
          <div className={styles.contactValue}>
            {item.href ? (
              <a href={item.href} target='_blank' rel='noopener noreferrer nofollow'>
                {item.value}
              </a>
            ) : (
              item.value
            )}
          </div>
        </div>
      ))}

      <div className={styles.admissionsSection}>
        <Typography variant='h3' marginBottom='16px'>
          {t('universityPage.contactsTab.admissionsCommittee.title')}
        </Typography>

        {admissionsCommittee.persons.map((person) => (
          <div key={person.name.uk} className={styles.contactPersonRow}>
            <div className={styles.contactPersonRole}>{person.role[lang]}</div>
            <div className={styles.contactPersonName}>{person.name[lang]}</div>
          </div>
        ))}

        {admissionsCommitteeItems.map((item) => (
          <div key={item.key} className={styles.contactPersonRow}>
            <div className={styles.contactPersonRole}>
              {t(`universityPage.contactsTab.admissionsCommittee.${item.key}`)}
            </div>
            <div className={styles.contactPersonName}>
              {item.multipleLinks ? (
                item.multipleLinks.map((link, index) => (
                  <Fragment key={index}>
                    <a href={link.href} target='_blank' rel='noopener noreferrer nofollow'>
                      {link.text}
                    </a>
                    {index < item.multipleLinks.length - 1 && ', '}
                  </Fragment>
                ))
              ) : (
                <a href={item.href} target='_blank' rel='noopener noreferrer nofollow'>
                  {item.text}
                </a>
              )}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ContactsTab;
