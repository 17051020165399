import { Autocomplete, AutocompleteProps, TextField } from '@mui/material';
import { Controller, FieldValues, Path, useFormContext } from 'react-hook-form';
import { z } from 'zod';

export const autocompleteOptionSchema = z.object({
  id: z.string().or(z.number()),
  label: z.string(),
});

export type AutocompleteOption = z.infer<typeof autocompleteOptionSchema>;

type FormAutocompleteProps<T extends FieldValues> = {
  name: Path<T>;
  label: string;
  options: AutocompleteOption[];
  multiple?: boolean;
  placeholder?: string;
} & Omit<AutocompleteProps<AutocompleteOption, boolean, false, false>, 'name' | 'renderInput' | 'options' | 'multiple'>;

const FormAutocomplete = <T extends FieldValues>({
  name,
  label,
  options,
  multiple = false,
  ...rest
}: FormAutocompleteProps<T>) => {
  const { control } = useFormContext<T>();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, value, ref, ...field } }) => {
        const selectedOption = multiple
          ? options.filter((option) => value?.some((val: AutocompleteOption) => option.id === (val?.id ?? val)))
          : options.find((option) => option.id === (value?.id || value)) || null;

        return (
          <Autocomplete<AutocompleteOption, boolean, false, false>
            multiple={multiple}
            options={options}
            getOptionLabel={(option) => option.label}
            value={selectedOption}
            onChange={(_, newValue: AutocompleteOption | AutocompleteOption[] | null) => {
              if (multiple && Array.isArray(newValue)) {
                onChange(newValue.map((val) => val.id));
              } else if (!multiple && !Array.isArray(newValue)) {
                onChange(newValue?.id ?? null);
              }
            }}
            isOptionEqualToValue={(option, value) => option.id === value?.id}
            {...rest}
            renderInput={(params) => (
              <TextField
                {...params}
                {...field}
                inputRef={ref}
                label={label}
                variant='outlined'
                placeholder={rest.placeholder}
              />
            )}
          />
        );
      }}
    />
  );
};

export default FormAutocomplete;
