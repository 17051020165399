import { Outlet } from 'react-router-dom';

import App from '../app';
import Footer from '../Footer/Footer';
import Header from '../Header/Header';

export const MainLayout = () => {
  return (
    <App>
      <Header />
      <Outlet />
      <Footer />
    </App>
  );
};
