import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  CartesianGrid,
  ComposedChart,
  Legend,
  Line,
  ReferenceLine,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';

import { PartialSubjects, SpecialityWithCoefficients } from '../../../../../api/types/specialities';
import { subjectColors } from '../../../constants/subjectColors';
import { SubjectColors } from '../../../types/subject-colors';
import { generateInverseGraphData } from '../../../utils/generateInverseGraphData';
import { useResponsiveChart } from '../../../utils/useResponsiveChart';

import { CustomTooltip } from './CustomTooltip';

interface Props {
  speciality: SpecialityWithCoefficients | null;
  grades: PartialSubjects | null;
  courseGrade: number | '';
  oldFormula: boolean;
}

export function GradesGraph({ speciality, grades, courseGrade, oldFormula }: Props) {
  const [data, setData] = useState<Array<{ score: number; [key: string]: number | undefined }>>([]);
  const [minimumScore, setMinimumScore] = useState<number | null>(null);
  const { t } = useTranslation();
  const {
    isXSmall,
    isSmall,
    getChartHeight,
    getChartMargins,
    getLabelFontSize,
    getStrokeWidth,
    getTickCount,
    calculateYAxisTicks,
    getLegendPosition,
  } = useResponsiveChart();

  useEffect(() => {
    if (speciality) {
      try {
        const { data: inverseGraphData, minimumCompetitiveScore } = generateInverseGraphData(
          speciality,
          grades,
          courseGrade,
          oldFormula,
        );

        setMinimumScore(minimumCompetitiveScore);

        const transformedData = inverseGraphData.map((point) => {
          const newPoint: {
            score: number;
            [key: string]: number | undefined;
          } = {
            score: point.competitiveScore,
          };

          Object.entries(point).forEach(([key, value]) => {
            if (key !== 'competitiveScore' && value !== undefined) {
              newPoint[key] = value;
            }
          });

          return newPoint;
        });

        setData(transformedData);
      } catch (error) {
        console.error('Error generating graph data:', error);
        setData([]);
      }
    }
  }, [speciality, grades, courseGrade, oldFormula]);

  return (
    <ResponsiveContainer
      key={oldFormula ? 1 : 0}
      width='100%'
      height={getChartHeight()}
      className='grades-chart-container'
    >
      <ComposedChart data={data} margin={getChartMargins()}>
        <CartesianGrid strokeDasharray='3 3' stroke={isXSmall ? 'rgba(0,0,0,0.1)' : undefined} />

        <XAxis
          dataKey='score'
          type='number'
          domain={[minimumScore || 100, 200]}
          tick={{ fontSize: getLabelFontSize() }}
          label={{
            value: t('calculator.graph.y'),
            position: 'bottom',
            offset: -5,
            fontSize: getLabelFontSize(),
          }}
          tickCount={6}
        />

        <YAxis
          domain={[100, 200]}
          type='number'
          interval={'preserveStartEnd'}
          tick={{ fontSize: getLabelFontSize() }}
          label={{
            value: t('calculator.graph.x'),
            angle: -90,
            dx: isSmall ? -5 : -10,
            position: 'insideCenter',
            fontSize: getLabelFontSize(),
          }}
          ticks={calculateYAxisTicks(100, 200)}
          tickCount={getTickCount()}
        />

        <Tooltip content={<CustomTooltip />} isAnimationActive />

        <Legend {...getLegendPosition()} />

        <ReferenceLine
          y={200}
          stroke='#f44336'
          strokeDasharray='3 3'
          label={{
            value: 'max',
            position: 'right',
            fill: '#f44336',
            fontSize: getLabelFontSize() - 2,
          }}
        />

        {speciality &&
          Object.entries(speciality.coefficients).map(([subject, coef]) => {
            if (coef && subject !== 'ukrainian' && subject !== 'mathematics' && subject !== 'history') {
              return (
                <Line
                  key={`line-${subject}`}
                  type='monotone'
                  dataKey={`${subject}Line`}
                  stroke={subjectColors[subject as keyof SubjectColors]}
                  strokeWidth={getStrokeWidth()}
                  dot={false}
                  activeDot={{ r: 5 }}
                  legendType='none'
                  isAnimationActive={false}
                  connectNulls={true}
                  name={t(`calculator.subjects.nominative.${subject}`)}
                />
              );
            }
            return null;
          })}
      </ComposedChart>
    </ResponsiveContainer>
  );
}
