import { basic } from '../common/constants';

import { API } from './constants';
import { SpecialityAllRes, SpecialityWithCoefficients } from './types/specialities';

export const fetchSpecialitiesAll = async (): Promise<SpecialityAllRes> => {
  try {
    const response = await fetch(`${basic.backendUrl}${API.CALCULATOR.SPECIALITIES}`);
    if (!response.ok) {
      throw new Error('Failed to fetch specialities data');
    }
    return await response.json();
  } catch (error) {
    console.error('Error fetching specialities data:', error);
    return [];
  }
};

export const fetchSpecialityById = async (specialityId: number): Promise<SpecialityWithCoefficients> => {
  try {
    const response = await fetch(`${basic.backendUrl}${API.CALCULATOR.SPECIALITIES}/${specialityId}`);

    if (!response.ok) {
      throw new Error('Failed to fetch speciality data');
    }

    const speciality = await response.json();
    speciality.coefficients = Object.fromEntries(
      Object.entries(speciality.coefficients).map(([key, value]) => [key, value ? parseFloat(value as string) : 0]),
    );
    return speciality;
  } catch (error) {
    console.error('Error fetching specialities data:', error);
    return {
      id: 0,
      code: '',
      name: '',
      coefficients: {},
    } as unknown as SpecialityWithCoefficients;
  }
};
