import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { getOffer } from '../../../../api/offers';
import { SEO } from '../../../../components/seo/Seo';
import { CoefficientsCard } from '../../components/Offer/CoefficientsCard';
import { DetailsCard } from '../../components/Offer/DetailsCard';
import { EducationProgramsCard } from '../../components/Offer/EducationProgramsCard';
import { Header } from '../../components/Offer/Header';
import { LastScoresCard } from '../../components/Offer/LastScoresCard';
import { StudentFeedbackCard } from '../../components/Offer/StudentFeedbackCard';
import { DetailedOffer } from '../../types/offer';
import { trackTimeOnPage } from '../../utils/trackTimeOnPage';
import { LoadingPage } from '../LoadingPage/LoadingPage';

import styles from './OfferPage.module.scss';

export function OfferPage() {
  const { id } = useParams();
  const [offer, setOffer] = useState<DetailedOffer | null>();

  useEffect(() => {
    if (!id) return;

    const fetchOffer = async () => {
      const result = await getOffer(Number(id));
      setOffer(result);
    };

    fetchOffer();
  }, []);

  useEffect(() => {
    const tracker = trackTimeOnPage('offer_detailed_page');

    return () => {
      if (tracker && tracker.cleanup) {
        tracker.cleanup();
      }
    };
  }, []);

  if (!offer) return <LoadingPage title='Пропозиції завантажуються' />;

  return (
    <>
      <SEO titleKey='seo:offers.title' descriptionKey='seo:offers.description' canonicalPath='/offers' />
      <div className={styles.wrap}>
        <Header offer={offer} />
        <DetailsCard offer={offer} />
        <EducationProgramsCard offer={offer} />
        <LastScoresCard offer={offer} />
        <CoefficientsCard offer={offer} />
        <StudentFeedbackCard offer={offer} />
      </div>
    </>
  );
}
