import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import styles from '../auth.module.scss';
import { useAuthForm } from '../state/AuthFormContext';

export default function Step() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { step, role, prevStep, totalSteps } = useAuthForm();

  const onClick = () => {
    if (step === 1) {
      navigate('/auth/register');
    }
    prevStep();
  };

  return (
    <div onClick={onClick} className={styles.step}>
      <img src='/images/auth/arrow-left.svg' alt='Go back arrow' />
      {role ? (
        <Typography variant='body2'>{t('auth.step_of_total', { step, total: totalSteps[role] })}</Typography>
      ) : (
        <Typography variant='body2'>{t('auth.back')}</Typography>
      )}
    </div>
  );
}
