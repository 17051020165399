import { createContext, ReactNode, useContext, useEffect, useState } from 'react';

import { api } from '../../../api/api';
import { User } from '../../../api/schemas/user';
import { useUser } from '../../../hooks/auth/useUser';

interface AuthState {
  accessToken: string | null;
  user: User | null;
  setAccessToken: (token: string | null) => void;
}

const AuthContext = createContext<AuthState | null>(null);

export function AuthProvider({ children }: { children: ReactNode }) {
  const [isMounted, setIsMounted] = useState(false);
  const [accessToken, setAccessToken] = useState<string | null>(() => {
    return localStorage.getItem('accessToken');
  });

  const { user, invalidateUser, logout } = useUser(isMounted && !!accessToken);

  useEffect(() => {
    if (accessToken) {
      localStorage.setItem('accessToken', accessToken);
      invalidateUser();
    } else {
      localStorage.removeItem('accessToken');
      logout();
    }
  }, [accessToken]);

  useEffect(() => {
    const interceptor = api.interceptors.request.use((config) => {
      if (accessToken) {
        config.headers.Authorization = `Bearer ${accessToken}`;
      }
      return config;
    });

    setIsMounted(true);

    return () => {
      api.interceptors.request.eject(interceptor);
    };
  }, [accessToken]);

  const value = {
    accessToken,
    user,
    setAccessToken,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

export const useAuth = (): AuthState => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};
