export interface Participant {
  firstName: string;
  lastName: string;
  role: string;
  description: string;
  links: {
    telegram: string;
    linkedIn: string;
    instagram?: string;
  };
  img: string;
}

export const PARTICIPANTS: Participant[] = [
  {
    firstName: 'Владислав',
    lastName: 'Бандурін',
    role: 'Co-Founder & CEO | MBA',
    img: 'vladyslav_bandurin.png',
    description: 'Організував всю цю вечірку :)',
    links: {
      telegram: 'https://t.me/VladBandurin',
      instagram: 'https://www.instagram.com/vladyslavbandurin',
      linkedIn: 'https://www.linkedin.com/in/vladislavbandurin',
    },
  },
  {
    firstName: 'Ілля',
    lastName: 'Крошка',
    img: 'illia_kroshka.jpg',
    role: 'CTO & Back-End Developer',
    description: 'Він оркеструє симфонію коду',
    links: {
      telegram: 'https://t.me/K_Illya',
      instagram: 'https://www.instagram.com/illya2418/',
      linkedIn: 'https://www.linkedin.com/in/illia-kroshka/',
    },
  },
  {
    firstName: 'Дмитро',
    lastName: 'Кириченко',
    role: 'Back-End Developer',
    img: 'dmytro_kyrychenko.jpg',
    description: 'Чарівник за завісою',
    links: {
      telegram: 'https://t.me/CyC_lik',
      instagram: 'https://www.instagram.com/0cyclik0',
      linkedIn: 'https://www.linkedin.com/in/dmytro-kyrychenko-916b922b9/',
    },
  },
  {
    firstName: 'Єлизавета',
    lastName: 'Ярмоленко',
    role: 'Product designer',
    img: 'elizabeth_yarmolenko.jpg',
    description: 'Якщо щось виглядає просто – значить, було складно зробити',
    links: {
      telegram: 'https://t.me/elizyarko',
      instagram: 'https://www.instagram.com/eliz_yarko/',
      linkedIn: 'https://www.linkedin.com/in/elizabeth-yarmolenko/',
    },
  },
  {
    firstName: 'Олег',
    lastName: 'Запара',
    img: 'oleh_zapara.jpg',
    role: 'Front-End Developer',
    description: 'Змушує інтерфейси не просто працювати, а захоплювати',
    links: {
      telegram: 'https://t.me/olehzpr',
      instagram: 'https://www.instagram.com/olegzpr',
      linkedIn: 'https://www.linkedin.com/in/oleh-zapara',
    },
  },
  {
    firstName: 'Олександр',
    lastName: 'Король',
    img: 'oleksandr_korol.png',
    role: 'Front-End Developer',
    description: 'Дарує форму та функціональність кожній веб-сторінці',
    links: {
      telegram: 'https://t.me/notsifes',
      linkedIn: 'https://www.linkedin.com/in/alexander-korol-1426a7286/',
    },
  },
  {
    firstName: 'Аліна',
    lastName: 'Матвіїва',
    img: 'alina_matviiva.png',
    role: 'Designer & Product Owner',
    description: 'Вона - найкраща з найкращих дизайнерів',
    links: {
      telegram: 'https://t.me/alinamagical',
      instagram: 'https://www.instagram.com/alinamagical',
      linkedIn: 'https://www.linkedin.com/in/alina-matvieieva',
    },
  },
  {
    firstName: 'Ольга',
    lastName: 'Усачова',
    img: 'olga_usachova.png',
    role: 'PR & Comms',
    description: 'Прагне, щоб про Abitly дізнався кожен абітурієнт',
    links: {
      telegram: 'https://t.me/olha_u',
      instagram: 'https://www.instagram.com/usachovaaa',
      linkedIn: 'https://www.linkedin.com/in/olhausachova',
    },
  },
  {
    firstName: 'Ярослав',
    lastName: 'Присяжний',
    img: 'yaroslav_prysyazhnii.png',
    role: 'Data Analyst',
    description: 'In data we trust',
    links: {
      telegram: 'https://t.me/user_yaroslaw',
      instagram: 'https://www.instagram.com/imposing_man',
      linkedIn: 'https://www.linkedin.com/in/yaroslav-prysiazhnyi-473084227',
    },
  },
  {
    firstName: 'Оксана',
    lastName: 'Жилка',
    img: 'oksana_zhylka.JPEG',
    role: 'SEO',
    description: 'Робить складне простим і доступним',
    links: {
      telegram: 'https://t.me/oksgogg',
      instagram: 'https://www.instagram.com/zhylkaoksana',
      linkedIn: 'https://www.linkedin.com/in/oksanazhylka',
    },
  },
  {
    firstName: 'Вікторія',
    lastName: 'Гречуха',
    img: 'viktoria_hrechukha.png',
    role: 'Full-Stack Engineer',
    description: 'Знайомить фахівців з чудовими людьми',
    links: {
      telegram: 'https://t.me/vhrechukha',
      instagram: 'https://www.instagram.com/crzhel',
      linkedIn: 'https://www.linkedin.com/in/vhrechukha',
    },
  },
  {
    firstName: 'Дмитро',
    lastName: 'Карауш',
    img: 'dmytro_karaush.png',
    role: 'ex-CTO & Full-Stack Engineer',
    description: 'Написав перший рядок коду для цього сайту',
    links: {
      telegram: 'https://t.me/dmytro',
      linkedIn: 'https://www.linkedin.com/in/dmitry-karaush-bb4290168',
    },
  },
  {
    firstName: 'Владислав',
    lastName: 'Сурін',
    img: 'vladyslav_surin.png',
    role: 'ex-Front-End Developer',
    description: 'Він вдихає життя в пікселі та дизайн',
    links: {
      telegram: 'https://t.me/surinvladyslav',
      instagram: 'https://www.instagram.com/surinvladyslav',
      linkedIn: 'https://www.linkedin.com/in/surinvladyslav',
    },
  },
  {
    firstName: 'Валерія',
    lastName: 'Прошаченко',
    img: 'valeria_proshachenko.png',
    role: 'ex-Front-End Developer',
    description: 'Понеділок починається в суботу',
    links: {
      telegram: 'http://t.me/Llairet',
      instagram: 'https://instagram.com/valerchixx',
      linkedIn: 'https://www.linkedin.com/in/valeria-proshachenko-71a506252',
    },
  },
];
