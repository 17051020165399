import React, { createContext, useContext, useEffect, useState } from 'react';

import { registerUser } from '../../../api/auth';
import { ApplicantSchema } from '../schema/applicant-form';
import { OtherUserSchema } from '../schema/other-user-form';
import { ApplicantMemberSchema } from '../schema/relative-form';
import { SetupSchema } from '../schema/setup-form';
import { StudentSchema } from '../schema/student-form';
import { TeacherSchema } from '../schema/teacher-form';
import { UserType } from '../schema/user-type';
import { mapFormDataToDto } from '../utils/form-mapping';

import { useAuth } from './AuthContext';

const STORAGE_KEY = 'abity-registration-form';

export type Form =
  | { type: 'UNKNOWN'; data: SetupSchema }
  | { type: 'APPLICANT'; data: ApplicantSchema }
  | { type: 'APPLICANT_MEMBER'; data: ApplicantMemberSchema }
  | { type: 'STUDENT'; data: StudentSchema }
  | { type: 'TEACHER'; data: TeacherSchema }
  | { type: 'OTHER'; data: OtherUserSchema };

type AuthFormState = {
  step: number;
  role: UserType;
  totalSteps: Record<UserType, number>;
  formData: Form | null;
};

type AuthFormContextType = AuthFormState & {
  appendFormData: (formData: Form) => void;
  nextStep: () => void;
  prevStep: () => void;
  setTotalSteps: (type: UserType, steps: number) => void;
  startRegistration: () => void;
  completeRegistration: () => Promise<void>;
};

const initialState: AuthFormState = {
  step: 0,
  role: 'UNKNOWN',
  totalSteps: {
    UNKNOWN: Infinity,
    APPLICANT: Infinity,
    APPLICANT_MEMBER: Infinity,
    STUDENT: Infinity,
    TEACHER: Infinity,
    OTHER: Infinity,
  },
  formData: null,
};

const loadState = (): AuthFormState => {
  try {
    const savedState = localStorage.getItem(STORAGE_KEY);
    return savedState ? JSON.parse(savedState) : initialState;
  } catch {
    return initialState;
  }
};

const saveState = (state: AuthFormState) => {
  localStorage.setItem(STORAGE_KEY, JSON.stringify(state));
};

const AuthFormContext = createContext<AuthFormContextType | undefined>(undefined);

export const AuthFormProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const auth = useAuth();
  const [state, setState] = useState<AuthFormState>(loadState);

  useEffect(() => {
    saveState(state);
  }, [state]);

  const appendFormData = (formData: Form) => {
    setState((prevState) => {
      if (formData.type === 'UNKNOWN') {
        return { ...prevState, role: formData.data.userType, formData };
      }
      return { ...prevState, formData };
    });
  };

  const nextStep = () => {
    setState((prevState) => ({ ...prevState, step: prevState.step + 1 }));
  };

  const prevStep = () => {
    setState((prevState) => ({ ...prevState, step: prevState.step - 1 }));
  };

  const setTotalSteps = (type: UserType, steps: number) => {
    setState((prevState) => ({ ...prevState, totalSteps: { ...prevState.totalSteps, [type]: steps } }));
  };

  const startRegistration = () => {
    setState({ ...initialState, step: 1 });
  };

  const completeRegistration = async () => {
    if (!state.formData) return;
    const dto = mapFormDataToDto(state.formData);
    const response = await registerUser(dto);
    auth.setAccessToken(response?.access_token);
    setState(initialState);
    localStorage.removeItem(STORAGE_KEY);
  };

  const value: AuthFormContextType = {
    ...state,
    appendFormData,
    nextStep,
    prevStep,
    setTotalSteps,
    startRegistration,
    completeRegistration,
  };

  return <AuthFormContext.Provider value={value}>{children}</AuthFormContext.Provider>;
};

export const useAuthForm = (): AuthFormContextType => {
  const context = useContext(AuthFormContext);
  if (context === undefined) {
    throw new Error('useAuthForm must be used within an AuthFormProvider');
  }
  return context;
};
