import { SpecialityFilter } from '../../hooks/useSpecialitiesFilters';
import { CheckMarkIcon } from '../../icons/CheckMarkIcon';
import checkboxStyles from '../FilterCheckbox/FilterCheckbox.module.scss';

type CheckboxProps = {
  filterGroup: SpecialityFilter;
  onSelectMultiple: (ids: number[], value: boolean) => void;
};

export function SpecialityCheckbox({ filterGroup, onSelectMultiple }: CheckboxProps) {
  const selectAll = () => {
    onSelectMultiple(
      filterGroup.branchRelatedSpecialities.map((item) => item.id),
      !filterGroup.isChecked,
    );
  };

  return (
    <label className={checkboxStyles.wrapCheckbox}>
      <input
        type='checkbox'
        onChange={selectAll}
        checked={filterGroup.isChecked}
        className={checkboxStyles.inputCheckBox}
      />
      <CheckMarkIcon className={checkboxStyles.checkboxBig} data-radio={false} />
    </label>
  );
}
