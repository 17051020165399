import AbitlyLogo from '../../../../components/Logo/AbitlyLogo';
import styles from '../../auth.module.scss';
import CrossButton from '../../components/CrossButton';
import RegisterCard from '../../components/RegisterCard';

export default function RegisterPage() {
  return (
    <>
      <div className={styles.mobile}>
        <div className={styles.registerPageWrapper}>
          <div className={styles.registerBackground}>
            <AbitlyLogo to='/' className={styles.abitlyImage} />
          </div>
          <CrossButton className={styles.topRightCorner} />
          <div className={styles.card}>
            <RegisterCard />
          </div>
        </div>
      </div>
      <div className={styles.desktop}>
        <div className={styles.loginPageWrapper}>
          <div className={styles.card}>
            <CrossButton className={styles.topRightCorner} />
            <AbitlyLogo to='/' className={styles.abitlyImage} />
            <RegisterCard />
          </div>
        </div>
      </div>
    </>
  );
}
