const API = {
  FILTERS: {
    REGIONS: '/regions',
    SPECIALITIES: '/specialities',
    QUALIFICATIONS: '/qualifications',
    UNIVERSITIES: '/universities',
    UNIVERSITY_INFO: '/info',
    UNIVERSITY_STRUCTURE: '/structure',
    UNIVERSITY_SPECIALITIES: '/specialities',
    EDUCATION_FORMS: '/educationForms',
    FACULTIES: '/faculties',
  },
  OFFERS: {
    SEARCH_SUBJECTS: '/offers/subjects',
    SEARCH_UNIVERSITIES: '/offers/universities',
    SEARCH_YEAR: '/offers/year',
    OFFER: '/offers',
    COUNT: '/offers/count',
  },
  AUTH: {
    AUTH_GOOGLE: '/auth/google',
    AUTH_GOOGLE_REDIRECT: '/auth/google/redirect',
    REGISTRATION: '/auth/complete-registration',
    PROFILE: '/auth/profile',
    ME: '/auth/me',
    DELETE: '/auth/user',
  },
  STUDENTS: {
    BASE: '/students',
    LINK: '/students/link',
  },
  CALCULATOR: {
    SPECIALITIES: '/specialities/calculator',
  },
};

export { API };
