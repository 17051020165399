import { useEffect, useState } from 'react';

import nextFrame from '../../utils/nextFrame';

export const useAnimated = (def: boolean, delay = 250) => {
  const [state, setState] = useState<boolean>(def);
  const [delayedState, setDelayedState] = useState(state);
  useEffect(() => {
    if (!state) {
      const timeout = setTimeout(() => setDelayedState(state), delay);
      return () => clearTimeout(timeout);
    }
  }, [state]);

  return [
    state,
    delayedState,
    (newState: boolean) => {
      if (newState) {
        setDelayedState(true);
        nextFrame(() => setState(newState));
      } else setState(newState);
    },
  ] as const;
};
