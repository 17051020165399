import cx from 'classnames';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import './index.scss';

const Button = ({
  children,
  className,
  style,
  disabled,
  onClick,
  to,
  preserveSearch = true,
  customSearch,
  isLink = false,
  skipLanguagePrefix = false,
}: {
  className?: string;
  style?: React.CSSProperties;
  disabled?: boolean;
  onClick?: () => void;
  children?: React.ReactNode;
  to?: string;
  preserveSearch?: boolean;
  customSearch?: string;
  isLink?: boolean;
  skipLanguagePrefix?: boolean;
}) => {
  const location = useLocation();
  const { i18n } = useTranslation();

  if ((to || isLink) && !disabled) {
    let searchParams = '';
    if (customSearch) {
      searchParams = customSearch;
    } else if (preserveSearch) {
      searchParams = location.search;
    }

    let languageAwareTo = to || '#';

    if (!skipLanguagePrefix && to) {
      if (!to.startsWith('http') && !to.startsWith('#')) {
        if (!to.match(/^\/[a-z]{2}($|\/)/) && !to.startsWith(`/${i18n.language}`)) {
          languageAwareTo = `/${i18n.language}${to.startsWith('/') ? to : `/${to}`}`;
        }
      }
    }

    const finalTo = searchParams ? { pathname: languageAwareTo, search: searchParams } : languageAwareTo;

    return (
      <Link to={finalTo} className={cx('AppButton', className)} style={style} onClick={onClick}>
        {children}
      </Link>
    );
  }

  return (
    <button className={cx('AppButton', { disabled }, className)} disabled={disabled} style={style} onClick={onClick}>
      {children}
    </button>
  );
};

export default Button;
