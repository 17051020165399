import { Controller, useFormContext } from 'react-hook-form';

import { ChipButton } from '../../../components/ChipButton/ChipButton';
import styles from '../auth.module.scss';

type Chip = {
  icon?: string;
  key: string;
  value: string;
};

type ChipsInputProps = {
  name: string;
  chips: Chip[];
};

export default function ChipsInput({ name, chips }: ChipsInputProps) {
  const { control } = useFormContext();

  return (
    <div>
      <Controller
        name={name}
        control={control}
        defaultValue={[]}
        render={({ field }) => (
          <div className={styles.chipsWrapper}>
            <div className={styles.chipsInput}>
              {chips.map((chip) => {
                const isSelected = field.value === chip.key;
                return (
                  <ChipButton
                    key={chip.key}
                    selected={isSelected}
                    style={{ gap: '4px' }}
                    onClick={() => {
                      field.onChange(chip.key);
                    }}
                  >
                    {chip.icon && <img src={chip.icon} alt={chip.value} />}
                    {chip.value}
                  </ChipButton>
                );
              })}
            </div>
          </div>
        )}
      />
    </div>
  );
}
