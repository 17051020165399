import { useQuery } from '@tanstack/react-query';

import { getEducationalForms } from '../../api/filters';

export const useEducationForms = () => {
  const { data, isLoading, error } = useQuery({
    queryKey: ['educationForms'],
    queryFn: () => getEducationalForms(),
  });

  return { data, isLoading, error };
};
