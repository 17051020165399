import { z } from 'zod';

import { userTypeSchema } from './user-type';

export const setupSchema = z.object({
  name: z.string().min(1),
  userType: userTypeSchema,
  termsAgreement: z.boolean().refine((value) => value === true, {
    message: 'You must agree to the terms and conditions',
  }),
});

export type SetupSchema = z.infer<typeof setupSchema>;
