import { useTranslation } from 'react-i18next';

import { RuleCalcFormula } from './RuleCalcFormula';
import styles from './RulesOfCalc.module.scss';

export function RulesOfCalc() {
  const { t } = useTranslation();

  return (
    <div className={styles.wrapper}>
      <h2 className='h1' id='howToCalc'>
        {t('calculator.calcRules.title')}
      </h2>
      <RuleCalcFormula />
      <div className={styles.formula}>
        <p>
          <span style={{ color: '#C500B1' }}>К1</span>, <span style={{ color: '#0052C5' }}>К2</span>,{' '}
          <span style={{ color: '#00C535' }}>К3</span>, <span style={{ color: '#C54200' }}>К4</span>-{' '}
          {t('calculator.calcRules.formula.k')}
        </p>
        <p>П1, П2, П3, П4 - {t('calculator.calcRules.formula.p')}</p>
        <p>К4max - {t('calculator.calcRules.formula.k4')}</p>
        <p>КТ - {t('calculator.calcRules.formula.kt')}</p>
        <p>ТК - {t('calculator.calcRules.formula.tk')}</p>
        <p>ОУ - {t('calculator.calcRules.formula.oy')}</p>
      </div>
      <div className={styles.textWrapper}>
        <p className={styles.descWrapper}>
          <span>{t('calculator.calcRules.canBeApplied')}</span> {t('calculator.calcRules.calculationUpdate')}
        </p>
        <p className={styles.subtitle}>
          {t('calculator.calcRules.additionalCoefs.desc1')}{' '}
          <span>{t('calculator.calcRules.additionalCoefs.coefs')}</span>{' '}
        </p>
        <p style={{ marginBottom: 8 }}>{t('calculator.calcRules.additionalCoefs.regionCoef.title')}</p>
        <ul className={styles.coefList}>
          <li>
            <span> 1.07 — </span> {t('calculator.calcRules.additionalCoefs.regionCoef.1,07')}
          </li>
          <li>
            <span> 1.04 — </span>
            {t('calculator.calcRules.additionalCoefs.regionCoef.1,04')}
          </li>
          <li>
            <span> 1.00 — </span>
            {t('calculator.calcRules.additionalCoefs.regionCoef.1,00')}
          </li>
        </ul>
        <p style={{ marginBottom: 8 }}>{t('calculator.calcRules.additionalCoefs.branchCoef.title')}</p>
        <ul className={styles.coefList}>
          <li>
            <span> 1.02 — </span> {t('calculator.calcRules.additionalCoefs.branchCoef.1,02')}
          </li>
          <li>
            <span> 1.00 — </span>
            {t('calculator.calcRules.additionalCoefs.branchCoef.1,00')}
          </li>
        </ul>
        <p>{t('calculator.calcRules.ifExceeded')}</p>
      </div>
    </div>
  );
}
