import { useQuery } from '@tanstack/react-query';
import { useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';

import { getUniversitySpecialitiesById } from '../../../../api/filters';
import { useToggle } from '../../../../hooks/utils/useToggle';
import { parseSlug } from '../utils/parseSlug';

export const useUniversitySpecialties = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [sortAlphabetically, toggleSorting] = useToggle(false);
  const { slug, lang } = useParams<{ slug: string; lang: string }>();

  const { id } = parseSlug(slug);

  const { data, isLoading } = useQuery({
    queryKey: ['universitySpecialities', id],
    queryFn: () => getUniversitySpecialitiesById(id),
    enabled: !!id,
  });

  const filteredSpecialities = useMemo(() => {
    if (!data) return [];
    return data.filter(
      (speciality) =>
        speciality.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        speciality.code.toLowerCase().includes(searchTerm.toLowerCase()) ||
        speciality.relatedFaculties.some((faculty) =>
          faculty.name[lang].toLowerCase().includes(searchTerm.toLowerCase()),
        ),
    );
  }, [data, searchTerm]);

  const sortedSpecialities = useMemo(() => {
    if (filteredSpecialities.length === 0) return [];
    return [...filteredSpecialities].sort((a, b) => {
      const compareResult = a.name.localeCompare(b.name);
      return sortAlphabetically ? compareResult : -compareResult;
    });
  }, [filteredSpecialities, sortAlphabetically]);

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  };

  return {
    searchTerm,
    sortAlphabetically,
    isLoading,
    sortedSpecialities,
    handleSearchChange,
    toggleSorting,
  };
};
