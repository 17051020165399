export const REQUIRED_SUBJECTS = [
  { name: 'Українська мова', translation: 'profile.subjects.ukrainian' },
  { name: 'Математика', translation: 'profile.subjects.mathematics' },
  { name: 'Історія України', translation: 'profile.subjects.history' },
];

export const OPTIONAL_SUBJECTS = [
  { name: 'Українська література', translation: 'profile.subjects.ukrainianLiterature' },
  { name: 'Іноземна мова', translation: 'profile.subjects.foreignLanguage' },
  { name: 'Фізика', translation: 'profile.subjects.physics' },
  { name: 'Хімія', translation: 'profile.subjects.chemistry' },
  { name: 'Біологія', translation: 'profile.subjects.biology' },
  { name: 'Географія', translation: 'profile.subjects.geography' },
];
