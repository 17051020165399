import { TextField, TextFieldProps } from '@mui/material';
import { Controller, useFormContext, FieldValues, Path, PathValue } from 'react-hook-form';

type FormTextFieldProps<T extends FieldValues> = {
  name: Path<T>;
  label: string;
  defaultValue?: PathValue<T, Path<T>>;
  validation?: (value: string) => boolean;
} & Omit<TextFieldProps, 'name'>;

const FormTextField = <T extends FieldValues>({
  name,
  label,
  defaultValue = '' as PathValue<T, Path<T>>,
  validation,
  ...rest
}: FormTextFieldProps<T>) => {
  const { control } = useFormContext<T>();

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      render={({ field: { value, ...fieldProps } }) => (
        <TextField
          {...fieldProps}
          onChange={(e) => {
            const isValid = validation ? validation(e.target.value) : true;
            if (isValid) {
              fieldProps.onChange(e);
            }
          }}
          value={value ?? defaultValue}
          label={label}
          variant='outlined'
          fullWidth
          {...rest}
        />
      )}
    />
  );
};

export default FormTextField;
