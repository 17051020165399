import React from 'react';

import { DetailedOffer } from '../../types/offer';

import { Link } from 'react-router-dom';
import { langPath } from '../../../../utils/langPath';
import styles from './Offer.module.scss';
import { groupSubjects } from './utils/groupSubjects';

export const CoefficientsCard = ({ offer }: { offer: DetailedOffer }) => {
  const subjectGroups = groupSubjects(offer.concursSubjects);

  return (
    <div className={styles.cardWrap}>
      <div className={styles.title}>Предмети НМТ та коєфіцієнти 2024 року</div>
      <div className={styles.card}>
        <table className={styles.table}>
          <thead>
            <tr>
              <th>Предмети</th>
              <th>Коєф.</th>
              <th>Мін. бал</th>
            </tr>
          </thead>
          <tbody>
            {subjectGroups.map((group, j) => (
              <React.Fragment key={j}>
                {group.map((subject, i) => (
                  <tr className={i === group.length - 1 ? styles.withBorder : styles.withoutBorder} key={`${j}-${i}`}>
                    <td>{subject.name}</td>
                    {i === 0 && (
                      <>
                        <td rowSpan={group.length} className={styles.bold}>
                          {subject.coeff}
                        </td>
                        <td rowSpan={group.length} className={styles.bold}>
                          {subject.minScore ?? 1}
                        </td>
                      </>
                    )}
                  </tr>
                ))}
              </React.Fragment>
            ))}
          </tbody>
        </table>
      </div>

      <Link to={langPath('/blog/nmt-coefficients')}>Коефіцієнти НМТ 2025 року →</Link>
    </div>
  );
};
