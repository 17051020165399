import { Typography } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { getUniversityInfoById } from '../../../api/filters';
import CustomBreadcrumbs from '../../../components/Breadcrumbs/Breadcrumbs';

import { TabNavigation } from './components/TabNavigation/TabNavigation';
import { UniversityTabs } from './components/UniversityTabs/UniversityTabs';
import styles from './UniversityPage.module.scss';
import { parseSlug } from './utils/parseSlug';

const UniversityPage: React.FC = () => {
  const { t, i18n } = useTranslation();

  const { slug } = useParams<{ slug: string }>();

  const { id } = parseSlug(slug);

  const { data, isLoading } = useQuery({
    queryKey: ['universityInfo', id],
    queryFn: () => getUniversityInfoById(id),
  });

  const breadcrumbs = [
    {
      title: t('universityPage.breadcrumbs.offers'),
      href: '/offers',
    },
    {
      title: data?.shortName[i18n.language],
      href: `/universities/${slug}`,
    },
  ];

  return (
    <div className={styles.universityPageContainer}>
      <CustomBreadcrumbs items={breadcrumbs} />

      <div className={styles.universityHeader}>
        <Typography variant='h1'>{data?.universityName[i18n.language]}</Typography>
      </div>

      <TabNavigation />

      {isLoading ? (
        <div className={styles.wrapperLoading}>{t('universityPage.common.loading')}</div>
      ) : (
        <UniversityTabs universityData={data} />
      )}
    </div>
  );
};

export default UniversityPage;
