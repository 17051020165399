import { Typography } from '@mui/material';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { Faculty, Institution, Speciality } from '../../../../hooks/useUniversityStructure';
import styles from '../StructureTab.module.scss';

interface StructureItemProps {
  name: string;
  specialities: Speciality[];
}

const StructureItem: FC<StructureItemProps> = ({ name, specialities }) => {
  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <span className={styles.icon}>
          <img src='/emoji/graduation-cap.svg' alt='graduation-cap' />
        </span>
        <Typography variant='h4'>{name}</Typography>
      </div>

      <ul className={styles.specialitiesList}>
        {specialities.map((speciality) => (
          <li key={speciality.code} className={styles.specialityItem}>
            <span className={styles.specialityId}>{speciality.code}</span>
            <Typography>{speciality.name}</Typography>
          </li>
        ))}
      </ul>
    </div>
  );
};

interface StructureListProps {
  title: string;
  items: (Faculty | Institution)[];
  isLoading: boolean;
}

export const StructureList: FC<StructureListProps> = ({ title, items, isLoading }) => {
  const { t, i18n } = useTranslation();
  const lang = i18n.language;

  return (
    <div className={styles.listContainer}>
      <Typography variant='h3' marginBottom='20px'>
        {title}
      </Typography>

      {isLoading ? (
        <span>{t('universityPage.common.loading')}</span>
      ) : (
        items.map((item) => <StructureItem key={item.id} name={item.name[lang]} specialities={item.specialities} />)
      )}
    </div>
  );
};
