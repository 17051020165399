import { useEffect } from 'react';

export const SmoothScroll = () => {
  useEffect(() => {
    const handleAnchorLinkClick = (e: MouseEvent) => {
      const target = e.target as HTMLElement;
      const anchor = target.closest('a');

      if (anchor && anchor.hash && anchor.hash.length > 1) {
        e.preventDefault();

        const targetElement = document.querySelector(anchor.hash);

        if (targetElement) {
          const elementPosition = targetElement.getBoundingClientRect().top;
          const offsetPosition = elementPosition + window.pageYOffset - 16;

          window.scrollTo({
            top: offsetPosition,
            behavior: 'smooth',
          });

          history.pushState(null, '', anchor.hash);
        }
      }
    };

    document.addEventListener('click', handleAnchorLinkClick);

    return () => {
      document.removeEventListener('click', handleAnchorLinkClick);
    };
  }, []);

  return null;
};
