import { ReactNode } from 'react';

import styles from './OfferSubGroup.module.scss';

type OfferGroupProps = {
  title: ReactNode | string;
  children?: ReactNode | undefined;
};

export const OfferSubGroup = (props: OfferGroupProps) => {
  return (
    <div className={styles.wrap}>
      <div className={styles.title}>{props.title}</div>
      {props.children}
    </div>
  );
};
