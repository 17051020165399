import { Typography } from '@mui/material';

import { NoQueryPage } from '../../components/NoQueryPage/NoQueryPage';
import { SEO } from '../../components/seo/Seo';
import './TermsOfService.scss';

const TermsOfService = () => {
  return (
    <>
      <SEO titleKey='seo:terms.title' descriptionKey='seo:terms.description' canonicalPath='/terms' />

      <div className='page-wrap'>
        <div className='block'>
          <Typography variant='h1'>Угода користувача</Typography>
          <Typography variant='body2'>
            <b>Дата набирання чинності:</b> 16.01.2025
          </Typography>
        </div>
        <Typography variant='body2'>
          Отримуючи доступ до цього веб-сайту, ви погоджуєтесь із цими Умовами використання (&quot;Умови&quot;){' '}
          <span className='main-color'>Abitly.org</span>, усіма застосовними законами та нормативними актами, а також
          підтверджуєте свою відповідальність за дотримання чинного місцевого законодавства. Якщо ви не погоджуєтеся з
          будь-якими положеннями цих Умов, вам забороняється використовувати або отримувати доступ до веб-сайту{' '}
          <span className='main-color'>Abitly.org</span> і пов’язаних із ним сторінок (&quot;Сайт&quot;). Усі матеріали
          на цьому Сайті захищені чинним законодавством про авторські права та торговельні марки.
        </Typography>
        <div className='block'>
          <Typography variant='h1'>Відмова від відповідальності</Typography>
          <Typography variant='body2'>
            Матеріали на Сайті надаються &quot;як є&quot;. <span className='main-color'>Abitly.org</span> або
            уповноважений представник не надають жодних гарантій, явних чи неявних, і відмовляються від будь-яких інших
            гарантій, включаючи, але не обмежуючись, гарантії комерційної придатності, придатності для конкретної мети
            чи непорушення прав інтелектуальної власності. <span className='main-color'>Abitly.org</span> також не
            гарантує точність, достовірність або надійність використання матеріалів на цьому Сайті чи на інших
            пов&apos;язаних веб-сайтах.
          </Typography>
        </div>
        <div className='block'>
          <Typography variant='h1'>Обмеження відповідальності</Typography>
          <Typography variant='body2'>
            У жодному разі <span className='main-color'>Abitly.org</span> або її постачальники не несуть
            відповідальності за будь-які збитки (включаючи, але не обмежуючись, збитки через втрату даних або прибутку,
            чи через перерву в бізнесі), що виникають у результаті використання або неможливості використання матеріалів
            на цьому Сайті, навіть якщо <span className='main-color'>Abitly.org</span> було повідомлено усно чи письмово
            про можливість таких збитків. Оскільки у деяких юрисдикціях не допускаються обмеження непрямих гарантій чи
            обмеження відповідальності за непрямі або випадкові збитки, ці обмеження можуть не стосуватися вас.
          </Typography>
        </div>
        <div className='block'>
          <Typography variant='h1'>Зміни та виправлення</Typography>
          <Typography variant='body2'>
            Матеріали, розміщені на цьому Сайті, можуть містити технічні, типографічні або фотографічні помилки.{' '}
            <span className='main-color'>Abitly.org</span> не гарантує точність, повноту чи актуальність матеріалів. Ми
            залишаємо за собою право змінювати матеріали на Сайті у будь-який час без попередження, проте ми не
            зобов’язуємось оновлювати їх.
          </Typography>
        </div>
        <div className='block'>
          <Typography variant='h1'>Посилання</Typography>
          <Typography variant='body2'>
            Цей Сайт може містити посилання на сторонні веб-сайти. <span className='main-color'>Abitly.org</span> не
            контролює зміст таких веб-сайтів і не несе відповідальності за їхній зміст чи політики. Використання
            сторонніх веб-сайтів здійснюється на ваш власний ризик.
          </Typography>
        </div>
        <div className='block'>
          <Typography variant='h1'>Оновлення умов користування</Typography>
          <Typography variant='body2'>
            Ми можемо змінювати ці Умови користування у будь-який час без попереднього повідомлення. Використовуючи цей
            Сайт, ви погоджуєтеся з поточною версією Умов.
          </Typography>
        </div>
        <div className='block'>
          <Typography variant='h1'>Регулювання законодавством</Typography>
          <Typography variant='body2'>
            Ця Угода регулюється законодавством України, без урахування його колізійних норм.
          </Typography>
        </div>
        <div className='block'>
          <Typography variant='h1'>Зв&apos;яжіться з нами</Typography>
          <Typography variant='body2'>
            Якщо у вас є запитання або пропозиції, будь ласка, зв’яжіться з нами за адресою{' '}
            <a className='link' href='mailto:info@abitly.org'>
              info@abitly.org
            </a>
            .
          </Typography>
        </div>
      </div>
    </>
  );
};

const WrappedPrivacyPage = () => (
  <NoQueryPage>
    <TermsOfService />
  </NoQueryPage>
);

export default WrappedPrivacyPage;
