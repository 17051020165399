import { Box } from '@mui/material';
import { ChangeEvent, ReactNode, useMemo, useState } from 'react';

import { useToggle } from '../../../../hooks/utils/useToggle';
import { SpecialityFilter } from '../../hooks/useSpecialitiesFilters';
import { useSpecialitySearch } from '../../hooks/useSpecialitiesSearch';
import { ArrowIcon, ArrowIconDirection } from '../../icons/ArrowIcon';
import { CodeChip } from '../CodeChip/CodeChip';
import FiltersBlock from '../FilterBlock/FilterBlock';
import { Search } from '../Search/Search';
import { SubTitle } from '../SubTitle/SubTitle';

import { SpecialityDropdown } from './Dropdown';
import styles from './SpecialityFilterCard.module.scss';

const DEFAULT_ITEMS_TO_SHOW = 5;
const SHOW_MORE_PAGE = 15;

type SpecialityFilterCardProps = {
  title?: ReactNode;
  itemsToShow?: number;
  data: SpecialityFilter[];
  selectedFilters: number[];
  onSelect: (id: number | string) => void;
  onSelectMultiple: (ids: number[], value: boolean) => void;
};

export const SpecialityFilterCard = (props: SpecialityFilterCardProps) => {
  const [opened, toggle] = useToggle(true);
  const { searchValue, search, filteredFilters } = useSpecialitySearch(props.data);

  return (
    <FiltersBlock>
      {props.title && (
        <div className={styles.wrapBlock}>
          <SubTitle onClick={toggle}>
            <CodeChip className={styles.filterCount} size='big' code={props.selectedFilters?.length} padding={false} />
            {props.title}
          </SubTitle>
          <ArrowIcon
            direction={opened ? ArrowIconDirection.UP : ArrowIconDirection.DOWN}
            color={opened ? '#F59D33' : '#3C474E'}
            onClick={toggle}
          />
        </div>
      )}
      {opened && (
        <>
          <Search
            placeholder='Назва спеціальності або код'
            value={searchValue}
            onChange={(e: ChangeEvent<HTMLInputElement>) => search(e.target.value)}
          />
          <Filters {...props} data={filteredFilters} opened={searchValue.length > 0 ? true : undefined} />
        </>
      )}
    </FiltersBlock>
  );
};

type FiltersProps = {
  itemsToShow?: number;
  data?: SpecialityFilter[];
  opened?: boolean;
  onSelect: (id: number | string) => void;
  onSelectMultiple: (ids: number[], value: boolean) => void;
};

const Filters = (props: FiltersProps) => {
  const [showMoreClicks, setShowMoreClicks] = useState(0);
  const itemsToShow = props.itemsToShow || DEFAULT_ITEMS_TO_SHOW;

  const filtersDataToShow = useMemo(() => {
    const totalItemsToShow = Math.min(itemsToShow + showMoreClicks * SHOW_MORE_PAGE, props.data?.length ?? 0);
    return props.data?.slice(0, totalItemsToShow);
  }, [showMoreClicks, props.data, itemsToShow]);

  const countOfLeftFilters = useMemo(
    () => (props.data?.length ?? 0) - (filtersDataToShow?.length ?? 0),
    [filtersDataToShow, props.data],
  );

  return (
    <div>
      {filtersDataToShow?.map((item) => (
        <SpecialityDropdown
          key={item.branchName}
          filterGroup={item}
          opened={props.opened}
          onSelect={props.onSelect}
          onSelectMultiple={props.onSelectMultiple}
        />
      ))}
      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
        {countOfLeftFilters > 0 && (
          <p className={styles.showMore} onClick={() => setShowMoreClicks((prev) => prev + 1)}>
            Показати ще {Math.min(countOfLeftFilters, SHOW_MORE_PAGE)}
          </p>
        )}
        {showMoreClicks > 0 && (
          <p className={styles.showMore} onClick={() => setShowMoreClicks(0)}>
            Згорнути фільтри
          </p>
        )}
      </Box>
    </div>
  );
};
