import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import seoEN from '../locales/en/seo.json';
import translationEN from '../locales/en/translation.json';
import seoUK from '../locales/uk/seo.json';
import translationUK from '../locales/uk/translation.json';

import { ENVIRONMENTS, ENVS } from './constants';

export const LANGUAGES = {
  en: 'en',
  uk: 'uk',
};

export interface LocalisedString {
  [LANGUAGES.uk]: string;
  [LANGUAGES.en]: string;
}

export const DEFAULT_LANGUAGE = LANGUAGES.uk;

i18n.use(initReactI18next).init({
  resources: {
    [LANGUAGES.en]: {
      translation: translationEN,
      seo: seoEN,
    },
    [LANGUAGES.uk]: {
      translation: translationUK,
      seo: seoUK,
    },
  },
  lng: DEFAULT_LANGUAGE,
  fallbackLng: DEFAULT_LANGUAGE,
  debug: ENVS.CURRENT_ENV === ENVIRONMENTS.local,
  interpolation: {
    escapeValue: false,
  },
  react: {
    useSuspense: true,
  },
});

export default i18n;
