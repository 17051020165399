import { Box, Typography } from '@mui/material';
import { ReactNode } from 'react';
import styles from '../AboutUs.module.scss';

export default function ValuesSection() {
  return (
    <Box className={styles.sectionWrap} sx={{ paddingTop: '24px', paddingBottom: '24px' }}>
      <Typography variant='h2'>Наші цінності</Typography>
      <Box className={styles.valuesSection}>
        <ValueCard icon='/emoji/Unlocked.png' title='Незалежність і прозорість'>
          Ми <b>не рекламуємо університети</b> за гроші та не займаємося просуванням закордонних освітніх установ. У
          центрі — лише чесна інформація й справедливий доступ до якісної освіти в Україні.
        </ValueCard>
        <ValueCard icon='/emoji/Brain.png' title='Популяризація пріоритетних спеціальностей'>
          Ми підтримуємо та висвітлюємо <b>фундаментальні та інженерні напрямки</b>, які критично важливі для розвитку
          української економіки.
        </ValueCard>
        <ValueCard icon='/emoji/Handshake.png' title='Єдність заради якісної освіти'>
          Ми відкриті до співпраці з іншими освітніми проєктами та ініціативами, <b>які поділяють наші цінності</b> і
          працюють задля розвитку освіти в Україні.
        </ValueCard>
        <ValueCard icon='/emoji/Compass.png' title='Студентоцентризм'>
          Усе, що ми створюємо, базується на <b>потребах та інтересах студентів</b>. Ми хочемо, щоб кожен робив
          усвідомлений вибір освіти та розкривав свій потенціал.
        </ValueCard>
        <ValueCard icon='/emoji/Trophy.png' title='Визнання досягнень'>
          Ми із задоволенням і <b>безкоштовно ділимося новинами</b> про перемоги та успіхи українських університетів —
          хакатони, студентські стартапи, олімпіади та інше.
          <br />
          📧 Надсилай новини: info@abitly.org
        </ValueCard>
      </Box>
    </Box>
  );
}

type ValueCardProps = {
  icon: string;
  title: string;
  children: ReactNode;
};

function ValueCard({ icon, title, children }: ValueCardProps) {
  return (
    <Box className={styles.valueCard}>
      <img className={styles.valueCardImage} src={icon} alt={`${title} icon`}></img>
      <Typography variant='h3'>{title}</Typography>
      <Typography variant='body1' component='div'>
        {children}
      </Typography>
    </Box>
  );
}
