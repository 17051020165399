import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { getGoogleEndpoint } from '../../../api/auth';
import Button from '../../../components/ButtonV2/Button';
import { langPath } from '../../../utils/langPath';
import styles from '../auth.module.scss';

export default function RegisterCard() {
  const { t } = useTranslation();

  const googleAuth = () => {
    const url = getGoogleEndpoint();
    window.location.href = url;
  };

  return (
    <>
      <Typography variant='h1'>{t('auth.register')}</Typography>
      <Typography variant='body1' className={styles.secondaryText}>
        {t('auth.register-welcome')}
      </Typography>
      <Button onClick={() => googleAuth()} icon='/images/auth/google.svg'>
        {t('auth.google-register')}
      </Button>
      <Typography variant='body1' className={styles.secondaryText}>
        {t('auth.already-account')}
      </Typography>

      <Link to={langPath('/auth/login')}>
        <Typography variant='body1'>{t('auth.register-login')}</Typography>
      </Link>
    </>
  );
}
