import { zodResolver } from '@hookform/resolvers/zod';
import { FormProvider, useForm, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import Button from '../../../../../components/ButtonV2/Button';
import FormAutocomplete from '../../../../../components/mui-form/FormAutocomplete';
import { useRegionOptions } from '../../../../../hooks/filters/useRegions';
import { useUniversityOptions } from '../../../../../hooks/filters/useUniversities';
import Label from '../../../components/Label';
import Section from '../../../components/Section';
import ValidationError from '../../../components/ValidationError';
import {
  ApplicantPreferencesSchema,
  applicantPreferencesSchema,
  ApplicantSchema,
} from '../../../schema/applicant-form';
import { useAuthForm } from '../../../state/AuthFormContext';

export default function PreferencesForm() {
  const { t } = useTranslation();
  const authStore = useAuthForm();

  const form = useForm<ApplicantPreferencesSchema>({
    resolver: zodResolver(applicantPreferencesSchema),
    defaultValues: (authStore.formData?.data as ApplicantSchema).preferencesData || {
      cityPreferences: [],
      universityPreferences: [],
    },
  });

  const { regionOptions } = useRegionOptions();

  const selectedRegions = useWatch({ control: form.control, name: 'cityPreferences' });
  const { universityOptions } = useUniversityOptions(selectedRegions);

  const onSubmit = (data: ApplicantPreferencesSchema) => {
    authStore.appendFormData({
      type: 'APPLICANT',
      data: {
        ...authStore.formData?.data,
        preferencesData: data,
      },
    });
    authStore.nextStep();
  };

  return (
    <FormProvider {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)}>
        <Section>
          <Label>{t('auth.registration-flow.preferences.applicant-cities.label')}</Label>
          <FormAutocomplete
            name='cityPreferences'
            label='Обери інше місто'
            options={regionOptions}
            multiple
            placeholder={t('auth.registration-flow.preferences.applicant-cities.placeholder')}
          />
          <ValidationError error={form.formState.errors.cityPreferences} />
        </Section>
        <Section>
          <Label>{t('auth.registration-flow.preferences.applicant-universities.label')}</Label>
          <FormAutocomplete
            name='universityPreferences'
            options={universityOptions}
            label={t('profile.enrollmentInfo.universities')}
            multiple
            placeholder={t('auth.registration-flow.preferences.applicant-universities.placeholder')}
          />
          <ValidationError error={form.formState.errors.universityPreferences} />
        </Section>
        <Button disabled={!form.formState.isValid} type='submit'>
          {t('auth.registration-flow.buttons.complete')}
        </Button>
      </form>
    </FormProvider>
  );
}
