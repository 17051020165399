import { SpecialityWithCoefficients } from '../../../api/types/specialities';

export const placeholderSpeciality: Record<keyof SpecialityWithCoefficients['coefficients'], string> = {
  ukrainian: '__',
  mathematics: '__',
  history: '__',
  foreignLanguage: '__',
  biology: '__',
  physics: '__',
  chemistry: '__',
  ukrainianLiterature: '__',
  geography: '__',
  creativeCompetition: '__',
};
