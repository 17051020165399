import { Box } from '@mui/material';
import * as React from 'react';
import { useLocation } from 'react-router-dom';
import { CSSTransition } from 'react-transition-group';

import { SEO } from '../../components/seo/Seo';
import { OfferWelcomePage } from '../Offers/pages/OfferWelcomePage/OfferWelcomePage';

// TODO: remove duplicates
const AppContentAnimated = ({ children }: { children: React.ReactElement }) => {
  const location = useLocation();

  return (
    <div className='AppContentAnimated'>
      <CSSTransition key={location.pathname} in timeout={1000}>
        <div className='AppContentAnimated_Block'>{children}</div>
      </CSSTransition>
    </div>
  );
};

const Home = () => {
  return (
    <>
      <SEO titleKey='seo:home.title' descriptionKey='seo:home.description' canonicalPath='/' />
      <AppContentAnimated>
        <Box width='100%' display='flex' minHeight='100%' alignItems='flex-start' justifyContent='center'>
          <OfferWelcomePage />
        </Box>
      </AppContentAnimated>
    </>
  );
};

export default Home;
