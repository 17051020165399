import { useMediaQuery } from '@mui/material';

// Custom hook for chart responsiveness
export const useResponsiveChart = () => {
  const isXSmall = useMediaQuery('(max-width: 480px)');
  const isSmall = useMediaQuery('(max-width: 600px)');
  const isMedium = useMediaQuery('(max-width: 768px)');
  const isLarge = useMediaQuery('(max-width: 1024px)');

  const getChartHeight = () => {
    if (isXSmall) return 350;
    if (isSmall) return 400;
    if (isMedium) return 450;
    if (isLarge) return 500;
    return 600;
  };

  const getChartMargins = () => {
    if (isXSmall) {
      return { top: 10, right: 10, left: -20, bottom: 10 };
    }
    if (isSmall) {
      return { top: 15, right: 15, left: -10, bottom: 10 };
    }
    return { top: 20, right: 30, left: -10, bottom: 10 };
  };

  const getLabelFontSize = () => {
    if (isXSmall) return 10;
    if (isSmall) return 12;
    return 14;
  };

  const getStrokeWidth = () => {
    if (isXSmall) return 1;
    if (isSmall) return 1.5;
    return 2;
  };

  const getTickCount = () => {
    if (isXSmall) return 3;
    if (isSmall) return 4;
    return 5;
  };

  const getLegendPosition = () => {
    return {
      verticalAlign: 'top' as const,
      align: isSmall ? ('right' as const) : ('center' as const),
      dy: -10,
      iconSize: isXSmall ? 8 : isSmall ? 10 : 14,
      wrapperStyle: {
        fontSize: getLabelFontSize(),
        marginTop: -10,
      },
    };
  };

  const calculateYAxisTicks = (min: number, max: number) => {
    const count = getTickCount();
    const ticks = [];
    const step = Math.floor((max - min) / (count - 1));

    for (let i = 0; i < count; i++) {
      const value = min + i * step;
      ticks.push(Math.round(value));
    }

    if (ticks[ticks.length - 1] !== max) {
      ticks[ticks.length - 1] = max;
    }

    return ticks;
  };

  return {
    isXSmall,
    isSmall,
    isMedium,
    calculateYAxisTicks,
    isLarge,
    getChartHeight,
    getChartMargins,
    getLabelFontSize,
    getStrokeWidth,
    getTickCount,
    getLegendPosition,
  };
};
