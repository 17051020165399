import { Box } from '@mui/material';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { toast } from 'react-hot-toast';
import { useTranslation } from 'react-i18next';

import { updateUser } from '../../../api/auth';
import AbitlyLogo from '../../../components/Logo/AbitlyLogo';
import { NoQueryPage } from '../../../components/NoQueryPage/NoQueryPage';
import { useAuth } from '../../Auth/state/AuthContext';
import DeleteProfileButton from '../components/DeleteProfileButton';
import Header from '../components/Header';
import { pages } from '../constants/pages';
import styles from '../profile.module.scss';
import { UserForm } from '../schema/user-form';
import { adaptFormData, formToApiDto } from '../utils/data-adapter';

export default function ProfilePage() {
  const { user } = useAuth();
  const queryClient = useQueryClient();
  const form = useForm<UserForm>();
  const { t } = useTranslation();

  useEffect(() => {
    if (!user) return;
    form.reset(adaptFormData(user));
  }, [user]);

  const Page = pages[user?.userType ?? 'UNKNOWN'];

  const mutation = useMutation({ mutationFn: updateUser });

  const onSubmit = async (data: UserForm) => {
    const updateDto = formToApiDto(data);
    await toast.promise(mutation.mutateAsync(updateDto), {
      loading: t('profile.update.loading'),
      success: t('profile.update.success'),
      error: t('profile.update.error'),
    });
    queryClient.invalidateQueries({ queryKey: ['user'] });
  };

  return (
    <NoQueryPage>
      <FormProvider {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)}>
          <Header />
          <Box className={styles.profilePageWrapper}>
            <Page />
            <DeleteProfileButton />
            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
              <AbitlyLogo width={68} />
            </Box>
          </Box>
        </form>
      </FormProvider>
    </NoQueryPage>
  );
}
