import { Route, Routes, useLocation } from 'react-router-dom';

import { NoQueryPage } from '../../components/NoQueryPage/NoQueryPage';

import CallbackPage from './pages/Callback/CallbackPage';
import LoginPage from './pages/Login/LoginPage';
import RegisterPage from './pages/Register/RegisterPage';
import RegisterCompleted from './pages/RegisterCompleted/RegisterCompleted';
import RegisterMultiform from './pages/RegisterMultiform/RegisterMultiform';
import { AuthFormProvider } from './state/AuthFormContext';

export const AuthRoutes = () => {
  const location = useLocation();

  return (
    <NoQueryPage>
      <AuthFormProvider>
        <Routes location={location}>
          <Route path='/login' element={<LoginPage />} />
          <Route path='/callback' element={<CallbackPage />} />
          <Route path='/register' element={<RegisterPage />} />
          <Route path='/register-completed' element={<RegisterCompleted />} />
          <Route path='/register/form' element={<RegisterMultiform />} />
        </Routes>
      </AuthFormProvider>
    </NoQueryPage>
  );
};
