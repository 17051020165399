import { useTranslation } from 'react-i18next';
import { TooltipProps } from 'recharts';

export const CustomTooltip = ({ active, payload, label }: TooltipProps<number, string>) => {
  const { t } = useTranslation();

  if (active && payload && payload.length) {
    const uniqueSubjects = new Map();

    payload.forEach((entry) => {
      const dataKey = entry.dataKey?.toString() || '';
      const subjectKey = dataKey.endsWith('Line') ? dataKey.slice(0, -4) : dataKey;
      if (!uniqueSubjects.has(subjectKey) || dataKey === subjectKey) {
        uniqueSubjects.set(subjectKey, entry);
      }
    });

    const entriesToShow = Array.from(uniqueSubjects.values());

    if (entriesToShow.length === 0) {
      return null;
    }

    return (
      <div
        style={{
          backgroundColor: 'white',
          padding: '10px',
          border: '1px solid #ccc',
          borderRadius: '4px',
        }}
      >
        <p style={{ fontSize: '16px', fontWeight: 500 }}>
          {t('calculator.graph.score')} <span style={{ fontWeight: 700 }}>{label}</span> {t('calculator.graph.reason')}:
        </p>
        {entriesToShow.map((entry, index) => {
          const dataKey = entry.dataKey?.toString() || '';
          const subjectKey = dataKey.endsWith('Line') ? dataKey.slice(0, -4) : dataKey;

          const subjectName = t(`calculator.subjects.genitive.${subjectKey}`);

          return (
            <p key={`item-${index}`} style={{ color: entry.color }}>
              <span style={{ textTransform: 'capitalize' }}>{t('calculator.calculatedGrade.with')}</span>{' '}
              {`${subjectName}: ${
                entry.value !== undefined && entry.value !== null
                  ? typeof entry.value === 'number'
                    ? entry.value.toFixed(1)
                    : entry.value
                  : '-'
              }`}
            </p>
          );
        })}
      </div>
    );
  }

  return null;
};
