import { zodResolver } from '@hookform/resolvers/zod';
import { Typography } from '@mui/material';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import Button from '../../../../../components/ButtonV2/Button';
import Checkbox from '../../../../../components/checkbox';
import FormTextField from '../../../../../components/mui-form/FormTextField';
import ChipsInput from '../../../components/ChipsInput';
import Label from '../../../components/Label';
import Section from '../../../components/Section';
import ValidationError from '../../../components/ValidationError';
import { setupSchema, SetupSchema } from '../../../schema/setup-form';
import { UserType } from '../../../schema/user-type';
import { useAuthForm } from '../../../state/AuthFormContext';
export default function SetupForm() {
  const { t } = useTranslation();
  const authStore = useAuthForm();

  const getDefaultValues = () => {
    if (authStore.formData?.type === 'UNKNOWN') {
      return authStore.formData.data;
    } else {
      return authStore.formData?.data.setupData || {};
    }
  };

  const form = useForm<SetupSchema>({
    resolver: zodResolver(setupSchema),
    defaultValues: getDefaultValues(),
  });

  const onSubmit = (data: SetupSchema) => {
    authStore.appendFormData({ type: 'UNKNOWN', data });
    authStore.nextStep();
  };

  type Role = { icon: string; key: UserType; value: string };

  const roles: Role[] = [
    { icon: '/emoji/student.svg', key: 'APPLICANT', value: 'Абітурієнт(ка)' },
    { icon: '/emoji/family.svg', key: 'APPLICANT_MEMBER', value: 'Родич(ка) абітурієнта(ки)' },
    { icon: '/emoji/graduation-cap.svg', key: 'STUDENT', value: 'Студент(ка)' },
    { icon: '/emoji/teacher.svg', key: 'TEACHER', value: 'Викладач(ка)' },
    { icon: '/emoji/circle-ellipsis.svg', key: 'OTHER', value: 'Інше' },
  ];

  return (
    <FormProvider {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)}>
        <Section>
          <Label required>{t('auth.registration-flow.name.label')}</Label>
          <FormTextField
            name='name'
            label={t('auth.registration-flow.name.label')}
            placeholder={t('auth.registration-flow.name.placeholder')}
          />
          <ValidationError error={form.formState.errors.name} />
        </Section>
        <Section>
          <Label required>{t('auth.registration-flow.user-type.label')}</Label>
          <ChipsInput name='userType' chips={roles} />
          <ValidationError error={form.formState.errors.userType} />
        </Section>
        <Section>
          <Section direction='horizontal'>
            <Checkbox {...form.register('termsAgreement')} />
            <Typography variant='body2'>
              {t('auth.registration-flow.terms-agreement.label')}
              <a href='/privacy'>{t('auth.registration-flow.terms-agreement.privacy')}</a> та{' '}
              <a href='/terms'>{t('auth.registration-flow.terms-agreement.terms')}</a>.
            </Typography>
          </Section>
          <ValidationError error={form.formState.errors.termsAgreement} />
        </Section>
        <Button disabled={!form.formState.isValid} type='submit'>
          {t('auth.registration-flow.buttons.next')}
        </Button>
      </form>
    </FormProvider>
  );
}
