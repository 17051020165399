import { PropsWithChildren } from 'react';

import styles from '../auth.module.scss';

const defaultDirection = 'vertical';

type SectionProps = {
  direction?: 'horizontal' | 'vertical';
};

const classes = {
  horizontal: styles.horizontalSection,
  vertical: styles.verticalSection,
};

export default function Section(props: PropsWithChildren<SectionProps>) {
  const { direction, children } = props;
  return <section className={classes[direction ?? defaultDirection]}>{children}</section>;
}
