import { Box, Typography } from '@mui/material';
import AbitlyLogo from '../../../components/Logo/AbitlyLogo';
import styles from '../Partners.module.scss';

export default function AbitlySection() {
  return (
    <Box className={styles.sectionWrap}>
      <div className={styles.abitlySection}>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
          <div className={styles.logoContainer}>
            <AbitlyLogo />
          </div>
          <Typography variant='body1'>
            — волонтерський проєкт, створений студентами для абітурієнтів. Ми допомагаємо зрозуміло та без стресу знайти
            найкращу освіту в Україні й обрати спеціальність, яка справді підходить.
          </Typography>
        </Box>
        <img src='/images/about-us/iphone-preview.png' />
      </div>
    </Box>
  );
}
