import { Box, Breadcrumbs, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import { langPath } from '../../utils/langPath';
import styles from './Breadcrumbs.module.scss';

const CustomBreadcrumbs = ({
  items,
}: {
  items: {
    title: string;
    href: string;
  }[];
}) => {
  return (
    <Box className={styles.breadcrumbContainer}>
      <Breadcrumbs
        maxItems={3}
        separator={
          <>
            <svg width='10' height='10' viewBox='0 0 10 10' fill='none' xmlns='http://www.w3.org/2000/svg'>
              <path d='M2.5 9L7 5L2.5 1' stroke='#3C474E' strokeLinecap='round' strokeLinejoin='round' />
            </svg>
          </>
        }
        aria-label='breadcrumb'
        className={styles.breadcrumbSeparator}
      >
        <Link to={langPath('/')} className={styles.breadcrumbLink}>
          <img width='16px' src='/favicon.ico' alt='abitly favicon' />
          Abitly
        </Link>
        {items.map(({ title, href }, index) => {
          const isLastItem = index === items.length - 1;

          return isLastItem ? (
            <Typography key={title} className={styles.breadcrumbLast || styles.breadcrumbLink}>
              {title}
            </Typography>
          ) : (
            <Link key={title} to={langPath(href)} className={styles.breadcrumbLink}>
              {title}
            </Link>
          );
        })}
      </Breadcrumbs>
    </Box>
  );
};

export default CustomBreadcrumbs;
