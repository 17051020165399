import { z } from 'zod';

import { setupSchema } from './setup-form';

export const applicantMemberPerosonalInfoSchema = z.object({
  entranceYear: z.number(),
  country: z.string(),
  city: z.string(),
});

export const applicantMemberSpecialitiesSchema = z.object({
  specialities: z.array(z.number()),
});

export const applicantMemberPreferencesSchema = z.object({
  cityPreferences: z.array(z.number()),
  universityPreferences: z.array(z.number()),
});

export const applicantMemberSchema = z.object({
  setupData: setupSchema.optional(),
  personalData: applicantMemberPerosonalInfoSchema.optional(),
  specialitiesData: applicantMemberSpecialitiesSchema.optional(),
  preferencesData: applicantMemberPreferencesSchema.optional(),
});

export type ApplicantMemberSchema = z.infer<typeof applicantMemberSchema>;
export type ApplicantMemberPersonalInfoSchema = z.infer<typeof applicantMemberPerosonalInfoSchema>;
export type ApplicantMemberSpecialitiesSchema = z.infer<typeof applicantMemberSpecialitiesSchema>;
export type ApplicantMemberPreferencesSchema = z.infer<typeof applicantMemberPreferencesSchema>;
