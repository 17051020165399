import { z } from 'zod';

import { setupSchema } from './setup-form';

export const teacherFormSchema = z.object({
  universities: z.array(z.number()),
  subjects: z.string(),
});

export const teacherSchema = z.object({
  setupData: setupSchema,
  teacherData: teacherFormSchema.optional(),
});

export type TeacherFormSchema = z.infer<typeof teacherFormSchema>;
export type TeacherSchema = z.infer<typeof teacherSchema>;
