import React, { ReactNode, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';

import { DEFAULT_LANGUAGE, LANGUAGES } from '../common/i18n';

interface LanguageProviderProps {
  children: ReactNode;
}

export const LanguageProvider: React.FC<LanguageProviderProps> = ({ children }) => {
  const { i18n } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const pathSegments = location.pathname.split('/');
    const langInPath = pathSegments[1];

    const isValidLanguage = Object.values(LANGUAGES).includes(langInPath);

    if (isValidLanguage) {
      if (i18n.language !== langInPath) {
        i18n.changeLanguage(langInPath);
        localStorage.setItem('selectedLanguage', langInPath);
      }
    } else {
      const storedLanguage = localStorage.getItem('selectedLanguage');
      const defaultLang =
        storedLanguage && Object.values(LANGUAGES).includes(storedLanguage) ? storedLanguage : DEFAULT_LANGUAGE;

      const newPath = `/${defaultLang}${location.pathname}`;
      navigate({ pathname: newPath, search: location.search }, { replace: true });
    }
  }, [location.pathname, i18n, navigate]);

  return <>{children}</>;
};
