import { Box, IconButton, Link, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { langPath } from '../../utils/langPath';

import styles from './Footer.module.scss';

const Footer = () => {
  const { t } = useTranslation();

  return (
    <Box className={styles.footerContainer}>
      <Box style={{ paddingRight: '56px' }}>
        <Box className={styles.footerContent}>
          <Box className={styles.footerLogoSection}>
            <Box className={styles.footerLogo}>
              <img src={'/emoji/logo.svg'} alt='Logo' />
            </Box>
            <Box className={styles.footerTagline}>
              <Typography variant='body2'>{t('footer.tagline.madeWith')} ❤️‍🔥</Typography>
              <Typography variant='body2'>{t('footer.tagline.byStudents')}</Typography>
            </Box>
          </Box>
          <Box className={styles.footerLinksWrapper}>
            <Box className={styles.footerLinks}>
              <Link href={langPath('/partners')} className={styles.footerLinkItem}>
                <img src={'/emoji/Handshake.png'} alt='emoji' />
                {t('footer.links.partners')}
              </Link>
              <Link href={langPath('/blog')} className={styles.footerLinkItem}>
                <img src={'/emoji/Newspaper.png'} alt='emoji' />
                {t('footer.links.blog')}
              </Link>
              <Link
                href='https://t.me/VladBandurin'
                target='_blank'
                rel='noopener nofollow noreferrer'
                className={styles.footerLinkItem}
              >
                <img src={'/emoji/callMeHand.png'} alt='emoji' />
                {t('footer.links.callUs')}
              </Link>
              <Link
                href='https://send.monobank.ua/jar/3UNSF6txsH'
                target='_blank'
                rel='noopener nofollow noreferrer'
                className={styles.footerLinkItem}
              >
                <img src={'/emoji/moneyWithWings.png'} alt='emoji' />
                {t('footer.links.donate')}
              </Link>
              <Link
                href='https://studsearch.org'
                target='_blank'
                rel='noopener nofollow noreferrer'
                className={styles.footerLinkItem}
              >
                <img src={'/emoji/student.png'} alt='emoji' />
                {t('footer.links.leaveFeedback')}
              </Link>
            </Box>

            <Box className={styles.footerSocial}>
              <Box className={styles.socialLinks}>
                <Typography variant='body2'>{t('footer.socials')}</Typography>
                <Box className={styles.socialIcons}>
                  <IconButton
                    href={'https://www.instagram.com/abitly_org'}
                    target='_blank'
                    rel='noopener nofollow noreferrer'
                  >
                    <img src='/emoji/instagram.svg' alt={'Instagram of abitly'} />
                  </IconButton>
                  <IconButton
                    href={'https://t.me/abitlyorg'}
                    target='_blank'
                    rel='noopener nofollow noreferrer'
                    onClick={() => {
                      if (!(window.location.hostname === 'localhost')) {
                        window.dataLayer = window.dataLayer || [];
                        window.dataLayer.push({
                          event: 'click_on_telegram',
                          element_position: 'footer',
                        });
                      }
                    }}
                  >
                    <img src='/emoji/telegram.svg' alt={'Telegram of abitly'} />
                  </IconButton>
                </Box>
              </Box>
              <Box>
                <Typography variant='body2'>{t('footer.feedback')}</Typography>
                <Link
                  href='mailto:info@abitly.org'
                  variant='body2'
                  target='_blank'
                  rel='noopener nofollow noreferrer'
                  className={styles.websiteLink}
                >
                  info@abitly.org
                </Link>
              </Box>
            </Box>
          </Box>
        </Box>
        <Box className={styles.footerCopyright}>
          <Typography variant='body2'>{t('footer.copyright.text')}</Typography>
          <Box className={styles.footerPolicyLinks}>
            <Link href={langPath('/privacy')} className={styles.policyLink}>
              {t('footer.copyright.policy')}
            </Link>
            &
            <Link href={langPath('/terms')} className={styles.policyLink}>
              {t('footer.copyright.terms')}
            </Link>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Footer;
