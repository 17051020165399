import { ChangeEvent, FC } from 'react';
import { useTranslation } from 'react-i18next';

import { SearchIcon } from '../../../../Offers/icons/SearchIcon';

import styles from './SearchBar.module.scss';

interface SearchBarProps {
  searchTerm: string;
  onSearchChange: (event: ChangeEvent<HTMLInputElement>) => void;
}

export const SearchBar: FC<SearchBarProps> = ({ searchTerm, onSearchChange }) => {
  const { t } = useTranslation();

  return (
    <div className={styles.searchContainer}>
      <input
        type='text'
        placeholder={t('universityPage.common.search.placeholder')}
        value={searchTerm}
        onChange={onSearchChange}
        className={styles.searchInput}
        aria-label={t('universityPage.common.search.placeholder')}
      />
      <span className={styles.searchIcon}>
        <SearchIcon />
      </span>
    </div>
  );
};
