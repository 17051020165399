export const parseSearchParams = () => {
  const urlParams = new URLSearchParams(window.location.search);

  const specialitiesParam = urlParams.get('specialities');
  const specialities = specialitiesParam ? specialitiesParam.split(',') : [];

  const regionsParam = urlParams.get('regions');
  const regions = regionsParam ? regionsParam.split(',') : [];

  return {
    specialities,
    regions,
  };
};
