import { ThemeProvider } from '@mui/material';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { Navigate, Route, Routes } from 'react-router-dom';

import { DEFAULT_LANGUAGE } from './common/i18n';
import { MainLayout } from './components/main-layout/MainLayout';
import AboutUs from './pages/AboutUs';
import { AuthRoutes } from './pages/Auth/AuthRoutes';
import { AuthProvider } from './pages/Auth/state/AuthContext';
import Blog from './pages/Blog';
import CalculatorPage from './pages/Calculator/CalculatorPage';
import Home from './pages/Home';
import { OfferRoutes } from './pages/Offers/OfferRoutes';
import PrivacyPage from './pages/Privacy/PrivacyPage';
import ProfilePage from './pages/Profile/pages/ProfilePage';
import TermsOfService from './pages/TermsOfService/TermsOfService';
import { UniversitiesRoutes } from './pages/Universities/UniversitiesRoutes';
import { LanguageProvider } from './providers/LanguageProvider';
import { theme } from './theme';
import PartnersPage from './pages/Partners/PartnersPage';

const queryClient = new QueryClient();

function App() {
  return (
    <ThemeProvider theme={theme}>
      <QueryClientProvider client={queryClient}>
        <AuthProvider>
          <LanguageProvider>
            <Routes>
              <Route path='/' element={<Navigate to={`/${DEFAULT_LANGUAGE}`} replace />} />
              <Route path='/:lang' element={<MainLayout />}>
                <Route index element={<Home />} />
                <Route path='blog/*' element={<Blog />} />
                <Route path='about/*' element={<AboutUs />} />
                <Route path='partners' element={<PartnersPage />} />
                <Route path='nmt-calculator' element={<CalculatorPage />} />
                <Route path='specialities-calculator' element={<Navigate to='nmt-calculator' replace />} />
                <Route path='privacy' element={<PrivacyPage />} />
                <Route path='terms' element={<TermsOfService />} />
                <Route path='universities/*' element={<UniversitiesRoutes />} />
                <Route path='*' element={<Navigate to={`/${DEFAULT_LANGUAGE}`} replace />} />
              </Route>

              <Route path='/:lang/auth/*' element={<AuthRoutes />} />
              <Route path='/:lang/offers/*' element={<OfferRoutes />} />
              <Route path='/:lang/profile' element={<ProfilePage />} />

              <Route path='/auth/*' element={<Navigate to={`/${DEFAULT_LANGUAGE}/auth`} replace />} />
              <Route path='/offers/*' element={<Navigate to={`/${DEFAULT_LANGUAGE}/offers`} replace />} />

              <Route path='/profile' element={<Navigate to={`/${DEFAULT_LANGUAGE}/profile`} replace />} />
            </Routes>
            <ReactQueryDevtools initialIsOpen={false} />
          </LanguageProvider>
        </AuthProvider>
      </QueryClientProvider>
    </ThemeProvider>
  );
}
export default App;
