import { z } from 'zod';

import { setupSchema } from './setup-form';

export const studentFormSchema = z.object({
  entranceYear: z.number(),
  university: z.number(),
  faculty: z.number(),
  speciality: z.number(),
});

export const studentSchema = z.object({
  setupData: setupSchema,
  studentData: studentFormSchema.optional(),
});

export type StudentFormSchema = z.infer<typeof studentFormSchema>;
export type StudentSchema = z.infer<typeof studentSchema>;
