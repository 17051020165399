import { z } from 'zod';

import { userTypeSchema } from './auth';

export const userProfileRegionSchema = z.object({
  id: z.number(),
  name: z.string(),
  coefficient: z.number(),
});

export const userProfileSpecialitySchema = z.object({
  id: z.number(),
  code: z.string(),
  name: z.string(),
  specification: z.string().nullable(),
  branchId: z.number(),
  isSupported: z.boolean(),
});

export const userProfileUniversitySchema = z.object({
  id: z.number(),
  name: z.string(),
  mainInstitution: z.string().nullable(),
  shortName: z.string(),
  nameEn: z.string(),
  institutionCategory: z.string(),
  ownership: z.string(),
  governingAuthority: z.string(),
  regionId: z.number(),
  website: z.string(),
  applicantsMeanScore: z.number(),
  offersCount: z.number(),
});

export const userProfileFacultySchema = z.object({
  id: z.number(),
  name: z.string(),
  universityId: z.number(),
});

export const userProfileSubjectScoreSchema = z.object({
  id: z.number().nullable(),
  score: z.number().nullable(),
  name: z.string(),
  translation: z.string().optional(),
});

export const userBaseSchema = z.object({
  id: z.string(),
  email: z.string(),
  name: z.string(),
  createdAt: z.string().datetime(),
  updatedAt: z.string().datetime(),
});

export const applicantSchema = userBaseSchema.extend({
  userType: z.literal(userTypeSchema.enum.APPLICANT),
  enrollmentYear: z.number(),
  country: z.string().nullable(),
  city: z.string().nullable(),
  aboutMe: z.string().nullable(),
  aboutMeDetailed: z.string().nullable(),
  expectations: z.string().nullable(),
  expectationsDetailed: z.string().nullable(),
  selectedRegions: z.array(userProfileRegionSchema),
  interestedSpecialities: z.array(userProfileSpecialitySchema),
  selectedUniversities: z.array(userProfileUniversitySchema),
  subjectScores: z.array(userProfileSubjectScoreSchema),
});

export const applicantMemberSchema = userBaseSchema.extend({
  userType: z.literal(userTypeSchema.enum.APPLICANT_MEMBER),
  enrollmentYear: z.number(),
  country: z.string().nullable(),
  city: z.string().nullable(),
  selectedRegions: z.array(userProfileRegionSchema),
  interestedSpecialities: z.array(userProfileSpecialitySchema),
  selectedUniversities: z.array(userProfileUniversitySchema),
  subjectScores: z.array(userProfileSubjectScoreSchema),
});

export const studentSchema = userBaseSchema.extend({
  userType: z.literal(userTypeSchema.enum.STUDENT),
  enrollmentYear: z.number(),
  university: userProfileUniversitySchema.nullable(),
  faculty: userProfileFacultySchema.nullable(),
  speciality: userProfileSpecialitySchema.nullable(),
});

export const teacherSchema = userBaseSchema.extend({
  userType: z.literal(userTypeSchema.enum.TEACHER),
  universities: z.array(userProfileUniversitySchema),
  subjects: z.array(z.string()),
});

export const otherUserSchema = userBaseSchema.extend({
  userType: z.literal(userTypeSchema.enum.OTHER),
  aboutMe: z.string(),
});

export const userSchema = z.discriminatedUnion('userType', [
  applicantSchema,
  applicantMemberSchema,
  studentSchema,
  teacherSchema,
  otherUserSchema,
]);

export const applicantUpdateDto = z.object({
  name: z.string(),
  enrollmentYear: z.number(),
  country: z.string().nullable(),
  city: z.string().nullable(),
  aboutMe: z.string().nullable(),
  aboutMeDetailed: z.string().nullable(),
  expectations: z.string().nullable(),
  expectationsDetailed: z.string().nullable(),
  selectedRegions: z.array(z.number()),
  selectedUniversities: z.array(z.number()),
  interestedSpecialities: z.array(z.number()),
  subjectScores: z.array(z.object({ subjectName: z.string(), subjectScore: z.number().nullable() })),
  userType: z.literal(userTypeSchema.enum.APPLICANT),
});

export const applicantMemberUpdateDto = z.object({
  name: z.string(),
  enrollmentYear: z.number(),
  country: z.string().nullable(),
  city: z.string().nullable(),
  selectedRegions: z.array(z.number()),
  selectedUniversities: z.array(z.number()),
  interestedSpecialities: z.array(z.number()),
  subjectScores: z.array(z.object({ subjectName: z.string(), subjectScore: z.number().nullable() })),
  userType: z.literal(userTypeSchema.enum.APPLICANT_MEMBER),
});

export const studentUpdateDto = z.object({
  name: z.string(),
  enrollmentYear: z.number(),
  universityId: z.number().nullish(),
  facultyId: z.number().nullish(),
  specialityId: z.number().nullish(),
  userType: z.literal(userTypeSchema.enum.STUDENT),
});

export const teacherUpdateDto = z.object({
  name: z.string(),
  universityIds: z.array(z.number()),
  subjects: z.array(z.string()),
  userType: z.literal(userTypeSchema.enum.TEACHER),
});

export const otherUserUpdateDto = z.object({
  name: z.string(),
  aboutMe: z.string(),
  userType: z.literal(userTypeSchema.enum.OTHER),
});

export const userUpdateDto = z.discriminatedUnion('userType', [
  applicantUpdateDto,
  applicantMemberUpdateDto,
  studentUpdateDto,
  teacherUpdateDto,
  otherUserUpdateDto,
]);

export type UserProfileRegion = z.infer<typeof userProfileRegionSchema>;
export type UserProfileSpeciality = z.infer<typeof userProfileSpecialitySchema>;
export type UserProfileUniversity = z.infer<typeof userProfileUniversitySchema>;
export type UserProfileFaculty = z.infer<typeof userProfileFacultySchema>;
export type UserProfileSubjectScore = z.infer<typeof userProfileSubjectScoreSchema>;

export type Applicant = z.infer<typeof applicantSchema>;
export type ApplicantMember = z.infer<typeof applicantMemberSchema>;
export type Student = z.infer<typeof studentSchema>;
export type Teacher = z.infer<typeof teacherSchema>;
export type OtherUser = z.infer<typeof otherUserSchema>;
export type User = z.infer<typeof userSchema>;

export type ApplicantUpdateDto = z.infer<typeof applicantUpdateDto>;
export type ApplicantMemberUpdateDto = z.infer<typeof applicantMemberUpdateDto>;
export type StudentUpdateDto = z.infer<typeof studentUpdateDto>;
export type TeacherUpdateDto = z.infer<typeof teacherUpdateDto>;
export type OtherUserUpdateDto = z.infer<typeof otherUserUpdateDto>;
export type UserUpdateDto = z.infer<typeof userUpdateDto>;
