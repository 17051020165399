import { Typography } from '@mui/material';
import Box from '@mui/material/Box';
import { useTranslation } from 'react-i18next';

import { useAuth } from '../../pages/Auth/state/AuthContext';
import { profileVariants } from '../../pages/Profile/constants/profileVariants';
import { settings } from '../../theme';
import { Link } from '../Link/Link';

import { HeaderMenuGroup } from './Header';
import HeaderMenuButton from './HeaderMenuButton';

export default function ProfileSection() {
  const { user, setAccessToken } = useAuth();
  const { t } = useTranslation();

  if (!user) return null;

  const logout = () => {
    setAccessToken(null);
  };

  return (
    <>
      <HeaderMenuGroup>
        <Box key='user' sx={{ display: 'flex', flexDirection: 'column', gap: '6px', padding: '10px 24px' }}>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
            <img src={profileVariants[user.userType].icon} alt={profileVariants[user.userType].description} />
            <Typography variant='body1'>{user.name}</Typography>
          </Box>
          <Typography color={settings.darkest80} variant='body2'>
            {profileVariants[user.userType].description}
          </Typography>
        </Box>
        <HeaderMenuButton key='profile-settings' emoji='/emoji/gear.png' name={t('header.settings')} to='/profile' />
        <HeaderMenuButton
          key='nmt-calculator'
          emoji='/emoji/abacus.png'
          name={t('header.specialities-calculator')}
          to='/nmt-calculator'
        />
      </HeaderMenuGroup>
      <Box sx={{ display: 'flex', marginBottom: '12px' }}>
        <Link to='/' icon='/images/auth/logout.svg' variant='button-outline' size='slim' onClick={logout}>
          Вийти з акаунту
        </Link>
      </Box>
    </>
  );
}
