import { Link } from 'react-router-dom';

import { langPath } from '../../../../utils/langPath';
import { FiltersChipIcon } from '../../icons/FiltersChipIcon';

import styles from './FiltersChip.module.scss';

export const FiltersChip = () => (
  <Link to={langPath({ pathname: '/offers', search: location.search })} className={styles.chip}>
    <div className={styles.icon}>
      <FiltersChipIcon />
    </div>
    <div className={styles.title}>
      <p>Фільтри</p>
    </div>
  </Link>
);
