import { ReactNode } from 'react';
import { Link, LinkProps } from 'react-router-dom';

import { langPath } from '../../../../utils/langPath';

import styles from './index.module.scss';

type OffersButtonProps = {
  children: ReactNode;
  emoji?: string;
} & LinkProps;

const OffersButton = ({ emoji, children, to, ...props }: OffersButtonProps) => {
  return (
    <Link className={styles.AppOffersButton} to={langPath(to)} {...props}>
      {emoji && (
        <div className={styles.AppOffersButton_Box}>
          <img className={styles.AppOffersButton_Emoji} src={emoji} alt='emoji' />
        </div>
      )}
      <span className={styles.AppOffersButton_Name}>{children}</span>
    </Link>
  );
};

export default OffersButton;
