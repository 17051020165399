import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';

type MultipleVariantOptions = { variant: 'multiple' };

type BaseSingleVariantOptions = { variant: 'single' };
type RequiredSingleVariantOptions = BaseSingleVariantOptions & { required: true; defaultValue: number };
type OptionalSingleVariantOptions = BaseSingleVariantOptions & { required?: false; defaultValue?: number };
type SingleVariantOptions = RequiredSingleVariantOptions | OptionalSingleVariantOptions;

type FilterOptions = SingleVariantOptions | MultipleVariantOptions;

type FilterResult<T extends FilterOptions> = T extends SingleVariantOptions
  ? {
      selectedFilter: number;
      onSelect: (id: number | string) => void;
      isChecked: (id: number) => boolean;
    }
  : {
      selectedFilters: number[];
      onSelect: (id: number | string) => void;
      onSelectMultiple: (ids: number[], value: boolean) => void;
      isChecked: (id: number) => boolean;
    };

const useSingleFilterSearchParams = (
  filterName: string,
  options: SingleVariantOptions,
): FilterResult<SingleVariantOptions> => {
  const [searchParams, setSearchParams] = useSearchParams();
  const storedValue = searchParams.get(filterName);

  const selectedFilter = storedValue ? Number(storedValue) : null;

  useEffect(() => {
    if (!storedValue && options.defaultValue !== undefined) {
      setSearchParams(
        (prev) => {
          prev.set(filterName, options.defaultValue?.toString());
          return prev;
        },
        { replace: true },
      );
    }
  }, [options.defaultValue, searchParams]);

  const onSelect = (id: number | string) => {
    setSearchParams(
      (prev) => {
        const numId = Number(id);
        if (numId === selectedFilter && !options.required) {
          prev.delete(filterName);
        } else {
          prev.set(filterName, id.toString());
        }
        return prev;
      },
      { replace: true },
    );
  };

  const isChecked = (id: number) => selectedFilter?.toString() === id.toString();

  return { selectedFilter, onSelect, isChecked };
};

const useMultipleFilterSearchParams = (filterName: string): FilterResult<MultipleVariantOptions> => {
  const [searchParams, setSearchParams] = useSearchParams();
  const storedValue = searchParams.get(filterName);

  const selectedFilters = storedValue ? storedValue.split(',').map(Number) : [];

  const onSelect = (id: number | string) => {
    setSearchParams(
      (prev) => {
        const newSelectedFilters = selectedFilters.includes(Number(id))
          ? selectedFilters.filter((filter) => filter !== Number(id))
          : [...selectedFilters, Number(id)];
        if (newSelectedFilters.length > 0) {
          prev.set(filterName, newSelectedFilters.join(','));
        } else {
          prev.delete(filterName);
        }
        return prev;
      },
      { replace: true },
    );
  };

  const onSelectMultiple = (ids: number[], value: boolean) => {
    setSearchParams(
      (prev) => {
        let newSelectedFilters: number[];
        if (value) {
          newSelectedFilters = [...selectedFilters.filter((filter) => !ids.includes(filter)), ...ids];
        } else {
          newSelectedFilters = selectedFilters.filter((filter) => !ids.includes(filter));
        }
        if (newSelectedFilters.length > 0) {
          prev.set(filterName, newSelectedFilters.join(','));
        } else {
          prev.delete(filterName);
        }
        return prev;
      },
      {
        replace: true,
      },
    );
  };

  const isChecked = (id: number) => selectedFilters.includes(id);

  return { selectedFilters, onSelect, onSelectMultiple, isChecked };
};

export const useFilterSearchParams = <T extends FilterOptions>(filterName: string, options: T): FilterResult<T> => {
  if (options.variant === 'single') {
    return useSingleFilterSearchParams(filterName, options) as FilterResult<T>;
  }

  return useMultipleFilterSearchParams(filterName) as FilterResult<T>;
};
