import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import { Header } from '../../../../components/Header';
import { langPath } from '../../../../utils/langPath';
import { CodeChip } from '../../components/CodeChip/CodeChip';
import { FiltersChip } from '../../components/FiltersChip/FiltersChip';
import { OfferCard } from '../../components/OfferCard/OfferCard';
import { OfferGroup } from '../../components/OfferGroup/OfferGroup';
import { OfferSubGroup } from '../../components/OfferSubGroup/OfferSubGroup';
import { SortChip } from '../../components/SortChip/SortChip';
import { useRegionFilters } from '../../hooks/useRegionFilters';
import { useSpecialitiesFilters } from '../../hooks/useSpecialitiesFilters';
import { useUniversityFilters } from '../../hooks/useUniversityFilters';
import { useOffersContext } from '../../layouts/OffersContext';
import { SubjectOffer, UniversityOffer } from '../../types/offer';
import { parseSearchParams } from '../../utils/parseSearchParams';
import { trackTimeOnPage } from '../../utils/trackTimeOnPage';
import { LoadingPage } from '../LoadingPage/LoadingPage';

import styles from './OffersResult.module.scss';

export const OffersResult = () => {
  const [loading, setLoading] = useState(true);
  const { offers, fetchOffers, resetOffers } = useOffersContext();

  const isUniversityGrouping = (offers[0] as UniversityOffer)?.universityName !== undefined;

  useEffect(() => {
    async function fetchData() {
      await fetchOffers();
      setLoading(false);
    }

    fetchData();

    return () => {
      resetOffers();
    };
  }, []);

  useEffect(() => {
    const tracker = trackTimeOnPage('offers_page');

    return () => {
      if (tracker && tracker.cleanup) {
        tracker.cleanup();
      }
    };
  }, []);

  if (loading) {
    return <LoadingPage title='Підбираємо найкращі пропозиції' description='І не страшно трішки почекати' />;
  }

  return (
    <div className={styles.offersResultWrap}>
      <div>
        <Header />
        <div className={styles.chipsWrap}>
          <FiltersChip />
          <SortChip title='Назвою закладу' sorting='universityName' />
          <SortChip title='Бюджетні місця' sorting='budgetPlaces' />
          <SortChip title='Вартість контракту' sorting='contractPrice' />
          <SortChip title='Кодом спецальності' sorting='specialityCode' />
        </div>
        <SearchInfo />
        {offers.length === 0 && <GoBackView />}
        {isUniversityGrouping ? (
          <OffersByUniversity data={offers as UniversityOffer[]} />
        ) : (
          <OffersBySubject data={offers as SubjectOffer[]} />
        )}
        <ShowMoreButton />
      </div>
    </div>
  );
};

function SearchInfo() {
  const universities = useUniversityFilters();
  const specialities = useSpecialitiesFilters();
  const regions = useRegionFilters();

  const join = (array: string[]) => array?.join(', ').trimEnd();

  const loading = universities.isLoading || specialities.isLoading || regions.isLoading;

  const selectedRegions = regions.data?.filter((x) => x.isChecked).map((x) => x.name);
  const selectedSpecialityCodes = Array.from(
    new Set(
      specialities.data
        ?.flatMap((x) => x.branchRelatedSpecialities.filter((y) => y.isChecked).map((y) => y.code))
        .filter((x) => x !== undefined),
    ),
  ).sort((a, b) => Number(a) - Number(b));

  const selectedUniversities = universities.data?.filter((x) => x.isChecked).map((x) => `"${x.short_name}"`);

  const addRegions = (searchText: string, regions: string[]) => {
    if (regions?.length === 0) return searchText;
    if (regions?.length === 1) return `${searchText}, регіон ${regions[0]}`;
    return `${searchText}, регіони ${join(regions)}`;
  };

  const addSpecialityCodes = (searchText: string, specialityCodes: string[]) => {
    if (specialityCodes?.length === 0) return searchText;
    if (specialityCodes?.length === 1) return `${searchText}, спеціальність ${specialityCodes[0]}`;
    return `${searchText}, спеціальності ${join(specialityCodes)}`;
  };

  const addUniversities = (searchText: string, universities: string[]) => {
    if (universities?.length === 0) return searchText;
    if (universities?.length === 1) return `${searchText}, університет ${universities[0]}`;
    return `${searchText}, університети ${join(universities)}`;
  };

  let searchText = '';
  searchText = addRegions(searchText, selectedRegions);
  searchText = addSpecialityCodes(searchText, selectedSpecialityCodes);
  searchText = addUniversities(searchText, selectedUniversities);

  searchText = searchText.replace(/^, /, '').replace(/,$/, '');
  searchText = searchText.charAt(0).toUpperCase() + searchText.slice(1);

  return <div className={styles.seachValues}>{loading ? '...' : searchText}</div>;
}

type OffersByUniversityProps = {
  data: UniversityOffer[];
};

function OffersByUniversity(props: OffersByUniversityProps) {
  return (
    <div className={styles.cardsWrap}>
      {props.data.map((offerGroup, index) => (
        <OfferGroup key={index} title={<UniversityTitle {...offerGroup} />} {...offerGroup}>
          {offerGroup.faculties.map((faculty, index) => (
            <OfferSubGroup key={index} title={<FacultyTitle {...faculty} />} {...faculty}>
              {faculty.specialities.map((offer, index) => (
                <OfferCard
                  key={index}
                  title={<SpecialityTitle {...offer} />}
                  offers={offer.offers}
                  universityId={offerGroup.universityId}
                />
              ))}
            </OfferSubGroup>
          ))}
        </OfferGroup>
      ))}
    </div>
  );
}

type OffersBySubjectProps = {
  data: SubjectOffer[];
};

function OffersBySubject(props: OffersBySubjectProps) {
  return (
    <div className={styles.cardsWrap}>
      {props.data.map((offerGroup, index) => (
        <OfferGroup key={index} title={<SpecialityTitle {...offerGroup} />} {...offerGroup}>
          {offerGroup.universities.map((university, index) => (
            <OfferSubGroup key={index} title={<UniversityTitle {...university} />} {...university}>
              {university.faculties.map((offer, index) => (
                <OfferCard
                  key={index}
                  title={<FacultyTitle {...offer} />}
                  offers={offer.offers}
                  universityId={university.universityId}
                />
              ))}
            </OfferSubGroup>
          ))}
        </OfferGroup>
      ))}
    </div>
  );
}

function SpecialityTitle({ specialityName, specialityCode }: { specialityName: string; specialityCode: string }) {
  return (
    <>
      <CodeChip code={specialityCode} size='big' /> {specialityName}
    </>
  );
}

function FacultyTitle({ facultyName }: { facultyName: string }) {
  return <span>🎓 {facultyName}</span>;
}

function UniversityTitle({ universityName }: { universityName: string }) {
  return <span style={{ color: 'black' }}>🏛 {universityName}</span>;
}

function ShowMoreButton() {
  const { fetchOffers, offerPagination } = useOffersContext();
  const hasNextPage = (offerPagination?.count ?? 0) > (offerPagination?.limit ?? 0);

  const loadMoreOffers = async () => {
    const oldLimit = offerPagination?.limit ?? 0;
    fetchOffers({ limit: oldLimit + 15 });
  };

  if (!hasNextPage) {
    return <div className={styles.spacer}></div>;
  }

  return (
    <button className={styles.showMoreButton} onClick={loadMoreOffers}>
      Показати ще 15
    </button>
  );
}

function GoBackView() {
  useEffect(() => {
    if (!(window.location.hostname === 'localhost')) {
      const { specialities, regions } = parseSearchParams();
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        event: 'empty search offers',
        filter_discipline_code: specialities.join(','),
        filter_geo_code: regions.join(','),
      });
    }
  }, []);

  return (
    <div className={styles.goBackWrap}>
      <img className={styles.goBackImage} src='/images/no-results.jpg' alt='Не знайдено жодних пропозицій' />
      <div className={styles.goBackDescription}>
        На жаль, за даними пошуковими фільтрами не було знайдено жодної пропозиції.
      </div>
      <Link to={langPath({ pathname: '/', search: location.search })}>Повернутися до фільтрів</Link>
    </div>
  );
}
